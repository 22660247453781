import "../App.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import MobileMenu from "../Components/MobileMenu";
import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";

const PrivacyPolicy = () => {
  //window.scrollTo(0, 0);

  useEffect(() => {
    window.scrollTo(0, 0);
   }, []);
  return (
    <div className="App">
      <div className="body-wrapper">
        {/* HEADER AREA START (header-4) */}
        <header className="ltn__header-area ltn__header-4 ltn__header-6 ltn__header-transparent gradient-color-2">
          <Header />
        </header>
        {/* HEADER AREA END */}
        {/* Utilize Mobile Menu Start */}
        <div
          id="ltn__utilize-mobile-menu"
          className="ltn__utilize ltn__utilize-mobile-menu"
        >
          <MobileMenu />
        </div>
        {/* Utilize Mobile Menu End */}
        <div className="ltn__utilize-overlay" />
        {/* BREADCRUMB AREA START */}
        <div
          className="ltn__breadcrumb-area ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image"
          data-bg="img/bg/33.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                  <div className="section-title-area ltn__section-title-2">
                      <h1 className="section-title white-color">Privacy Policy</h1>
                  </div>
               
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* BREADCRUMB AREA END */}
        {/* CONTACT FORM AREA START */}
       
          <div className="container">
             <p>
             <h3>DJ Auto Parts Co LTD is Committed to Respecting Your Privacy </h3> <br/>

             DJ Auto Parts Co LTD is committed to respecting your personal information and complying with the relevant laws including, without limitation, the General Data Protection Regulations (“GDPR”). This Privacy Policy, together all related/linked documents and with the Terms of Use <a href="https://djautoac.com/"><u>[https://djautoac.com/]</u>  </a>  of the DJ Auto Parts Co LTD website at the domain <a href="https://djautoac.com/"><u>djautoac</u> </a> Website sets out the basis on which any personal data collected from you, through your interface with DJ Auto Parts Co LTD, or that you provide via the Djautoac Website will be processed.
             </p>
             <p>
             Please read this Privacy Policy carefully to understand DJ Auto Parts Co LTD’s views and practices regarding your personal data and how it will be collected, processed, held, stored and disclosed.
             </p>
             <p>
             By visiting the Djautoac Website you accept and consent to the practices described in this Privacy Policy.
             </p>
            <p>
            This Privacy Policy may be amended from time to time and the new version posted on this webpage with an updated date displayed. You should check this webpage from time to time to see if there have been any changes as they will apply to you. You confirm that you accept any changes to this Privacy Policy by continuing to provide information, including by usage of the Djautoac Website.
            </p>
            <h3>
            Who Collects Your Information via the Djautoac Website?
            </h3>
            <p>
            The Djautoac Website is owned and operated by DJ Auto Parts Co LTD, a limited company registered in the Kingdom of Saudi Arabia under company number 4030305139 and whose registered office is at Prince Majid Street; Safa District; Po Box 248 Jeddah 21411 (“Company”).
            </p>
            <p>
            Any information collected via the Djautoac Website is collected and processed by the Company on behalf of Abdul Latif Jameel in accordance with this Privacy Policy. For the purpose of the GDPR, the data controller is the Company.
            </p>
            <h3>
            Information Collected About You
            </h3>
            <p>
            The Company may collect and process the following data about you: <br/>
            	Information you provide via the Djautoac Website. You may provide information about yourself by filling in the enquiry form on the Djautoac Website, by corresponding with Abdul Latif Jameel by any of the following methods including the contact e-mail address made available on the Djautoac Website, when you register to receive the Djautoac newsletter, by subscribing to the Djautoac informational post, participating in other social media activities on the Djautoac Website made available via the Djautoac Website, participating in any customer surveys and when you report a problem with the Djautoac Website. The information you provide may include personal information that you provide by completing on-line web-forms, including, but not limited to: <br/>
                	forename and family name(s) <br/>
                	email address <br/>
                	telephone number(s) <br/>
                	postal address(es) <br/>
                	any other information that you choose to provide in free-form text fields. <br/>
            <p>
            <h3>Information collected about you via the Djautoac Website.</h3>  With regard to each of your visits to the Djautoac Website, the following information may be automatically collected: 
            <br/> <p>technical information, including the internet protocol (IP) address used to connect your computer to the internet, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform; and</p>
            <p>information about your visit, including the full Uniform Resource Locators (URL) clickstream to, through and from the Djautoac Website (including date and time); pages you viewed; page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and methods used to browse away from the page and any phone number used to call any Abdul Latif Jameel contact number.</p>
            <p>The Company may also use website visitor statistical data to analyze trends and demographics of Djautoac’s visitors. Please note that we do not assign each visitor an individual profile.</p>	
            <p><h4>Information received from other sources.</h4> The Company may receive information about you if you use any other websites operated by an Abdul Latif Jameel business, or any service provided by Abdul Latif Jameel. The Company works closely with third parties (including, for example, business partners, sub-contractors in technical, payment and delivery services, advertising networks, analytics providers, search information providers, credit reference agencies, customer survey providers) and may receive information about you from them.</p>

            </p>    
          
            </p>
            </div>
         
                
      {/* FOOTER AREA START (ltn__footer-2 ltn__footer-color-1) */}
      <footer className="ltn__footer-area ltn__footer-2 ltn__footer-color-1">
        <Footer />
      </footer>
      {/* FOOTER AREA END */}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
