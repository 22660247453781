import React from "react";
import { Link } from "react-router-dom";
import Menu from "./Menu";
import UserMenu from "./UserMenu";

const Header = () => {
  function refreshPage() {
    setTimeout(() => {
      window.location.reload(false);
    }, 500);
  }
  return (
    <>
      {/* ltn__header-top-area start */}
      <div className="ltn__header-top-area top-area-color-white plr--9">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7">
              <div className="ltn__top-bar-menu">
                <ul>
                  <li>
                    <Link to="mailto:djautoparts@dj-auto.com?Subject=News%20Booking%20Request">
                      <i className="icon-mail" /> djautoparts@dj-auto.com
                    </Link>
                  </li>
                  <li>
                    <Link to="/locator" onClick={refreshPage}>
                      <i className="icon-placeholder" /> Kingdom of Saudi Arabia
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-5">
              <div className="top-bar-right text-right">
                <div className="ltn__top-bar-menu">
                  <ul>
                    <li>
                      <a href="https://shop.dj-auto.com/" target="_blank">
                        <span className="active-currency">B2B Login</span>
                      </a>
                    </li>
                    {/* <li> */}
                    {/* ltn__language-menu */}
                    {/* <div className="ltn__drop-menu ltn__currency-menu ltn__language-menu">
                        <ul>
                          <li>
                            <a href="#" className="dropdown-toggle">
                              <span className="active-currency">English</span>
                            </a>
                            <ul>
                              <li>
                                <a href="#">عربي</a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div> */}
                    {/* </li> */}
                    <li>
                      {/* ltn__social-media */}
                      <div className="ltn__social-media">
                        <ul>
                          <li>
                            <a href="https://m.facebook.com/DJAutoKSA" title="Facebook">
                              <i className="fab fa-facebook-f" />
                            </a>
                          </li>
                          {/* <li>
                            <Link to="#" title="Twitter">
                              <i className="fab fa-twitter" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#" title="Linkedin">
                              <i className="fab fa-linkedin" />
                            </Link>
                          </li> */}
                          <li>
                            <a href="https://www.instagram.com/djautoksa/?hl=en" title="Instagram">
                              <i className="fab fa-instagram" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ltn__header-top-area end */}
      {/* ltn__header-middle-area start */}
      <div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-black plr--9">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="site-logo-wrap">
                <div className="site-logo">
                  <Link to="/" onClick={refreshPage}>
                    <img src="img/logo-2.png" alt="Logo" />
                  </Link>
                </div>
                <div className="get-support clearfix get-support-color-white">
                  <div className="site-logo">
                    <img src="img/logo-4.png" alt="Logo" />
                  </div>
                  <div className="site-logo" style={{ paddingLeft: "20px" }}>
                    <img src="img/logo-5.png" alt="Logo" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col d-none d-sm-block d-xs-none">
              <div className="get-support clearfix get-support-color-white">
                <div className="get-support-icon">
                  <i className="icon-call" />
                </div>
                <div className="get-support-info">
                  <h6>Get Booking on Call</h6>
                  <h4>
                    <a href="tel:+966549126544">+966-549-126-544</a>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col header-menu-column menu-color-white">
              <div className="header-menu d-none d-xl-block">
                <Menu />
              </div>
            </div>
            <div className="ltn__header-options ltn__header-options-2">
              {/* user-menu */}
              <div className="ltn__drop-menu user-menu">
                <UserMenu />
              </div>
              {/* Mobile Menu Button */}
              <div class="mobile-menu-toggle d-xl-none">
                <a href="#ltn__utilize-mobile-menu" class="ltn__utilize-toggle">
                  <svg viewBox="0 0 800 600">
                    <path
                      d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                      id="top"
                    ></path>
                    <path d="M300,320 L540,320" id="middle"></path>
                    <path
                      d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                      id="bottom"
                      transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ltn__header-middle-area end */}
    </>
  );
};

export default Header;
