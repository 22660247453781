import "../App.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import MobileMenu from "../Components/MobileMenu";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { SERVER_URL } from "../Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  //window.scrollTo(0, 0);

  useEffect(() => {
    window.scrollTo(0, 0);
   }, []);

  const [customerContact, setCustomerContact] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });
  const handleInput = (e) => {
    const { name, value } = e.target;
    setCustomerContact({ ...customerContact, [name]: value });
  };

  // Handling the form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(customerContact);
    if (
      customerContact.name == "" ||
      customerContact.email === "" ||
      customerContact.phoneNumber === "" ||
      customerContact.message === ""
    ) {
      toast.error("Please fill details", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      let url = SERVER_URL + `/api/contacts/`;
      axios
        .post(url, {
          name: customerContact.name,
          email: customerContact.email,
          phoneNumber: customerContact.phoneNumber,
          message: customerContact.message,
        })
        .then((res) => {
          console.log(res);
          toast.success(res.data.message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error(err, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
      setCustomerContact({
        name: "",
        email: "",
        phoneNumber: "",
        message: "",
      });
    }
  };

  return (
    <div className="App">
      <div className="body-wrapper">
        {/* HEADER AREA START (header-4) */}
        <header className="ltn__header-area ltn__header-4 ltn__header-6 ltn__header-transparent gradient-color-2">
          <Header />
        </header>
        {/* HEADER AREA END */}
        {/* Utilize Mobile Menu Start */}
        <div
          id="ltn__utilize-mobile-menu"
          className="ltn__utilize ltn__utilize-mobile-menu"
        >
          <MobileMenu />
        </div>
        {/* Utilize Mobile Menu End */}
        <div className="ltn__utilize-overlay" />
        {/* BREADCRUMB AREA START */}
        <div
          className="ltn__breadcrumb-area ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image"
          data-bg="img/bg/33.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                  <div className="section-title-area ltn__section-title-2">
                    <h6 className="section-subtitle ltn__secondary-color">
                      // Feel Free To
                    </h6>
                    <h1 className="section-title white-color">Contact us</h1>
                  </div>
                  {/* <div className="ltn__breadcrumb-list">
                    <ul>
                      <li>
                        <a href="/home">Home</a>
                      </li>
                      <li>Contact</li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* BREADCRUMB AREA END */}
        {/* CONTACT FORM AREA START */}
        <div className="ltn__contact-address-area mb-90 ">
          <div className="container">
            <div className="row">
              {/* CONTACT DETAILS AREA END */}
              <div className="col-lg-3">
                <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                  <div className="ltn__contact-address-icon">
                    <img src="img/icons/10.png" alt="Icon Image" />
                  </div>
                  <h3>Email Address</h3>
                  <p>
                    <a href="mailto:djautoparts@dj-auto.com">
                      djautoparts@dj-auto.com
                    </a>
                  </p>
                  <div className="ltn__contact-address-icon">
                    <img src="img/icons/11.png" alt="Icon Image" />
                  </div>
                  <h3>Phone Number</h3>
                  <p>
                    <a href="tel:+966549126544">+966-549-126-544</a>
                  </p>
                  <div className="ltn__contact-address-icon">
                    <img src="img/icons/12.png" alt="Icon Image" />
                  </div>
                  <h3>Main Branch</h3>
                  <p>Kingdom of Saudi Arabia</p>
                </div>
              </div>
              {/* CONTACT DETAILS AREA END */}
              {/* CONTACT MESSAGE AREA START */}
              <div className="col-lg-9">
                <div className="ltn__form-box contact-form-box box-shadow white-bg">
                  <h4 className="title-2">Get A Quote</h4>
                  <form
                    id="contact-form"
                    action="mail.php"
                    method="post"
                    onSubmit={handleSubmit}
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <div className="input-item input-item-name ltn__custom-icon">
                          <input
                            type="text"
                            name="name"
                            value={customerContact.name}
                            onChange={handleInput}
                            placeholder="Enter your name"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="input-item input-item-email ltn__custom-icon">
                          <input
                            type="email"
                            name="email"
                            value={customerContact.email}
                            onChange={handleInput}
                            placeholder="Enter email address"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="input-item input-item-phone ltn__custom-icon">
                          <input
                            type="number"
                            name="phoneNumber"
                            id="phoneNumber"
                            value={customerContact.phoneNumber}
                            onChange={handleInput}
                            placeholder="e.g:+966123456789"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="input-item input-item-textarea ltn__custom-icon">
                      <textarea
                        name="message"
                        value={customerContact.message}
                        onChange={handleInput}
                        placeholder="Enter message"
                        defaultValue={""}
                        required
                      />
                    </div>
                    <button
                      className="btn theme-btn-1 btn-effect-1 text-uppercase"
                      type="submit"
                    >
                      SUBMIT REQUEST
                    </button>
                    <ToastContainer
                      position="bottom-left"
                      autoClose={5000}
                      hideProgressBar={false}
                      newestOnTop
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                    />
                  </form>
                </div>
                <p className="form-message mb-0 mt-20" />
              </div>
            </div>
            {/* CONTACT MESSAGE AREA END */}
          </div>
        </div>
      </div>
      {/* CONTACT FORM AREA END */}
      {/* GOOGLE MAP AREA START */}
      <div className="google-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8094722.56960643!2d41.52379762900829!3d22.60563273367376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c202e3c45527d7%3A0x5e89356d9e4e1b5a!2sAbdul%20Latif%20Jameel!5e0!3m2!1sen!2s!4v1638861248402!5m2!1sen!2s"
          width="100%"
          height="100%"
          frameBorder={0}
          allowFullScreen
          aria-hidden="false"
          tabIndex={0}
        />
      </div>
      {/* GOOGLE MAP AREA END */}
      {/* THREE STEPS AREA START */}
      <div className="contact-feature-area before-bg-bottom mb--30">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="ltn__contact-feature-item">
                <div className="ltn__contact-feature-icon">
                  <img src="img/icons/9.png" alt="Icon Image" />
                </div>
                <div className="ltn__contact-feature-info">
                  <span className="h6">Find your nearest</span>
                  <br />
                  <span className="h2">Branch</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="ltn__contact-feature-item">
                <div className="ltn__contact-feature-icon">
                  <img src="img/icons/8.png" alt="Icon Image" />
                </div>
                <div className="ltn__contact-feature-info">
                  <span className="h6">Make a booking by</span>
                  <br />
                  <span className="h2">Send Request</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="ltn__contact-feature-item">
                <div className="ltn__contact-feature-icon">
                  <img src="img/icons/7.png" alt="Icon Image" />
                </div>
                <div className="ltn__contact-feature-info">
                  <span className="h6">For Confirmation</span>
                  <br />
                  <span className="h2">Get Phone Call</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* THREE STEPS AREA END */}
      {/* FOOTER AREA START (ltn__footer-2 ltn__footer-color-1) */}
      <footer className="ltn__footer-area ltn__footer-2 ltn__footer-color-1">
        <Footer />
      </footer>
      {/* FOOTER AREA END */}
      {/* Body main wrapper end */}
      {/* preloader area start */}
      {/* <Preloader /> */}
    </div>
  );
};

export default Contact;
