import Header from "../Components/Header";
import MobileMenu from "../Components/MobileMenu";
import Footer from "../Components/Footer";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { SERVER_URL } from "../Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";

const ForgotPassword = () => {


  const [customer, setCustomer] = useState({
    email: "",
    newPassword: "",
    confirmPassword: "",
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let email = params.get("email");
    setCustomer({ email: email });
    window.scrollTo(0, 0);
  }, []);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setCustomer({ ...customer, [name]: value });
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();

    let url = SERVER_URL + `/api/customers/forgotpassword/`;

    axios
      .post(url, customer)
      .then((res) => {
        toast.success("New password has been created Successfully", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setCustomer({
          email: "",
          newPassword: "",
          confirmPassword: "",
        });
      })
      .catch((err) => {
        console.log(err);

        toast.error(err, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
  return (
    <div className="App">
      <div className="body-wrapper">
        {/* HEADER AREA START (header-4) */}
        <header className="ltn__header-area ltn__header-4 ltn__header-6 ltn__header-transparent gradient-color-2">
          {/* ltn__header-top-area start */}
          <Header />
          {/* ltn__header-middle-area end */}
        </header>
        {/* HEADER AREA END */}
        {/* Utilize Mobile Menu Start */}
        <div
          id="ltn__utilize-mobile-menu"
          className="ltn__utilize ltn__utilize-mobile-menu"
        >
          <MobileMenu />
        </div>
        {/* Utilize Mobile Menu End */}
        <div className="ltn__utilize-overlay" />
        {/* BREADCRUMB AREA START */}
        <div
          className="ltn__breadcrumb-area ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image"
          data-bg="img/bg/36.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                  <div className="section-title-area ltn__section-title-2">
                    <h6 className="section-subtitle ltn__secondary-color">
                      // Welcome to our company
                    </h6>
                    <h1 className="section-title white-color">My Account</h1>
                  </div>
                  {/* <div className="ltn__breadcrumb-list">
                    <ul>
                      <li>
                        <a href="/home">Home</a>
                      </li>
                      <li>My Account</li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* BREADCRUMB AREA END */}
        {/* WISHLIST AREA START */}
        <div className="liton__wishlist-area pb-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* PRODUCT TAB AREA START */}
                <div className="ltn__myaccount-tab-content-inner">
                  {/* <p>New password has been update Successfully</p> */}
                  <div className="ltn__form-box">
                    <form action="#">
                      <fieldset>
                        <legend>New Password</legend>
                        <div className="row">
                          <div className="col-md-12">
                            <label>Email</label>
                            <input
                              type="text"
                              name="email"
                              id="email"
                              placeholder="Type your registered email"
                              value={customer.email}
                              onChange={handleInput}
                              required
                            />
                            <label>New password</label>
                            <input
                              type="password"
                              name="newPassword"
                              id="newPassword"
                              placeholder="********"
                              value={customer.newPassword}
                              autoComplete="false"
                              onChange={handleInput}
                              required
                            />
                            <label>Confirm password:</label>
                            <input
                              type="password"
                              name="confirmPassword"
                              id="confirmPassword"
                              placeholder="********"
                              value={customer.confirmPassword}
                              autoComplete="false"
                              onChange={handleInput}
                              required
                            />
                          </div>
                        </div>
                      </fieldset>
                      <div className="btn-wrapper">
                        <button
                          type="submit"
                          className="btn theme-btn-1 btn-effect-1 text-uppercase"
                          onClick={handlePasswordChange}
                        >
                          Save Changes
                        </button>
                        <ToastContainer
                          position="bottom-left"
                          autoClose={5000}
                          hideProgressBar={false}
                          newestOnTop
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                        />
                      </div>
                    </form>
                  </div>
                </div>
                {/* PRODUCT TAB AREA END */}
              </div>
            </div>
          </div>
        </div>
        {/* WISHLIST AREA START */}
        {/* THREE STEPS AREA START */}
        <div className="contact-feature-area before-bg-bottom mb--30">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="ltn__contact-feature-item">
                  <div className="ltn__contact-feature-icon">
                    <img src="img/icons/9.png" alt="Icon Image" />
                  </div>
                  <div className="ltn__contact-feature-info">
                    <span className="h6">Find your nearest</span>
                    <br />
                    <span className="h2">Branch</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="ltn__contact-feature-item">
                  <div className="ltn__contact-feature-icon">
                    <img src="img/icons/8.png" alt="Icon Image" />
                  </div>
                  <div className="ltn__contact-feature-info">
                    <span className="h6">Make a booking by</span>
                    <br />
                    <span className="h2">Send Request</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="ltn__contact-feature-item">
                  <div className="ltn__contact-feature-icon">
                    <img src="img/icons/7.png" alt="Icon Image" />
                  </div>
                  <div className="ltn__contact-feature-info">
                    <span className="h6">For Confirmation</span>
                    <br />
                    <span className="h2">Get Phone Call</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* THREE STEPS AREA END */}
        {/* FOOTER AREA START (ltn__footer-2 ltn__footer-color-1) */}
        <footer className="ltn__footer-area ltn__footer-2 ltn__footer-color-1">
          <Footer />
        </footer>
        {/* FOOTER AREA END */}
      </div>
      {/* Body main wrapper end */}
      {/* preloader area start */}
      {/* <Preloader /> */}
    </div>
  );
};

export default ForgotPassword;
