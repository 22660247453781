import React from 'react';
import { Link } from 'react-router-dom';


const Menu = () => {

  function refreshPage() {
    setTimeout(() => {
      window.location.reload(false);
    }, 500);
  }

  return (
    <nav>
      <div className="ltn__main-menu">
        <ul>
          <li><Link to="/" onClick={refreshPage}></Link>
          </li>
          {/* <li><a href="/">Home</a>
          </li> */}
          <li><Link to="/whydensoservices">About us</Link>
          </li>
          <li><Link to="/service">Services</Link>
          </li>
          <li>
            <Link to="/locator" onClick={refreshPage}>Locator</Link>

          </li>
          <li><Link to="/ecatalog">e-Catalog</Link>
          </li>
          <li><Link to="/gallery">Our Gallery</Link>
          </li>
          <li><Link to="/WarrantyClaim">Claim Form</Link>
          </li>
          {/* <li><Link to="/contact">Contact</Link></li> */}
          {/* <li><a href="/AdminPanel">Admin Panel</a>
          </li> */}
          {/* <li className="special-link">
            <a href="/home">MAKE A BOOKING</a></li> */}
        </ul>
      </div>
    </nav>


  )
}

export default Menu;