import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import MobileMenu from "../Components/MobileMenu";
import Footer from "../Components/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Registration from "./Registration";
import axios from "axios";
import { SERVER_URL } from "../Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = ({ setLoginCustomer }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    const params = new URLSearchParams(location.search);
    let customer_id = params.get("userId");
    let token = params.get("token");
    if (customer_id && token) {
      let url = SERVER_URL + `/api/customers/` + customer_id;
      axios
        .put(url, {
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          password: "",
          type: "",
          verified: true,
        })
        .then((res) => {
          toast.success(
            "Email Verified, Please login with your Credentials..",
            {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        })
        .catch((err) => {
          console.log(err);

          toast.error("Failed to Verify Email", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    }
  }, []);

  const [customer, setCustomer] = useState({
    email: "",
    password: "",
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setCustomer({ ...customer, [name]: value });
  };

  const handleLogin = (e) => {
    e.preventDefault();

    let url = SERVER_URL + `/api/customers/login/`;

    axios
      .post(url, customer)
      .then((res) => {
        if (res.data.customer === undefined) {
          sessionStorage.setItem("customer", "");
          sessionStorage.setItem("customerName", "");
          sessionStorage.setItem("customerType", "");

          toast.error(res.data.message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          //setLoginCustomer(res.data.customer);
          sessionStorage.setItem("customer", res.data.customer.email);
          sessionStorage.setItem("customerName", res.data.customer.firstName);
          sessionStorage.setItem("customerType", res.data.customer.type);

          if (res.data.customer.verified === false) {
            toast.info("Please Verify your email first", {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            toast.success(res.data.message, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            if (res.data.customer.type === "admin") navigate("/AdminPanel");
            else {
              window.open("/", "_self");
              navigate("/");
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (customer.email != null) {
      let url = SERVER_URL + `/api/customers/forgotpassword/email`;

      axios
        .post(url, { email: customer.email, password: "" })
        .then((res) => {
          toast.success(res.data.message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setCustomer({
            email: "",
            password: "",
          });
        })
        .catch((err) => {
          console.log(err);

          toast.error(err, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    }
  };

  return (
    <div className="App">
      <div className="body-wrapper">
        {/* HEADER AREA START (header-4) */}
        <header className="ltn__header-area ltn__header-4 ltn__header-6 ltn__header-transparent gradient-color-2">
          <Header />
        </header>
        {/* HEADER AREA END */}
        {/* Utilize Mobile Menu Start */}
        <div
          id="ltn__utilize-mobile-menu"
          className="ltn__utilize ltn__utilize-mobile-menu"
        >
          <MobileMenu />
        </div>
        {/* Utilize Mobile Menu End */}
        <div className="ltn__utilize-overlay" />
        {/* BREADCRUMB AREA START */}
        <div
          className="ltn__breadcrumb-area ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image"
          data-bg="img/bg/35.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                  <div className="section-title-area ltn__section-title-2">
                    <h6 className="section-subtitle ltn__secondary-color">
                      // Welcome to DENSO
                    </h6>
                    <h1 className="section-title white-color">
                      Account<span>.</span>
                    </h1>
                  </div>
                  {/* <div className="ltn__breadcrumb-list">
                    <ul>
                      <li>
                        <a href="/home">Home</a>
                      </li>
                      <li>Login</li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ACCOUNT AREA START */}
        <div className="ltn__login-area pb-65">
          <div className="container">
            <div className="row">
              {/* LOGIN AREA START */}
              <div className="col-lg-6">
                <div className="account-login-inner p-5 contact-form-box box-shadow">
                  <div className="section-title-area ">
                    <h1 className="section-title">Login Your Account</h1>
                  </div>
                  <form action="#" className="ltn__form-box">
                    <input
                      type="text"
                      name="email"
                      id="email"
                      value={customer.email}
                      placeholder="Email*"
                      onChange={handleInput}
                      required
                    />
                    <input
                      type="password"
                      id="password"
                      name="password"
                      value={customer.password}
                      placeholder="Password*"
                      onChange={handleInput}
                      required
                    />
                    <div className="btn-wrapper mt-0">
                      <button
                        className="theme-btn-1 btn btn-block"
                        type="submit"
                        onClick={handleLogin}
                      >
                        SIGN IN
                      </button>
                      <ToastContainer
                        position="bottom-left"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                      />
                    </div>
                    <div className="go-to-btn mt-20">
                      <a
                        href="#"
                        title="Forgot Password?"
                        data-toggle="modal"
                        data-target="#ltn_forget_password_modal"
                      >
                        <small>FORGOTTEN YOUR PASSWORD?</small>
                      </a>
                    </div>
                  </form>
                </div>
                <div className="account-create pt-50">
                  <h4>DON'T HAVE AN ACCOUNT?</h4>
                  <p>
                    Do you want to book services personalized recommendations{" "}
                    <br />
                    check out more quickly your booking history register.
                  </p>
                </div>
              </div>
              {/* LOGIN AREA START */}
              {/* REGISTER AREA START (Register) */}
              <Registration />
              {/* REGISTER AREA END (Register) */}
            </div>
          </div>
        </div>
        {/* ACCOUNT AREA END */}
        {/* THREE STEPS AREA START */}
        <div className="contact-feature-area before-bg-bottom mb--30">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="ltn__contact-feature-item">
                  <div className="ltn__contact-feature-icon">
                    <img src="img/icons/9.png" alt="Icon Image" />
                  </div>
                  <div className="ltn__contact-feature-info">
                    <span className="h6">Find your nearest</span>
                    <br />
                    <span className="h2">Branch</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="ltn__contact-feature-item">
                  <div className="ltn__contact-feature-icon">
                    <img src="img/icons/8.png" alt="Icon Image" />
                  </div>
                  <div className="ltn__contact-feature-info">
                    <span className="h6">Make a booking by</span>
                    <br />
                    <span className="h2">Send Request</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="ltn__contact-feature-item">
                  <div className="ltn__contact-feature-icon">
                    <img src="img/icons/7.png" alt="Icon Image" />
                  </div>
                  <div className="ltn__contact-feature-info">
                    <span className="h6">For Confirmation</span>
                    <br />
                    <span className="h2">Get Phone Call</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* THREE STEPS AREA END */}
        {/* FOOTER AREA START (ltn__footer-2 ltn__footer-color-1) */}
        <footer className="ltn__footer-area ltn__footer-2 ltn__footer-color-1">
          <Footer />
        </footer>
        {/* FOOTER AREA END */}
        {/* MODAL AREA START (Wishlist Modal) */}
        <div className="ltn__modal-area ltn__add-to-cart-modal-area----">
          <div
            className="modal fade"
            id="ltn_forget_password_modal"
            tabIndex={-1}
          >
            <div className="modal-dialog modal-md" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="ltn__quick-view-modal-inner">
                    <div className="modal-product-item">
                      <div className="row">
                        <div className="col-12">
                          <div className="modal-product-info text-center">
                            <h4>FORGET PASSWORD?</h4>
                            <p className="added-cart">
                              {" "}
                              Enter you register email.
                            </p>
                            <form action="#" className="ltn__form-box">
                              <input
                                type="text"
                                name="email"
                                placeholder="Type your register email*"
                                value={customer.email}
                                onChange={handleInput}
                                required
                              />
                              <div className="btn-wrapper mt-0">
                                <button
                                  className="theme-btn-1 btn btn-block"
                                  type="submit"
                                  onClick={handleSubmit}
                                >
                                  Submit
                                </button>
                                <ToastContainer
                                  position="bottom-left"
                                  autoClose={5000}
                                  hideProgressBar={false}
                                  newestOnTop
                                  closeOnClick
                                  rtl={false}
                                  pauseOnFocusLoss
                                  draggable
                                  pauseOnHover
                                />
                              </div>
                            </form>
                          </div>
                          {/* additional-info */}
                          <div className="additional-info d-none">
                            <p>
                              We want to give you <b>10% discount</b> for your
                              first order, <br /> Use discount code at checkout
                            </p>
                            <div className="payment-method">
                              <img src="img/icons/payment.png" alt="#" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* MODAL AREA END */}
      </div>
      {/* Body main wrapper end */}
      {/* preloader area start */}
      {/* <Preloader /> */}
    </div>
  );
};

export default Login;
