import React, { useState, useEffect } from "react";
import axios from "axios";
import { SERVER_URL } from "../Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";

const BookingHistory = () => {
  var hashValueGetter = function(params) {
    return params.node ? params.node.rowIndex + 1 : null;
  };

  const colDefs = [
    {
      headerName: "S#",
      minWidth: 50,
      width: "15%",
      valueGetter: hashValueGetter,
      checkboxSelection: false,
      filter: false,
    },
    {
      headerName: "Vehicle",
      field: "vehicleType",
      minWidth: 150,
      width: "20%",
    },
    {
      headerName: "Model",
      field: "model",
      minWidth: 100,
      width: "20%",
    },
    {
      headerName: "Transmission",
      field: "transmission",
      minWidth: 150,
      width: "20%",
    },
    {
      headerName: "Service",
      field: "serviceDetails",
      minWidth: 200,
      width: "20%",
    },
    {
      headerName: "Branch Name",
      field: "branchName",
      minWidth: 150,
      width: "20%",
    },
    {
      headerName: "Booking Date",
      field: "bookingDate",
      minWidth: 150,
      width: "20%",
    },
    {
      headerName: "Booking Time",
      field: "bookingTime",
      minWidth: 150,
      width: "20%",
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    floatingFilter: true,
  };

  const onGridReady = (params) => {
    let url = SERVER_URL + `/api/bookings/`;

    axios
      .get(url)
      .then((res) => {
        //console.log(res.data.data);
        //setData(res.data.data);
        params.api.applyTransaction({ add: res.data.data });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  return (
    <div>
      {" "}
      <div className="ltn__myaccount-tab-content-inner">
        {/* <p className="bg-green">New changes has been update Successfully</p> */}
        <div className="ltn__myaccount-tab-content-inner">
          <p>
            <legend>Booking History </legend>
          </p>
        </div>
        <div className="ltn__form-box">
          <form action="#">
            <div className="ltn__myaccount-tab-content-inner denso__myaccount-tab">
              <div className="table-responsive">
                <div
                  className="ag-theme-balham-dark"
                  style={{
                    height: "475px",
                    width: "100%",
                    fontSize: 14,
                  }}
                >
                  <AgGridReact
                    pagination={true}
                    paginationPageSize={10}
                    paginationAutoPageSize={true}
                    defaultColDef={defaultColDef}
                    rowHeight={35}
                    //rowData={data}
                    onGridReady={onGridReady}
                    columnDefs={colDefs}
                  ></AgGridReact>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BookingHistory;
