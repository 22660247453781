import React from "react";
import axios from "axios";
import { SERVER_URL } from "../Config";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";

const NewsLetter = () => {
  var hashValueGetter = function(params) {
    return params.node ? params.node.rowIndex + 1 : null;
  };

  const colDefs = [
    {
      headerName: "S#",
      valueGetter: hashValueGetter,
      checkboxSelection: false,
      // filter: false,
    },
    {
      headerName: "Subscriber Name",
      field: "name",
    },
    {
      headerName: "Email",
      field: "email",
    },
    {
      headerName: "Phone Number",
      field: "phoneNumber",
    },
  ];
  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
    floatingFilter: true,
  };
  let gridApi = null;
  const onGridReady = (params) => {
    let url = SERVER_URL + `/api/contacts/`;

    axios
      .get(url)
      .then((res) => {
        gridApi = params.api;
        params.api.applyTransaction({ add: res.data.contacts });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onBtExport = () => {
    gridApi.exportDataAsCsv();
  };
  return (
    <div>
      {" "}
      <div className="ltn__myaccount-tab-content-inner">
        <p>
          Hello, <strong>Admin</strong>
        </p>
      </div>
      <div className="example-header">
        <button
          onClick={onBtExport}
          className="btn theme-btn-1 btn-effect-1 text-uppercase"
        >
          Export to Excel
        </button>
        <br /> <br />
      </div>
      <div className="ltn__myaccount-tab-content-inner denso__myaccount-tab">
        <div className="table-responsive">
          <div
            className="ag-theme-balham-dark"
            style={{
              height: "450px",
              width: "100%",
              fontSize: 14,
            }}
          >
            <AgGridReact
              pagination={true}
              paginationPageSize={10}
              paginationAutoPageSize={true}
              defaultColDef={defaultColDef}
              rowHeight={35}
              //rowData={data}
              onGridReady={onGridReady}
              columnDefs={colDefs}
            ></AgGridReact>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsLetter;
