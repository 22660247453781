import React, { useState, useEffect } from "react";
import axios from "axios";
import { SERVER_URL } from "../Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";

const RegisterUser = () => {
  const [Registration, setRegistration] = useState({
    _id: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    verified: "",
    type: "",
  });

  const [data, setData] = useState();

  useEffect(() => {
    onGridReady();
  }, []);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setRegistration({ ...Registration, [name]: value });
  };

  const saveCustomer = (e) => {
    e.preventDefault();

    if (
      Registration.firstName === "" ||
      Registration.lastName === "" ||
      Registration.email === "" ||
      Registration.phoneNumber == "" ||
      Registration.password === ""
    ) {
      toast.error("Please fill details", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      if (Registration._id !== "" && Registration._id !== undefined) {
        let url = SERVER_URL + `/api/customers/` + Registration._id;
        axios
          .put(url, {
            firstName: Registration.firstName,
            lastName: Registration.lastName,
            email: Registration.email,
            phoneNumber: Registration.phoneNumber,
            password: Registration.password,
            type: Registration.type,
            verified: Registration.verified,
          })
          .then((res) => {
            onGridReady();
            toast.success(res.data.message, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setRegistration({
              _id: "",
              firstName: "",
              lastName: "",
              email: "",
              phoneNumber: "",
              password: "",
              verified: "",
              type: "",
            });
          })
          .catch((err) => {
            console.log(err);
            toast.error(err, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
      }
     
    }
  };
  var hashValueGetter = function(params) {
    return params.node ? params.node.rowIndex + 1 : null;
  };

  const colDefs = [
    {
      headerName: "S#",
      minWidth: 50,
      width: "5%",
      valueGetter: hashValueGetter,
      checkboxSelection: false,
      filter: false,
    },
    {
      headerName: "First Name",
      field: "firstName",
      minWidth: 125,
      width: "15%",
    },
    {
      headerName: "Last Name",
      field: "lastName",
      minWidth: 125,
      width: "15%",
    },
    {
      headerName: "Email",
      field: "email",
      minWidth: 200,
      width: "20%",
    },
    {
      headerName: "Phone No.",
      field: "phoneNumber",
      minWidth: 200,
      width: "20%",
    },
    {
      headerName: "Verification",
      field: "verified",
      minWidth: 57,
      width: "10%",
    },
    {
      headerName: "Type",
      field: "type",
      minWidth: 50,
      width: "10%",
    },
    {
      headerName: "Action",
      field: "Action",
      minWidth: 180,
      width: "25%",
      cellRenderer: ActionRenderer,
      sortable: false,
      filter: false,
    },
  ];
  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
    floatingFilter: true,
  };

  const buttonStyle = {
    fontWeight: "bold",
    width: 60,
    marginRight: 5,
    marginLeft: 5,
    background: "#e30613",
  };
  const onClickEdit = (params) => {
    const selectedRows = params.api.getSelectedRows();

    if (selectedRows.length === 1) {
      setRegistration({
        _id: selectedRows[0]._id,
        firstName: selectedRows[0].firstName,
        lastName: selectedRows[0].lastName,
        email: selectedRows[0].email,
        phoneNumber: selectedRows[0].phoneNumber,
        password: selectedRows[0].password,
        type: selectedRows[0].type,
        verified: selectedRows[0].verified,
      });
    }
  };

  const onConfirmDelete = (params) => {
    try {
      let url = SERVER_URL + `/api/customers/` + params;

      axios
        .delete(url)
        .then((res) => {
          onGridReady();
          toast.success(res.data.message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error(err, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } catch (ex) {}

  };

  const onCancelDelete = (params) => {};

  const onClickDelete = (params) => {
    const selectedRows = params.api.getSelectedRows();

    if (selectedRows.length === 1) {
      window.confirm("Are you sure you wish to delete this item?")
        ? onConfirmDelete(selectedRows[0]._id)
        : onCancelDelete(selectedRows[0]._id);
    }
  };
  function ActionRenderer(params) {
    return (
      <span>
        <button style={buttonStyle} onClick={() => onClickEdit(params)}>
          Edit
        </button>

        <button style={buttonStyle} onClick={() => onClickDelete(params)}>
          Delete
        </button>
      </span>
    );
  }
  const onGridReady = (params) => {
    let url = SERVER_URL + `/api/customers/`;

    axios
      .get(url)
      .then((res) => {
        setData(res.data.customers);
        //params.api.applyTransaction({ add: res.data.data });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  return (
    <div>
      {" "}
      <div className="ltn__myaccount-tab-content-inner">
        {/* <p className="bg-green">New changes has been update Successfully</p> */}
        <div className="ltn__form-box">
          <form action="#">
            <fieldset>
              <legend>Customer Details </legend>
              <div className="row">
                <div className="col-md-6">
                  <div className="input-item input-item-map ltn__custom-icon">
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      value={Registration.firstName}
                      onChange={handleInput}
                      placeholder="First Name"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-item input-item-map ltn__custom-icon">
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      value={Registration.lastName}
                      onChange={handleInput}
                      placeholder="Last Name"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                   <div className="input-item input-item-email ltn__custom-icon">
                    <input
                      type="text"
                      name="email"
                      id="email"
                      value={Registration.email}
                      onChange={handleInput}
                      placeholder="Email*"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                <div className="input-item input-item-map ltn__custom-icon">
                  <input
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                  value={Registration.phoneNumber}
                  onChange={handleInput}
                   placeholder="e.g:+966123456789*"
                   required
                   />
                  </div>
                </div>
                </div>
                <div className="row">
                <div className="col-md-6">
                  <div className="input-item input-item-add ltn__custom-icon">
                    <input
                      type="password"
                      name="password"
                      id="password"
                      value={Registration.password}
                      onChange={handleInput}
                      placeholder="Password*"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-item input-item-map ltn__custom-icon">
                    <input
                      type="text"
                      name="verified"
                      id="verified"
                      value={Registration.verified}
                      onChange={handleInput}
                      placeholder="true or false*"
                      required
                    />
                  </div>
                </div>
              </div>
              
                
                <div className="row">
                <div className="col-md-6">
                  <div className="input-item input-item-map ltn__custom-icon">
                    <input
                      type="text"
                      name="type"
                      id="type"
                      value={Registration.type}
                      onChange={handleInput}
                      placeholder="admin or customer"
                      required
                    />
                  </div>
                </div>
                </div>
              
              <div className="btn-wrapper">
                <button
                  type="submit"
                  className="btn theme-btn-1 btn-effect-1 text-uppercase"
                  onClick={saveCustomer}
                >
                  Save Changes
                </button>
                <ToastContainer
                  position="bottom-left"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </div>
            </fieldset>

            <div className="ltn__myaccount-tab-content-inner denso__myaccount-tab">
              <div className="table-responsive">
                <div
                  className="ag-theme-balham-dark"
                  style={{
                    height: "475px",
                    width: "100%",
                    fontSize: 14,
                  }}
                >
                  <AgGridReact
                    pagination={true}
                    paginationPageSize={10}
                    paginationAutoPageSize={true}
                    defaultColDef={defaultColDef}
                    rowHeight={35}
                    rowData={data}
                    //onGridReady={onGridReady}
                    columnDefs={colDefs}
                    rowSelection="single"
                  ></AgGridReact>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterUser;
