import React, { useState, useEffect } from "react";
import axios from "axios";
import { SERVER_URL } from "../Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";

const Timings = () => {
  const [Timings, setTimings] = useState({
    _id: "",
    day: "",
    dayTiming: "",
  });
  const [data, setData] = useState();

  useEffect(() => {
    onGridReady();
  }, []);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setTimings({ ...Timings, [name]: value });
  };

  const saveTimings = (e) => {
    e.preventDefault();

    if (Timings._id !== "" && Timings._id !== undefined) {
      let url = SERVER_URL + `/api/timings/` + Timings._id;
      axios
        .put(url, Timings)
        .then((res) => {
          onGridReady();
          toast.success(res.data.message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimings({
            _id: "",
            day: "",
            dayTiming: "",
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error(err, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } else if (Timings._id === "" || Timings._id === undefined) {
      let url = SERVER_URL + `/api/timings/`;

      axios
        .post(url, Timings)
        .then((res) => {
          onGridReady();
          toast.success(res.data.message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimings({
            _id: "",
            day: "",
            dayTiming: "",
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error(err, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    }
    
  };

  var hashValueGetter = function(params) {
    return params.node ? params.node.rowIndex + 1 : null;
  };

  const colDefs = [
    {
      headerName: "S#",
      minWidth: 70,
      width: "10%",
      valueGetter: hashValueGetter,
      checkboxSelection: false,
      filter: false,
    },
    {
      headerName: "Day",
      field: "day",
      minWidth: 250,
      width: "25%",
    },
    {
      headerName: "Opening/Closing Time",
      field: "dayTiming",
      minWidth: 350,
      width: "25%",
    },

    {
      headerName: "Action",
      field: "Action",
      minWidth: 180,
      width: "30%",
      cellRenderer: ActionRenderer,
      sortable: false,
      filter: false,
    },
  ];
  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
    floatingFilter: true,
  };
  const buttonStyle = {
    fontWeight: "bold",
    width: 60,
    marginRight: 5,
    marginLeft: 5,
    background: "#e30613",
  };
  const onClickEdit = (params) => {
    const selectedRows = params.api.getSelectedRows();

    if (selectedRows.length === 1) {
      setTimings({
        _id: selectedRows[0]._id,
        day: selectedRows[0].day,
        dayTiming: selectedRows[0].dayTiming,
      });
    }
  };

  const onConfirmDelete = (params) => {
    try {
      let url = SERVER_URL + `/api/timings/` + params;

      axios
        .delete(url)
        .then((res) => {
          onGridReady();
          toast.success(res.data.message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error(err, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } catch (ex) {}

   
  };

  const onCancelDelete = (params) => {};

  const onClickDelete = (params) => {
    const selectedRows = params.api.getSelectedRows();

    if (selectedRows.length === 1) {
      window.confirm("Are you sure you wish to delete this item?")
        ? onConfirmDelete(selectedRows[0]._id)
        : onCancelDelete(selectedRows[0]._id);
    }
  };
  function ActionRenderer(params) {
    return (
      <span>
        <button style={buttonStyle} onClick={() => onClickEdit(params)}>
          Edit
        </button>

        <button style={buttonStyle} onClick={() => onClickDelete(params)}>
          Delete
        </button>
      </span>
    );
  }
  const onGridReady = (params) => {
    let url = SERVER_URL + `/api/timings/`;

    axios
      .get(url)
      .then((res) => {
        setData(res.data.data);
        // params.api.applyTransaction({ add: res.data.data });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
  return (
    <div>
      <div className="ltn__myaccount-tab-content-inner">
        {/* <p className="bg-green">New changes has been update Successfully</p> */}
        <div className="ltn__form-box">
          <form action="#">
            <fieldset>
              <legend> Open/Close Timings </legend>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="input-item input-item-time">
                    <label>Day</label>
                    <input
                      placeholder="Day Name"
                      type="text"
                      name="day"
                      value={Timings.day}
                      onChange={handleInput}
                      required
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="input-item input-item-time">
                    <label>Opening/Closing Time</label>
                    <input
                      placeholder="HH:MM-AM/PM to HH:MM-AM/PM"
                      type="text"
                      name="dayTiming"
                      value={Timings.dayTiming}
                      onChange={handleInput}
                      required
                    />
                  </div>
                </div>
              </div>
            </fieldset>
            <div className="btn-wrapper">
              <button
                type="submit"
                className="btn theme-btn-1 btn-effect-1 text-uppercase"
                onClick={saveTimings}
              >
                Save Changes
              </button>
              <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </div>
          </form>
          <div>
            <br />
          </div>
          <div className="ltn__myaccount-tab-content-inner denso__myaccount-tab">
            <div className="table-responsive">
              <div
                className="ag-theme-balham-dark"
                style={{
                  height: "475px",
                  width: "100%",
                  fontSize: 14,
                }}
              >
                <AgGridReact
                  pagination={true}
                  paginationPageSize={10}
                  paginationAutoPageSize={true}
                  defaultColDef={defaultColDef}
                  rowHeight={35}
                  rowData={data}
                  //onGridReady={onGridReady}
                  columnDefs={colDefs}
                  rowSelection="single"
                ></AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timings;
