import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { SERVER_URL } from "../Config";

const Footer = () => {
  function refreshPage() {
    setTimeout(() => {
      window.location.reload(false);
    }, 500);
  }
  const [Timings, setTimings] = useState();

  useEffect(() => {
    getTimings();
  }, []);

  const getTimings = () => {
    let url = SERVER_URL + `/api/timings/`;

    axios
      .get(url)
      .then((res) => {
        setTimings(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div
        className="footer-top-area footer-top-extra-padding  section-bg-2 bg-image"
        data-bg="img/bg/footer-bg.jpg"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-md-4">
              <div className="footer-widget footer-about-widget clearfix">
                <h4 className="footer-title">Why Denso Service?</h4>
                <p>
                  DENSO provides a wide range of premium vehicle repair and
                  maintenance products in the Kingdom of Saudi Arabia. Denso
                  Central Distributor for Denso and is building up a Denso
                  Service...
                  <Link to="/whydensoservices">Readmore</Link>
                </p>
                <div className="footer-address">
                  <ul>
                    <li>
                      <div className="footer-address-icon">
                        <i className="icon-placeholder" />
                      </div>
                      <div className="footer-address-info">
                        <p>Kingdom of Saudi Arabia</p>
                      </div>
                    </li>
                    <li>
                      <div className="footer-address-icon">
                        <i className="icon-call" />
                      </div>
                      <div className="footer-address-info">
                        <p>
                          <a href="tel:+966549126544">+966-549-126-544</a>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="footer-address-icon">
                        <i className="icon-mail" />
                      </div>
                      <div className="footer-address-info">
                        <p>
                          <a href="mailto:djautoparts@dj-auto.com">
                            djautoparts@dj-auto.com
                          </a>
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="ltn__social-media-4">
                  <ul>
                    <li>
                      <a href="#" title="Facebook">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="#" title="Twitter">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="#" title="Linkedin">
                        <i className="fab fa-linkedin" />
                      </a>
                    </li>
                    <li>
                      <a href="#" title="Youtube">
                        <i className="fab fa-youtube" />
                      </a>
                    </li>
                    <li>
                      <a href="#" title="Instagram">
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4">
              <div
                className="footer-widget ltn__footer-timeline-widget ltn__footer-timeline-widget-1 bg-image bg-overlay-theme-black-90"
                data-bs-bg="img/bg/4.jpg"
              >
                <h6 className="ltn__secondary-color text-uppercase">
                  // time schedule
                </h6>
                <h4 className="footer-title">Operational Timings</h4>
                <ul>
                  {Timings &&
                    Timings.map((element) => (
                      <li>
                        {element.day} <span>{element.dayTiming}</span>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-md-4">
              <div className="footer-widget footer-menu-widget footer-menu-widget-2-column clearfix">
                <h4 className="footer-title">Company Info</h4>
                <div className="footer-menu">
                  <ul>
                    <li>
                      <Link to="/whydensoservices">About us</Link>
                    </li>
                    <li>
                      <Link to="/service">Services</Link>
                    </li>
                    <li>
                      <Link to="/locator" onClick={refreshPage}>
                        Locator
                      </Link>
                    </li>
                    <li>
                      <Link to="/gallery">Our Gellery</Link>
                    </li>
                    <li>
                      <a href="https://shop.dj-auto.com/" target="_blank">
                        B2B Login
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="footer-menu">
                  <ul>
                  
                    <li>
                      <Link to="/ecatalog">e-Catalog</Link>
                    </li>
                    <li>
                      <Link to="/WarrantyClaim">Claim Form</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                    <li>
                      <Link to="/PrivacyPolicy">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
                <h4 className="footer-title">Newsletter</h4>
                <p>Subscribe and receive updates via email.</p>
                <div className="footer-newsletter">
                  <form action="#">
                    <input type="email" name="email" placeholder="Email*" />
                    <div className="btn-wrapper">
                      <button className="theme-btn-1 btn" type="submit">
                        <i className="fas fa-location-arrow" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ltn__copyright-area ltn__copyright-2">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12 text-md-left text-center pt-10">
              <p>
                Copyright &amp; Design By DENSO -{" "}
                <span className="current-year">2022</span>
              </p>
            </div>
            <div className="col-md-6 col-12 text-md-right text-center">
              <a href="/">
                <img
                  src="img/google-play.png"
                  width={120}
                  className=" p-md-1"
                  alt="GooglePlayImage"
                />
              </a>
              <a href="/">
                <img
                  src="img/app-store.png"
                  width={120}
                  className=" p-md-1 pl-1"
                  alt="GooglePlayImage"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="live_chat">
        <h5>Let's Talk</h5>
        <a href="https://wa.me/+966553023135" target="_blank">
          <img
            className="whatsapp-logo"
            src="img/WhatsApp.png"
            alt="whatsapp"
          />
        </a>
      </div>
    </>
  );
};

export default Footer;
