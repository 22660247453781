import React, { useState, useEffect } from "react";
import axios from "axios";
import { SERVER_URL } from "../Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ChangeModelBanner = () => {
  const [banner, setBanner] = useState({
    _id: "",
    bannerTitle: "",
    imagePath: "",
  });

  useEffect(() => {
    onGridReady();
  }, []);

  const onGridReady = (params) => {
    let url = SERVER_URL + `/api/banners/`;

    axios
      .get(url)
      .then((res) => {
        setBanner(...res.data.data);
        var data = res.data.data;

        data.forEach((element) => {
          sessionStorage.setItem("_id", element._id);
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const handleChange = async (e) => {
    if (e.target.name === "imagePath") {
      const file = e.target.files[0];
      const base64 = await convertBase64(file);
      setBanner({ ...banner, [e.target.name]: base64 });
    } else {
      const { name, value } = e.target;
      setBanner({ ...banner, [name]: value });
    }
    //setBanner({ ...banner, [e.target.name]: base64 });
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      if (file) {
        fileReader.readAsDataURL(file);
      }

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      // fileReader.onerror((error) => {
      //   reject(error);
      // });
    });
  };

  const deletePrevious = (params) => {
    try {
      let url = SERVER_URL + `/api/banners/` + params;

      axios
        .delete(url)
        .then((res) => {
          onGridReady();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (ex) {}
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let url = SERVER_URL + `/api/banners/`;
    if (
      banner.bannerTitle === "" ||
      banner.bannerTitle === undefined ||
      banner.imagePath === "" ||
      banner.imagePath === undefined
    ) {
      toast.info("Please fill out all mandatory fields ", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      const id = sessionStorage.getItem("_id");

      if (id !== "" && id !== undefined && id !== null) deletePrevious(id);

      axios
        .post(url, banner)
        .then((res) => {
          onGridReady();
          toast.success("New banner has been added Successfully", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setBanner({
            _id: "",
            imagePath: "",
            bannerTitle: "",
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error(err, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    }
    
  };

  return (
    <div>
      <div className="ltn__myaccount-tab-content-inner">
        {/* <p className="bg-green">New banner has been update Successfully</p> */}
        <div className="ltn__form-box">
          <form action="#">
            <fieldset>
              <legend>Modal Banner </legend>
              <div className="row">
                <div className="col-md-12">
                  <label>Tittle</label>
                  <input
                    placeholder="Type Banner Tittle"
                    type="text"
                    name="bannerTitle"
                    onChange={handleChange}
                    required
                  />
                  <label>Upload New Image</label>
                  <input
                    type="file"
                    name="imagePath"
                    id="imagePath"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    className="form-control p-1"
                    required
                  />
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <img
                      src={banner && banner.imagePath}
                      alt="image"
                      className="p-1 text-center aligncenter"
                    />
                  </div>
                </div>
              </div>
            </fieldset>
            <div className="btn-wrapper">
              <button
                type="submit"
                className="btn theme-btn-1 btn-effect-1 text-uppercase"
                onClick={handleSubmit}
              >
                Save Changes
              </button>
              <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangeModelBanner;
