import React, { useState, useEffect } from "react";
import axios from "axios";
import { SERVER_URL } from "../Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";

const ContactInfo = () => {
  const [ContactInfo, setContactInfo] = useState({
    _id: "",
    email: "",
    phoneNumber: "",
    address: "",
  });
  const [data, setData] = useState();

  useEffect(() => {
    onGridReady();
  }, []);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setContactInfo({ ...ContactInfo, [name]: value });
  };

  const saveContactInfo = (e) => {
    e.preventDefault();

    if (ContactInfo._id !== "" && ContactInfo._id !== undefined) {
      let url = SERVER_URL + `/api/contactInfo/` + ContactInfo._id;
      axios
        .put(url, ContactInfo)
        .then((res) => {
          onGridReady();
          toast.success(res.data.message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setContactInfo({
            _id: "",
            email: "",
            phoneNumber: "",
            address: "",
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error(err, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } else if (ContactInfo._id === "" || ContactInfo._id === undefined) {
      let url = SERVER_URL + `/api/contactInfo/`;

      axios
        .post(url, ContactInfo)
        .then((res) => {
          onGridReady();
          toast.success(res.data.message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setContactInfo({
            _id: "",
            email: "",
            phoneNumber: "",
            address: "",
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error(err, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    }
    
  };

  var hashValueGetter = function(params) {
    return params.node ? params.node.rowIndex + 1 : null;
  };

  const colDefs = [
    {
      headerName: "S#",
      minWidth: 50,
      width: "5%",
      valueGetter: hashValueGetter,
      checkboxSelection: false,
      filter: false,
    },
    {
      headerName: "Email",
      field: "email",
      minWidth: 200,
      width: "25%",
    },
    {
      headerName: "Phone #",
      field: "phoneNumber",
      minWidth: 120,
      width: "15%",
    },
    {
      headerName: "Address",
      field: "address",
      minWidth: 270,
      width: "25%",
    },

    {
      headerName: "Action",
      field: "Action",
      minWidth: 125,
      width: "25%",
      cellRenderer: ActionRenderer,
      sortable: false,
      filter: false,
    },
  ];
  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
    floatingFilter: true,
  };
  const buttonStyle = {
    fontWeight: "bold",
    width: 60,
    marginRight: 5,
    marginLeft: 5,
    background: "#e30613",
  };
  const onClickEdit = (params) => {
    const selectedRows = params.api.getSelectedRows();

    if (selectedRows.length === 1) {
      setContactInfo({
        _id: selectedRows[0]._id,
        email: selectedRows[0].email,
        phoneNumber: selectedRows[0].phoneNumber,
        address: selectedRows[0].address,
      });
    }
  };

  const onConfirmDelete = (params) => {
    try {
      let url = SERVER_URL + `/api/contactInfo/` + params;

      axios
        .delete(url)
        .then((res) => {
          onGridReady();
          toast.success(res.data.message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error(err, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } catch (ex) {}

  };

  const onCancelDelete = (params) => {};

  const onClickDelete = (params) => {
    const selectedRows = params.api.getSelectedRows();

    if (selectedRows.length === 1) {
      window.confirm("Are you sure you wish to delete this item?")
        ? onConfirmDelete(selectedRows[0]._id)
        : onCancelDelete(selectedRows[0]._id);
    }
  };
  function ActionRenderer(params) {
    return (
      <span>
        <button style={buttonStyle} onClick={() => onClickEdit(params)}>
          Edit
        </button>

        <button style={buttonStyle} onClick={() => onClickDelete(params)}>
          Delete
        </button>
      </span>
    );
  }
  const onGridReady = (params) => {
    let url = SERVER_URL + `/api/contactInfo/`;

    axios
      .get(url)
      .then((res) => {
        setData(res.data.data);
        // params.api.applyTransaction({ add: res.data.data });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
  return (
    <div>
      <div className="ltn__myaccount-tab-content-inner">
        {/* <p className="bg-green">New changes has been update Successfully</p> */}
        <div className="ltn__form-box">
          <form action="#">
            <fieldset>
              <legend> Contact Details </legend>
              <div className="row">
                <div className="col-md-6">
                  <div className="input-item input-item-email ltn__custom-icon">
                    <input
                      className="nice-select"
                      type="email"
                      name="email"
                      id="email"
                      value={ContactInfo.email}
                      onChange={handleInput}
                      placeholder="Email Address"
                      required
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="input-item input-item-phone ltn__custom-icon">
                    <input
                      className="nice-select"
                      type="number"
                      name="phoneNumber"
                      id="phoneNumber"
                      value={ContactInfo.phoneNumber}
                      onChange={handleInput}
                      placeholder="e.g:+966123456789"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="input-item input-item-add ltn__custom-icon">
                    <input
                      type="text"
                      name="address"
                      placeholder="Address"
                      value={ContactInfo.address}
                      onChange={handleInput}
                      required
                    />
                  </div>
                </div>
              </div>
            </fieldset>
            <div className="btn-wrapper">
              <button
                type="submit"
                className="btn theme-btn-1 btn-effect-1 text-uppercase"
                onClick={saveContactInfo}
              >
                Save Changes
              </button>
              <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </div>
          </form>
          <div>
            <br />
          </div>
          <div className="ltn__myaccount-tab-content-inner denso__myaccount-tab">
            <div className="table-responsive">
              <div
                className="ag-theme-balham-dark"
                style={{
                  height: "475px",
                  width: "100%",
                  fontSize: 14,
                }}
              >
                <AgGridReact
                  pagination={true}
                  paginationPageSize={10}
                  paginationAutoPageSize={true}
                  defaultColDef={defaultColDef}
                  rowHeight={35}
                  rowData={data}
                  //onGridReady={onGridReady}
                  columnDefs={colDefs}
                  rowSelection="single"
                ></AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
