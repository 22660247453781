import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ChangeModelBanner from "../Admin/ChangeModelBanner";
import AddNewService from "../Admin/AddNewService";
import AddPortfolio from "../Admin/AddPortfolio";
import ContactInfo from "../Admin/ContactInfo";
import Timings from "../Admin/Timings";
import NewsLetter from "../Admin/NewsLetter";
import NewPricePackage from "../Admin/NewPricePackage";
import AddNewLocation from "../Admin/AddNewLocation";
import PasswordChange from "../Admin/PasswordChange";
import AddNewModelYear from "../Admin/AddNewModelYear";
import AddNewTransmission from "../Admin/AddNewTransmission";
import AddNewVehicleType from "../Admin/AddNewVehicleType";
import RegisterUser from "../Admin/RegisterUser";
import BookingHistory from "../Admin/BookingHistory";
import WarrantyClaimHistory from "../Admin/WarrantyClaimHistory";
import TimeSlots from "../Admin/TimeSlotsSettings";
import ReservedSlots from "../Admin/ReservedSlots";

const AdminPanel = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const customerType = sessionStorage.getItem("customerType");
    if (customerType !== "admin") {
      navigate("/");
    }
  }, []);

  const handleLogOut = () => {
    sessionStorage.setItem("customer", "");
    sessionStorage.setItem("customerName", "");
    sessionStorage.setItem("customerType", "");
  };
  return (
    <div>
      {" "}
      <div>
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <title>DENSO KSA | Authorized Service Dealer | KSA</title>
        <meta name="robots" content="noindex, follow" />
        <meta name="description" content="A wide range of premium vehicle repair and maintenance products in the Kingdom of Saudi Arabia." />
        <meta name="keywords" content="AC Servicing, Engine Service, Suspension and Drivetrain, Electrical and Electronics, Filters inspection and replacement, Wiper blades inspection and replacement, Warning indicators, diagnosis and Repair, cTune-ups, Emissions inspections, Fluid and lubricants services, Brake system inspection and repair, Exterior lights inspection and replacement, Tyres inspection and replacement, Engine drive belt inspection and replacement, Engine repair, Engine performance check, Hoses and pipes replacement , Radiator inspection and replacement, Fuel system inspection and repair, Cooling system and components service, Fuel injection inspection, service and repair, Ignition system inspection and repair, Spark plugs replacement, Engine mounting inspection and replacement, Shock absorbers inspection and replacement, Steering components inspection and replacement, Suspension components inspection and replacement, Driveshaft boots servicing, Propeller shafts inspection and repair, Wheel alignment inspection and adjusts, Charging system inspection and repair, Starting system inspection and repair, Power windows repair, Door locks repair, Electrical power steering repair, Power accessories repair, Wiper and washer motor replacement, All batteries inspection and replacement" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        {/* Place favicon.png in the root directory */}
        <link rel="shortcut icon" href="img/favicon.png" type="image/x-icon" />
        {/* Font Icons css */}
        <link rel="stylesheet" href="css/font-icons.css" />
        {/* plugins css */}
        <link rel="stylesheet" href="css/plugins.css" />
        {/* Main Stylesheet */}
        <link rel="stylesheet" href="css/style.css" />
        {/* Responsive css */}
        <link rel="stylesheet" href="css/responsive.css" />
        {/*[if lte IE 9]>
        <p class="browserupgrade">You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade your browser</a> to improve your experience and security.</p>
    <![endif]*/}
        {/* Add your site or application content here */}
        {/* Body main wrapper start */}
        <div className="body-wrapper">
          <div className="ltn__copyright-area ltn__copyright-2">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 col-12 text-center">
                  <h1>Welcome to Admin Area</h1>
                  <hr />
                </div>
              </div>
            </div>
          </div>
          {/* PRODUCT TAB AREA START */}
          <div className="ltn__product-tab-area">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3">
                  <div className="ltn__tab-menu-list mb-50">
                    <div className="nav">
                      <a
                        className="active show"
                        data-toggle="tab"
                        href="#RegisterUser"
                      >
                        Register Users <i className="fas fa-user" />
                      </a>
                      <a className data-toggle="tab" href="#BookingHistory">
                        Booking History <i className="fas fa-history" />
                      </a>
                      <a className data-toggle="tab" href="#WarrantyHistory">
                        Warranty Claim History <i className="fas fa-history" />
                      </a>
                      <a className data-toggle="tab" href="#ChangeModelBanner">
                        Modal Banner <i className="fas fa-image" />
                      </a>
                      <a className data-toggle="tab" href="#AddNewServices">
                        Services <i className="fas fa-cogs" />
                      </a>
                      <a className data-toggle="tab" href="#AddPortfolio">
                        Portfolio Images <i className="fas fa-images" />
                      </a>
                      <a className data-toggle="tab" href="#ContactInfo">
                        Contact Details <i className="fas fa-book" />
                      </a>
                      <a className data-toggle="tab" href="#Timings">
                        Timings <i className="fas fa-cogs" />
                      </a>
                      <a className data-toggle="tab" href="#Newsletter">
                        Newsletter Subs. <i className="fas fa-envelope" />
                      </a>
                      <a className data-toggle="tab" href="#NewPricePackage">
                        Price Package <i className="fas fa-dollar-sign" />
                      </a>
                      <a className data-toggle="tab" href="#AddNewLocation">
                        Location <i className="fas fa-location-arrow" />
                      </a>
                      <a className data-toggle="tab" href="#AddNewModelYear">
                        Model Year <i className="fas fa-cogs" />
                      </a>
                      <a className data-toggle="tab" href="#AddNewVehicleType">
                        Vehicle Type <i className="fas fa-cogs" />
                      </a>
                      <a className data-toggle="tab" href="#AddNewTransmission">
                        Transmission <i className="fas fa-cogs" />
                      </a>

                      <a className data-toggle="tab" href="#TimeSlots">
                        Time Slots <i className="fas fa-cogs" />
                      </a>
                      <a className data-toggle="tab" href="#ReservedSlots">
                        Reserved Slots <i className="fas fa-cogs" />
                      </a>
                      <a className data-toggle="tab" href="#PasswordChange">
                        Password Change <i className="fas fa-key" />
                      </a>
                      <a className href="/" onClick={handleLogOut}>
                        Logout <i className="fas fa-sign-out-alt" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="tab-content">
                    {/* TAB 1 START */}
                    <div
                      className="tab-pane fade active show"
                      id="RegisterUser"
                    >
                      <RegisterUser />
                    
                    </div>
                    {/* TAB 1 END */}
                    
                     {/* TAB 1-A START */}
                     <div className="tab-pane fade" id="BookingHistory">
                      <BookingHistory />
                    </div>

                    {/* TAB 1-A END */}
                    {/* TAB 1-B START */}
                    <div className="tab-pane fade" id="WarrantyHistory">
                      <WarrantyClaimHistory />
                    </div>

                    {/* TAB 1-B END */}
                    {/* TAB 2 START */}
                    <div className="tab-pane fade" id="ChangeModelBanner">
                      <ChangeModelBanner />
                    </div>

                    {/* TAB 2 END */}
                    {/* TAB 3 START */}
                    <div className="tab-pane fade" id="AddNewServices">
                      <AddNewService />
                    </div>

                    {/* TAB 3 END */}
                    {/* TAB 4 START */}
                    <div className="tab-pane fade" id="AddPortfolio">
                      <AddPortfolio />
                    </div>

                    {/* TAB 4 END */}
                    {/* TAB 5 START */}
                    <div className="tab-pane fade" id="ContactInfo">
                      <ContactInfo />
                    </div>
                    {/* TAB 5 END */}
                    {/* TAB 5-A START */}
                    <div className="tab-pane fade" id="Timings">
                      <Timings />
                    </div>
                    {/* TAB 5-A END */}
                    {/* TAB 6 START */}
                    <div className="tab-pane fade" id="Newsletter">
                      <NewsLetter />
                    </div>
                    {/* TAB 6 END */}
                    {/* TAB 7 START */}
                    <div className="tab-pane fade" id="NewPricePackage">
                      <NewPricePackage />
                    </div>
                    {/* TAB 7 END */}
                    {/* TAB 8 START */}
                    <div className="tab-pane fade" id="AddNewLocation">
                      <AddNewLocation />
                    </div>
                    {/* TAB 8 END */}
                    {/* TAB 9 START */}
                    <div className="tab-pane fade" id="AddNewVehicleType">
                      <AddNewVehicleType />
                    </div>
                    {/* TAB 9 END */}
                    {/* TAB 10 START */}
                    <div className="tab-pane fade" id="AddNewModelYear">
                      <AddNewModelYear />
                    </div>
                    {/* TAB 10 END */}
                    {/* TAB 11 START */}
                    <div className="tab-pane fade" id="AddNewTransmission">
                      <AddNewTransmission />
                    </div>
                    {/* TAB 11 END */}

                    {/* TAB 13 START */}
                    <div className="tab-pane fade" id="TimeSlots">
                      <TimeSlots />
                    </div>
                    {/* TAB 13 END */}

                    {/* TAB 14 START */}
                    <div className="tab-pane fade" id="ReservedSlots">
                      <ReservedSlots />
                    </div>
                    {/* TAB 14 END */}
                    {/* TAB 15 START */}
                    <div className="tab-pane fade" id="PasswordChange">
                      <PasswordChange />
                    </div>
                    {/* TAB 15 END */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* PRODUCT TAB AREA END */}
          {/* FOOTER AREA START (ltn__footer-2 ltn__footer-color-1) */}
          <div className="ltn__copyright-area ltn__copyright-2">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 col-12 text-center">
                  <hr />
                  <p>
                    Copyright &amp; Design By DENSO -{" "}
                    <span className="current-year">2021</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* FOOTER AREA END */}
        </div>
        {/* Body main wrapper end */}
        {/* preloader area start */}
        {/* <div className="preloader" id="preloader">
            <div className="preloader-inner">
              <div className="spinner">
                <img src="spinner/denso-spinner.svg" alt="spinner" />
              </div>
            </div>
          </div> */}
        {/* preloader area end */}
        {/* All JS Plugins */}
        {/* Main JS */}
      </div>
    </div>
  );
};

export default AdminPanel;
