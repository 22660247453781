import React, { useState, useEffect } from "react";
import axios from "axios";
import { SERVER_URL } from "../Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";

const NewPricePackage = () => {
  const [PricePackage, setPricePackage] = useState({
    _id: "",
    serviceName: "",
    currency: "",
    price: "",
    offer: "",
    mostPopular: "",
  });
  const [data, setData] = useState();

  useEffect(() => {
    onGridReady();
  }, []);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setPricePackage({ ...PricePackage, [name]: value });
  };

  const savePricePackage = (e) => {
    e.preventDefault();

    if (PricePackage._id !== "" && PricePackage._id !== undefined) {
      let url = SERVER_URL + `/api/packages/` + PricePackage._id;
      axios
        .put(url, PricePackage)
        .then((res) => {
          onGridReady();
          toast.success(res.data.message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setPricePackage({
            _id: "",
            serviceName: "",
            currency: "",
            price: "",
            offer: "",
            mostPopular: "",
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error(err, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } else if (PricePackage._id === "" || PricePackage._id === undefined) {
      let url = SERVER_URL + `/api/packages/`;
      axios
        .post(url, PricePackage)
        .then((res) => {
            onGridReady();
            toast.success(res.data.message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setPricePackage({
            _id: "",
            serviceName: "",
            currency: "",
            price: "",
            offer: "",
            mostPopular: "",
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error(err, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    }
    
  };

  var hashValueGetter = function(params) {
    return params.node ? params.node.rowIndex + 1 : null;
  };

  const colDefs = [
    {
      headerName: "S#",
      minWidth: 50,
      width: "5%",
      valueGetter: hashValueGetter,
      checkboxSelection: false,
      filter: false,
    },
    {
      headerName: "Service Name",
      field: "serviceName",
      minWidth: 125,
      width: "15%",
    },
    {
      headerName: "Currency",
      field: "currency",
      minWidth: 150,
      width: "10%",
    },
    {
      headerName: "Price",
      field: "price",
      minWidth: 100,
      width: "5%",
    },

    {
      headerName: "Offer",
      field: "offer",
      minWidth: 140,
      width: "20%",
    },
    {
      headerName: "Most Popular",
      field: "mostPopular",
      minWidth: 140,
      width: "15%",
    },
    {
      headerName: "Action",
      field: "Action",
      minWidth: 180,
      width: "25%",
      cellRenderer: ActionRenderer,
      sortable: false,
      filter: false,
    },
  ];
  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
    floatingFilter: true,
  };
  const buttonStyle = {
    fontWeight: "bold",
    width: 60,
    marginRight: 5,
    marginLeft: 5,
    background: "#e30613",
  };
  const onClickEdit = (params) => {
    const selectedRows = params.api.getSelectedRows();

    if (selectedRows.length === 1) {
      setPricePackage({
        _id: selectedRows[0]._id,
        serviceName: selectedRows[0].serviceName,
        currency: selectedRows[0].currency,
        price: selectedRows[0].price,
        offer: selectedRows[0].offer,
        mostPopular: selectedRows[0].mostPopular,
      });
    }
  };

  const onConfirmDelete = (params) => {
    try {
      let url = SERVER_URL + `/api/packages/` + params;

      axios
        .delete(url)
        .then((res) => {
          onGridReady();
          toast.success(res.data.message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error(err, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } catch (ex) {}

    
  };

  const onCancelDelete = (params) => {};

  const onClickDelete = (params) => {
    const selectedRows = params.api.getSelectedRows();

    if (selectedRows.length === 1) {
      window.confirm("Are you sure you wish to delete this item?")
        ? onConfirmDelete(selectedRows[0]._id)
        : onCancelDelete(selectedRows[0]._id);
    }
  };
  function ActionRenderer(params) {
    return (
      <span>
        <button style={buttonStyle} onClick={() => onClickEdit(params)}>
          Edit
        </button>

        <button style={buttonStyle} onClick={() => onClickDelete(params)}>
          Delete
        </button>
      </span>
    );
  }
  const onGridReady = (params) => {
    let url = SERVER_URL + `/api/packages/`;

    axios
      .get(url)
      .then((res) => {
        setData(res.data.data);
        //params.api.applyTransaction({ add: res.data.data });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  return (
    <div>
      <div className="ltn__myaccount-tab-content-inner">
        {/* <p className="bg-green">New changes has been update Successfully</p> */}
        <div className="ltn__form-box">
          <form action="#">
            <fieldset>
              <legend>Price Package </legend>
              <div className="row">
                <div className="col-lg-4 col-md-4">
                  <label>Service Name</label>
                  <input
                    placeholder="Type Service Name"
                    type="text"
                    name="serviceName"
                    value={PricePackage.serviceName}
                    onChange={handleInput}
                    required
                  />
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="input-item input-item-time">
                    <label>Select Currency</label>
                    <select
                      className="nice-select"
                      name="currency"
                      value={PricePackage.currency}
                      onChange={handleInput}
                      required
                    >
                      <option>Select</option>
                      <option>SR</option>
                      <option>$</option>
                    </select>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4">
                  <label>Add Price</label>
                  <input
                    placeholder="123.."
                    type="number"
                    name="price"
                    value={PricePackage.price}
                    onChange={handleInput}
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4">
                  <div className="input-item input-item-time">
                    <label>Select Offer</label>
                    <select
                      className="nice-select"
                      name="offer"
                      value={PricePackage.offer}
                      onChange={handleInput}
                      required
                    >
                      <option>Select</option>
                      <option>Hr</option>
                      <option>Day</option>
                      <option>Weekend</option>
                      <option>Month</option>
                      <option>Year</option>
                      <option>Black Friday</option>
                      <option>Special Offer</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <label>Most Popular</label>
                  <input
                    placeholder="true or false"
                    type="text"
                    name="mostPopular"
                    value={PricePackage.mostPopular}
                    onChange={handleInput}
                    required
                  />
                </div>
              </div>
            </fieldset>
            <div className="btn-wrapper">
              <button
                type="submit"
                className="btn theme-btn-1 btn-effect-1 text-uppercase"
                onClick={savePricePackage}
              >
                Save Changes
              </button>
              <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </div>
            <br />
            <div className="ltn__myaccount-tab-content-inner denso__myaccount-tab">
              <div className="table-responsive">
                <div
                  className="ag-theme-balham-dark"
                  style={{
                    height: "475px",
                    width: "100%",
                    fontSize: 14,
                  }}
                >
                  <AgGridReact
                    pagination={true}
                    paginationPageSize={10}
                    paginationAutoPageSize={true}
                    defaultColDef={defaultColDef}
                    rowHeight={35}
                    rowData={data}
                    //onGridReady={onGridReady}
                    columnDefs={colDefs}
                    rowSelection="single"
                  ></AgGridReact>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewPricePackage;
