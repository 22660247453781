import "../App.css";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import MobileMenu from "../Components/MobileMenu";
import Footer from "../Components/Footer";
import Preloader from "../Components/Preloader";
import axios from "axios";
import { SERVER_URL } from "../Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Service = () => {
  const navigate = useNavigate();
  const [PricePackage, setPricePackage] = useState();
  const [Services, setServices] = useState([
    {
      serviceName: "",
      serviceCategory: "",
    },
  ]);

  const [pageLoaded, setPeLoaded] = useState({
    isPageLoaded: false,
  });

  useEffect(() => {
    getPricePackages();
    window.scrollTo(0, 0);
    getServices();
  }, []);

  const getPricePackages = () => {
    let url = SERVER_URL + `/api/packages/`;

    axios
      .get(url)
      .then((res) => {
        setPricePackage(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleBookBow = (e) => {
    sessionStorage.setItem("serviceName", e.target.value);
    navigate("/");
  };

  const getServices = () => {
    let url = SERVER_URL + `/api/services/`;

    axios
      .get(url)
      .then((res) => {
        var data = res.data.data;
        const serviceData = [];
        let sName = null;
        let sCategories = null;

        for (var index = 0; index < data.length; index++) {
          {
            data &&
              data.forEach((element) => {
                if (data[index].serviceName === element.serviceName) {
                  sName = element.serviceName;
                  if (sCategories != null)
                    sCategories =
                      sCategories + "// " + element.serviceCategory + "hadi";
                  else sCategories = "// " + element.serviceCategory + "hadi";
                }
              });
          }
          // data.splice(index, 1);
          serviceData.push({
            serviceName: sName,
            serviceCategory: sCategories,
          });
          sName = null;
          sCategories = null;
        }

        const uniqueArray = serviceData.filter((value, index) => {
          const _value = JSON.stringify(value);
          return (
            index ===
            serviceData.findIndex((obj) => {
              return JSON.stringify(obj) === _value;
            })
          );
        });

        setServices(uniqueArray);
        setPeLoaded({ isPageLoaded: true });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  return (
    <div className="App">
      <div className="body-wrapper">
        {/* HEADER AREA START (header-4) */}
        <header className="ltn__header-area ltn__header-4 ltn__header-6 ltn__header-transparent gradient-color-2">
          <Header />
        </header>
        {/* HEADER AREA END */}
        {/* Utilize Mobile Menu Start */}
        <div
          id="ltn__utilize-mobile-menu"
          className="ltn__utilize ltn__utilize-mobile-menu"
        >
          <MobileMenu />
        </div>
        {/* Utilize Mobile Menu End */}
        <div className="ltn__utilize-overlay" />
        {/* BREADCRUMB AREA START */}
        <div
          className="ltn__breadcrumb-area ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image"
          data-bg="img/bg/9.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                  <div className="section-title-area ltn__section-title-2">
                    <h6 className="section-subtitle ltn__secondary-color">
                      // DENSO Provides
                    </h6>
                    <h1 className="section-title white-color">Services</h1>
                  </div>
                  {/* <div className="ltn__breadcrumb-list">
                    <ul>
                      <li>
                        <a href="/home">Home</a>
                      </li>
                      <li>Service</li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* BREADCRUMB AREA END */}
        {/* WHY CHOOSE US AREA START */}
        <div className="ltn__why-choose-us-area pt-10 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="why-choose-us-info-wrap">
                  <div className="section-title-area ltn__section-title-2">
                    {/* <h6 className="section-subtitle">// DENSO SERVICE</h6> */}
                    <h1 className="section-title">
                      Safety Is Our First Priority<span>.</span>
                    </h1>
                    <p>
                      DENSO provides a wide range of premium vehicle repair and
                      maintenance products. In the Kingdom of Saudi Arabia.{" "}
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="why-choose-us-feature-item">
                        <div className="why-choose-us-feature-icon">
                          <i className="icon-maintenance-1" />
                        </div>
                        <div className="why-choose-us-feature-brief">
                          <h1 className="section-title">
                            <a href="#servicing">
                              AC Servicing<span>:</span>
                            </a>
                          </h1>
                          <h3>
                            Quality maintenance to extend the life of your
                            vehicle
                          </h3>
                          <p>
                            We offer periodic vehicle maintenance using Denso
                            parts to ensure miles of hassle-free performance.
                            All repairs and parts are offered with a warranty.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="why-choose-us-feature-item">
                        <div className="why-choose-us-feature-icon">
                          <i className="icon-repair" />
                        </div>
                        <div className="why-choose-us-feature-brief">
                          <h1 className="section-title">
                            <a href="#engineService">
                              Engine Service<span>:</span>
                            </a>
                          </h1>
                          <h3>
                            Engine repair &amp; replacement with performance
                            check
                          </h3>
                          <p>
                            Our experienced team utilizes professional car
                            diagnostic tools to detect any issue and conduct
                            repairs on any make and model of vehicles.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="why-choose-us-feature-item">
                        <div className="why-choose-us-feature-icon">
                          <i className="icon-car-parts-6" />
                        </div>
                        <div className="why-choose-us-feature-brief">
                          <h1 className="section-title">
                            <a href="#suspension-drivetrain">
                              Suspension &amp; Drivetrain<span>:</span>
                            </a>
                          </h1>
                          <h3>
                            Precision alignment for smooth &amp; stable handling
                          </h3>
                          <p>
                            Do you have an issue with your steering or
                            suspension? Drive-in for a quick alignment check. We
                            will ensure precision alignment so that you can
                            enjoy a safe and smooth ride as well as benefit from
                            long-lasting tires and improved fuel efficiency.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="why-choose-us-feature-item">
                        <div className="why-choose-us-feature-icon">
                          <i className="icon-car-parts-4" />
                        </div>
                        <div className="why-choose-us-feature-brief">
                          <h1 className="section-title">
                            <a href="#electrical-electronics">
                              Electrical &amp; Electronics<span>:</span>
                            </a>
                          </h1>
                          <h3>
                            Complete electrical system check-up &amp; repair
                          </h3>
                          <p>
                            From alternators to spark plugs, we can correct any
                            faults or variances to restore full power to your
                            vehicle, using industry-standard electrical system
                            diagnostics.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* WHY CHOOSE US AREA END */}
        {/* COUNTDOWN AREA START */}
        <div
          className="ltn__call-to-action-area ltn__call-to-action-4 ltn__call-to-action-4-2 bg-overlay-black-50 bg-image pt-110 pb-120"
          data-bg="img/bg/6.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="call-to-action-inner call-to-action-inner-4 text-color-white text-center">
                  <h2 className="ltn__secondary-color">Hurry Up!</h2>
                  <h1 className="h1">
                    Air Conditioning Service, Maintenance and Repair
                    <span>:</span>
                  </h1>
                  <h3>
                    Free Health Checks, Air-conditioning Anti-bacterial clean,
                    air-conditioning re-gas, Checking Freon level, Detection of
                    leaks in the system, Inspection of hoses condition,
                    Inspection of seals condition.{" "}
                  </h3>
                  <div className="btn-wrapper animated">
                    <a
                      href="/"
                      className="theme-btn-1 btn btn-effect-1 text-uppercase"
                    >
                      BOOK NOW
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ltn__call-to-4-img-1">
            <img src="img/product/7.png" alt="#" />
          </div>
          <div className="ltn__call-to-4-img-2">
            <img src="img/bg/11.png" alt="#" />
          </div>
        </div>
        {/* COUNTDOWN AREA END */}

        {/* VIDEO AREA START */}
        {/* <div className="ltn__video-popup-area ltn__video-popup-margin--- mt-120">
          <div
            className="ltn__video-bg-img ltn__video-popup-height-600--- bg-overlay-black-30 bg-image bg-fixed ltn__animation-pulse1"
            data-bg="img/bg/9.jpg"
          >
            <a
              className="ltn__video-icon-2 ltn__video-icon-2-border---"
              href="#"
              data-rel="lightcase:myCollection"
            >
              <i className="fa fa-play" />
            </a>
          </div>
        </div> */}
        {/* VIDEO AREA END */}
        {/* SERVICE AREA START (Service 1) */}
        <div className="ltn__service-area section-bg-1 pt-115 pb-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area ltn__section-title-2 text-center">
                  <h6 className="section-subtitle">{/* // DENSO Service */}</h6>
                  <h1 className="section-title">
                    What We Giving Services<span>.</span>
                  </h1>
                </div>
              </div>
            </div>
            <div className="row">
              {pageLoaded.isPageLoaded &&
                Services &&
                Services.map((element) => (
                  <div id="servicing" className="col-lg-6 col-md-6">
                    <div className="ltn__service-item-2 white-bg">
                      <div className="service-item-icon">
                        <i className="icon-maintenance-1" />
                      </div>
                      <div className="service-item-brief">
                        <>
                          <h3>{element.serviceName}</h3>

                          <>
                            {" "}
                            {element.serviceCategory
                              .split("hadi")
                              .map((line, i) => (
                                <span key={i}>
                                  {line}
                                  <br />
                                </span>
                              ))}
                          </>
                        </>
                        {/* <hr /> */}
                        {/* <button type="submit" class="btn theme-btn-1 btn-effect-1 text-uppercase">SUBMIT REQUEST</button> */}
                        {/* <a
                          href="/home"
                          className="btn theme-btn-1 btn-effect-1 text-uppercase"
                        >
                          Book Services
                        </a> */}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* SERVICE AREA END */}
        {/* <!-- GET A Warranty CLAIM AREA START --> */}
        <div className="ltn__get-a-free-service-area get-a-free-service-margin pt-50 pb-250 section-bg-1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="get-a-free-service-inner bg-white text-center pt-115 pb-100">
                  <div className="call-to-img">
                    <img src="img/bg/10.png" alt="WarrantyImage" />
                  </div>
                  <div className="call-to-action-inner-content">
                    <div className="section-title-area ltn__section-title-2 text-center">
                      <h6 className="section-subtitle ltn__secondary-color">
                        // DENSO //
                      </h6>
                      <h1 className="section-title">
                        Get A Warranty Claim<span>.</span>
                      </h1>
                    </div>
                    <div className="btn-wrapper">
                      <Link
                        to="/WarrantyClaim"
                        className="btn theme-btn-1 btn-effect-1 text-uppercase"
                      >
                        submit claim
                      </Link>
                    </div>
                  </div>
                  <span className="call-to-circle-1"></span>
                  <span className="call-to-circle-2 fa-spin"></span>
                  <span className="call-to-bg-icon">
                    <i className="icon-automobile"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- GET A Warranty CLAIM AREA END --> */}
        {/* PRICING PLAN AREA START */}
        {/* <div
          className="ltn__pricing-plan-area pt-115 pb-120"
          id="liton_pricing"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area ltn__section-title-2 text-center ">
                  <h6 className="section-subtitle ltn__secondary-color">
                    // warranty
                  </h6>
                  <h1 className="section-title">
                    Promotion Plan<span>.</span>
                  </h1>
                </div>
              </div>
            </div>
            <div className="row ltn__price-slider-one-active slick-arrow-1 justify-content-center--- mt-50">
              {PricePackage &&
                PricePackage.map((element) => (
                  <>
                    {element.mostPopular ? (
                      <>
                        <div className="col-lg-3 col-sm-6">
                          <div className="ltn__pricing-plan-item text-center bg-white active">
                            <span className="pricing-badge">Most Popular</span>
                            <h2 className="pricing-title">
                              {element.serviceName}
                            </h2>
                            <div className="pricing-price">
                              <h2>
                                <sup>{element.currency}</sup>
                                {element.price}
                                <sub>/{element.offer}</sub>
                              </h2>
                            </div>
                            <ul>
                              <li />
                            </ul>
                            <div className="btn-wrapper">
                              <button
                                name={element.serviceName}
                                value={element.serviceName}
                                className="theme-btn-2 btn"
                                onClick={handleBookBow}
                              >
                                BOOK NOW
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-lg-3 col-sm-6">
                          <div className="ltn__pricing-plan-item text-center bg-white">
                            <h2 className="pricing-title">
                              {element.serviceName}
                            </h2>
                            <div className="pricing-price">
                              <h2>
                                <sup>{element.currency}</sup>
                                {element.price}
                                <sub>/{element.offer}</sub>
                              </h2>
                            </div>
                            <ul>
                              <li />
                            </ul>
                            <div className="btn-wrapper">
                              <button
                                name={element.serviceName}
                                value={element.serviceName}
                                className="theme-btn-2 btn"
                                onClick={handleBookBow}
                              >
                                BOOK NOW
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ))}
            </div>
          </div>
        </div> */}
        {/* PRICING PLAN AREA END */}
        {/* THREE STEPS AREA START */}
        <div className="contact-feature-area before-bg-bottom mb--30">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="ltn__contact-feature-item">
                  <div className="ltn__contact-feature-icon">
                    <img src="img/icons/9.png" alt="Icon Image" />
                  </div>
                  <div className="ltn__contact-feature-info">
                    <span className="h6">Find your nearest</span>
                    <br />
                    <span className="h2">Branch</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="ltn__contact-feature-item">
                  <div className="ltn__contact-feature-icon">
                    <img src="img/icons/8.png" alt="Icon Image" />
                  </div>
                  <div className="ltn__contact-feature-info">
                    <span className="h6">Make a booking by</span>
                    <br />
                    <span className="h2">Send Request</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="ltn__contact-feature-item">
                  <div className="ltn__contact-feature-icon">
                    <img src="img/icons/7.png" alt="Icon Image" />
                  </div>
                  <div className="ltn__contact-feature-info">
                    <span className="h6">For Confirmation</span>
                    <br />
                    <span className="h2">Get Phone Call</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* THREE STEPS AREA END */}
        {/* FOOTER AREA START (ltn__footer-2 ltn__footer-color-1) */}
        <footer className="ltn__footer-area ltn__footer-2 ltn__footer-color-1">
          <Footer />
        </footer>
        {/* FOOTER AREA END */}
      </div>
      {/* Body main wrapper end */}
      {/* preloader area start */}
      {/*  <Preloader /> */}
    </div>
  );
};

export default Service;
