import React, { useState, useEffect } from "react";
import axios from "axios";
import { SERVER_URL } from "../Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";

const AddNewTransmission = () => {
  const [Transmission, setTransmission] = useState({
    _id: "",
    transmissionName: "",
  });

  const [data, setData] = useState();

  useEffect(() => {
    onGridReady();
  }, []);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setTransmission({ ...Transmission, [name]: value });
  };

  const saveTransmission = (e) => {
    e.preventDefault();

    if (Transmission._id !== "" && Transmission._id !== undefined) {
      let url = SERVER_URL + `/api/transmissions/` + Transmission._id;
      axios
        .put(url, Transmission)
        .then((res) => {
          onGridReady();
          toast.success(res.data.message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTransmission({
            _id: "",
            transmissionName: "",
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error(err, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } else if (Transmission._id === "" || Transmission._id === undefined) {
      let url = SERVER_URL + `/api/transmissions/`;

      axios
        .post(url, Transmission)
        .then((res) => {
          onGridReady();
          toast.success(res.data.message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTransmission({
            _id: "",
            transmissionName: "",
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error(err, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    }

  };

  var hashValueGetter = function (params) {
    return params.node ? params.node.rowIndex + 1 : null;
  };

  const colDefs = [
    {
      headerName: "S#",
      minWidth: 50,
      width: "15%",
      valueGetter: hashValueGetter,
      checkboxSelection: false,
      filter: false,
    },
    {
      headerName: "Transmission",
      field: "transmissionName",
      minWidth: 200,
      width: "25%",
    },
    {
      headerName: "Action",
      field: "Action",
      minWidth: 75,
      width: "30%",
      cellRenderer: ActionRenderer,
      sortable: false,
      filter: false,
    },
  ];
  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
    floatingFilter: true,
  };
  const buttonStyle = {
    fontWeight: "bold",
    width: 60,
    marginRight: 5,
    marginLeft: 5,
    background: "#e30613",
  };

  const onClickEdit = (params) => {
    const selectedRows = params.api.getSelectedRows();

    if (selectedRows.length === 1) {
      setTransmission({
        _id: selectedRows[0]._id,
        transmissionName: selectedRows[0].transmissionName,
      });
    }
  };

  const onConfirmDelete = (params) => {
    try {
      let url = SERVER_URL + `/api/transmissions/` + params;

      axios
        .delete(url)
        .then((res) => {
          onGridReady();
          toast.success(res.data.message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error(err, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } catch (ex) { }

  };

  const onCancelDelete = (params) => { };

  const onClickDelete = (params) => {
    const selectedRows = params.api.getSelectedRows();

    if (selectedRows.length === 1) {
      window.confirm("Are you sure you wish to delete this item?")
        ? onConfirmDelete(selectedRows[0]._id)
        : onCancelDelete(selectedRows[0]._id);
    }
  };
  function ActionRenderer(params) {
    return (
      <span>
        <button style={buttonStyle} onClick={() => onClickEdit(params)}>
          Edit
        </button>

        <button style={buttonStyle} onClick={() => onClickDelete(params)}>
          Delete
        </button>
      </span>
    );
  }
  const onGridReady = (params) => {
    let url = SERVER_URL + `/api/transmissions/`;
    //params.api.hideOverlay();
    axios
      .get(url)
      .then((res) => {
        setData(res.data.data);
        //params.api.applyTransaction({ add: res.data.data });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  return (
    <div>
      {" "}
      <div className="ltn__myaccount-tab-content-inner">
        {/* <p className="bg-green">New changes has been update Successfully</p> */}
        <div className="ltn__form-box">
          <form action="#">
            <fieldset>
              <legend>Transmission </legend>
              <div className="row">
                <div className="col-lg-12">
                  <label>Transmission</label>
                  <input
                    placeholder="Transmission"
                    type="text"
                    name="transmissionName"
                    value={Transmission.transmissionName}
                    onChange={handleInput}
                    required
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="btn-wrapper">
                    <button
                      type="submit"
                      className="btn theme-btn-1 btn-effect-1 text-uppercase"
                      onClick={saveTransmission}
                    >
                      Save Changes
                    </button>
                    <ToastContainer
                      position="bottom-left"
                      autoClose={5000}
                      hideProgressBar={false}
                      newestOnTop
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                    />
                  </div>
                </div>
                {/* <input placeholder="//" type="text" /> */}
                {/* <div id="new_chq" />
                  <button className="add btn theme-btn-1 btn-effect-1 text-uppercase">
                    Add
                  </button>
                  <button className="remove btn theme-btn-1 btn-effect-1 text-uppercase">
                    remove
                  </button>
                  <input type="hidden" defaultValue={1} id="total_chq" />
                </div> */}
              </div>
            </fieldset>
            <div className="ltn__myaccount-tab-content-inner denso__myaccount-tab">
              <div className="table-responsive">
                <div
                  className="ag-theme-balham-dark"
                  style={{
                    height: "475px",
                    width: "100%",
                    fontSize: 14,
                  }}
                >
                  <AgGridReact
                    pagination={true}
                    paginationPageSize={10}
                    paginationAutoPageSize={true}
                    defaultColDef={defaultColDef}
                    rowHeight={35}
                    rowData={data}
                    //onGridReady={onGridReady}
                    columnDefs={colDefs}
                    rowSelection="single"
                  ></AgGridReact>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNewTransmission;
