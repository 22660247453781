import React, { useState } from "react";
import axios from "axios";
import { SERVER_URL } from "../Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Registration() {
  const [userRegistration, setUserRegistration] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
  });
  const handleInput = (e) => {
    const { name, value } = e.target;
    setUserRegistration({ ...userRegistration, [name]: value });
  };

  // Handling the form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(userRegistration);
    if (
      userRegistration.firstName === "" ||
      userRegistration.lastName === "" ||
      userRegistration.email === "" ||
      userRegistration.phoneNumber === "" ||
      userRegistration.password === "" ||
      userRegistration.confirmPassword === ""
    ) {
      toast.error("Please fill details", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      if (userRegistration.password !== userRegistration.confirmPassword) {
        toast.error("Password not matched", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        let url = SERVER_URL + `/api/customers/`;
        axios
          .post(url, {
            firstName: userRegistration.firstName,
            lastName: userRegistration.lastName,
            email: userRegistration.email,
            phoneNumber: userRegistration.phoneNumber,
            password: userRegistration.password,
          })
          .then((res) => {
            console.log(res);
            toast.info(res.data.message, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          })
          .catch((err) => {
            console.log(err);

            toast.error("Password not matched", {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
        setUserRegistration({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          password: "",
          confirmPassword: "",
        });
      }
    }
  };

  return (
    <>
      <div className="col-lg-6">
        <div className="account-login-inner p-5 contact-form-box box-shadow">
          <div className="section-title-area ">
            <h1 className="section-title">Register Your New Account</h1>
          </div>
          {/* Calling to the methods */}

          <form action="#" className="ltn__form-box" onSubmit={handleSubmit}>
            <input
              type="text"
              name="firstName"
              id="firstName"
              value={userRegistration.firstName}
              onChange={handleInput}
              placeholder="First Name"
              required
            />
            <input
              type="text"
              name="lastName"
              id="lastName"
              value={userRegistration.lastName}
              onChange={handleInput}
              placeholder="Last Name"
              required
            />
            <input
              type="text"
              name="email"
              id="email"
              value={userRegistration.email}
              onChange={handleInput}
              placeholder="Email*"
              required
            />
            <input
              type="text"
              name="phoneNumber"
              id="phoneNumber"
              value={userRegistration.phoneNumber}
              onChange={handleInput}
              placeholder="e.g:+966123456789*"
              required
            />
            <input
              type="password"
              name="password"
              id="password"
              value={userRegistration.password}
              onChange={handleInput}
              placeholder="Password*"
              required
            />
            <input
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              value={userRegistration.confirmPassword}
              onChange={handleInput}
              placeholder="Confirm Password*"
              required
            />
            <div className="btn-wrapper">
              <button
                className="theme-btn-1 btn reverse-color btn-block"
                type="submit"
              >
                CREATE ACCOUNT
              </button>
              <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Registration;
