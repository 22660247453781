import React, { useState } from "react";
import Home from "./pages/Home";
import Service from "./pages/Service";
import Whydensoservices from "./pages/Whydensoservices";
import Locator from "./pages/Locator";
import Ecatalog from "./pages/Ecatalog";
import Gallery from "./pages/Gallery";
import Contact from "./pages/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import reportWebVitals from "./reportWebVitals";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Account from "./pages/Account";
import AdminPanel from "./pages/AdminPanel";
import WarrantyClaim from "./pages/WarrantyClaim";
import ForgotPassword from "./pages/ForgotPassword";

function App() {
  const [customer, setLoginCustomer] = useState({});
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/service" element={<Service />} />
          <Route path="/whydensoservices" element={<Whydensoservices />} />
          <Route path="/locator" element={<Locator />} />
          <Route path="/ecatalog" element={<Ecatalog />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/WarrantyClaim" element={<WarrantyClaim />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/AdminPanel" element={<AdminPanel />} />
          {customer && customer.email ? (
            <Route path="/" element={<Home />} />
          ) : (
            <Route
              path="/"
              element={<Login setLoginCustomer={setLoginCustomer} />}
            />
          )}
          <Route
            path="/login"
            element={<Login setLoginCustomer={setLoginCustomer} />}
          />
          <Route path="/account" element={<Account />} />
          {/* <Route path="WarrantyClaim" element={<WarrantyClaim />} /> */}
          <Route
            path="/logout"
            element={<Home setLoginCustomer={setLoginCustomer} />}
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
