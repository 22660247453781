import "../App.css";
import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import MobileMenu from "../Components/MobileMenu";
import Footer from "../Components/Footer";
import AboutUs from "../Components/AboutUs";
import { Link } from "react-router-dom";
import axios from "axios";
import { SERVER_URL } from "../Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const WhyDensoServices = () => {
  const [GalleryImages, setGalleryImages] = useState();
  useEffect(() => {
    getGalleryImages();
    window.scrollTo(0, 0);
  }, []);

  const getGalleryImages = () => {
    let url = SERVER_URL + `/api/portfolioImages/`;

    axios
      .get(url)
      .then((res) => {
        var reverseData = res.data.data.reverse().slice(0, 4);
        setGalleryImages(reverseData);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  return (
    <div className="App">
      <div className="body-wrapper">
        {/* HEADER AREA START (header-4) */}
        <header className="ltn__header-area ltn__header-4 ltn__header-6 ltn__header-transparent gradient-color-2">
          {/* ltn__header-top-area start */}
          <Header />
          {/* ltn__header-middle-area end */}
        </header>
        {/* HEADER AREA END */}
        {/* Utilize Mobile Menu Start */}
        <div
          id="ltn__utilize-mobile-menu"
          className="ltn__utilize ltn__utilize-mobile-menu"
        >
          <MobileMenu />
        </div>
        {/* Utilize Mobile Menu End */}
        <div className="ltn__utilize-overlay" />
        {/* BREADCRUMB AREA START */}
        <div
          className="ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image"
          data-bg="img/bg/31.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                  <div className="section-title-area ltn__section-title-2">
                    <h6 className="section-subtitle ltn__secondary-color">
                      // Welcome DENSO
                    </h6>
                    <h1 className="section-title white-color">About us</h1>
                  </div>
                  {/* <div className="ltn__breadcrumb-list">
                      <ul>
                        <li>
                          <a href="/home">Home</a>
                        </li>
                        <li>About us</li>
                      </ul>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* BREADCRUMB AREA END */}

        {/* FEATURE AREA START ( Feature - 6) */}
        <div className="ltn__feature-area section-bg-1 pt-115 pb-90">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area ltn__section-title-2 text-center">
                  <h6 className="section-subtitle ltn__secondary-color text-left">
                    // features //
                  </h6>
                  <h2 className="section-title text-left">
                    Why Denso Service<span>?</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <span className="text-left">
                  <p>
                    We at DENSO, are committed to provide service solutions to
                    our prestigious customers for the DENSO products in Car AC.
                    We have a DENSO trained Service Network equipped with DENSO
                    special tools & equipment’s to ensure quality of repair
                    using DENSO genuine spares at affordable price
                  </p>
                  <p>
                    At DENSO we've taken everything we have learned as an OE
                    manufacturer and applied it to our aftermarket product
                    lines. Every component that leaves our factories has been
                    designed with precision, manufactured to OE standards and
                    subjected to rigorous safety and performance tests.
                  </p>
                  <p>
                    DENSO First Time Fit® products — including A/C compressors,
                    starters, alternators, ignition wire sets, fuel pumps, cabin
                    air, oil and air filters, wiper blades and more — are
                    products built and tested to OE-standard offered for the
                    aftermarket by DENSO. Each of these products is
                    precision-built for exact replacement so you don't have to
                    worry about doing the same job twice.
                  </p>
                  <p class="pb-50">
                    The OE-standard quality and reliability of DENSO aftermarket
                    components add up to a tremendous value for our customers.
                  </p>
                </span>
              </div>
            </div>
            <div className="row ltn__custom-gutter flex-nowrap">
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="ltn__feature-item ltn__feature-item-6 bg-white">
                  <div className="ltn__feature-icon">
                    <span>
                      <i className="icon-car-parts-9" />
                    </span>
                  </div>
                  <div className="ltn__feature-info">
                    <h3>
                      <a href="/service">Wide Range</a>
                    </h3>
                    <p>
                      Denso is your one-stop solution for all your car repair
                      and servicing needs.
                      <br></br>
                      <br></br>
                    </p>
                    {/* <a className="ltn__service-btn" href="/service">
                        Read More
                      </a> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="ltn__feature-item ltn__feature-item-6">
                  <div className="ltn__feature-icon">
                    <span>
                      <i className="icon-mechanic" />
                    </span>
                  </div>
                  <div className="ltn__feature-info">
                    <h3>
                      <a href="/service">Experienced Technicians</a>
                    </h3>
                    <p>
                      Our friendly and professional technicians are qualified
                      and experienced on a wide range of vehicles.
                    </p>
                    {/* <a className="ltn__service-btn" href="/service">
                        Read More
                      </a> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="ltn__feature-item ltn__feature-item-6 bg-white">
                  <div className="ltn__feature-icon">
                    <span>
                      <i className="icon-electric-car" />
                    </span>
                  </div>
                  <div className="ltn__feature-info">
                    <h3>
                      <a href="/service">All Makes &amp; Models</a>
                    </h3>
                    <p>
                      Whatever the brand or model of your vehicle, our
                      knowledgeable technicians have the skills to handle
                      different types of requirements.
                    </p>
                    {/* <a className="ltn__service-btn" href="/service">
                        Read More
                      </a> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row ltn__custom-gutter">
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="ltn__feature-item ltn__feature-item-6 active">
                  <div className="ltn__feature-icon">
                    <span>
                      <i className="icon-tag" />
                    </span>
                  </div>
                  <div className="ltn__feature-info">
                    <h3>
                      <a href="/service">Affordable Services</a>
                    </h3>
                    <p>
                      We offer professional services at the right price. Our
                      focus is to ensure your car’s safety and performance at
                      reasonable rates because we value your trust.
                    </p>
                    {/* <a className="ltn__service-btn" href="/service">
                        Read More
                      </a> */}
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-sm-6 col-12">
                <div className="ltn__feature-item ltn__feature-item-6 bg-white">
                  <div className="ltn__feature-icon">
                    <span>
                      <i className="icon-hand" />
                    </span>
                  </div>
                  <div className="ltn__feature-info">
                    <h3>
                      <a href="/service">Diagnostic Equipment</a>
                    </h3>
                    <p>
                      Equipped with advanced tools, we can accurately diagnose
                      the condition of your vehicle. This helps us fix it right
                      the first time, saving your time and money.
                    </p>
                    {/* <a className="ltn__service-btn" href="/service">
                        Read More
                      </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* FEATURE AREA END */}
        {/* THREE STEPS AREA START */}
        <div className="contact-feature-area before-bg-bottom section-bg-1 mb--30">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="ltn__contact-feature-item">
                  <div className="ltn__contact-feature-icon">
                    <img src="img/icons/9.png" alt="Icon Image" />
                  </div>
                  <div className="ltn__contact-feature-info">
                    <span className="h6">Find your nearest</span>
                    <br />
                    <span className="h2">Branch</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="ltn__contact-feature-item">
                  <div className="ltn__contact-feature-icon">
                    <img src="img/icons/8.png" alt="Icon Image" />
                  </div>
                  <div className="ltn__contact-feature-info">
                    <span className="h6">Make a booking by</span>
                    <br />
                    <span className="h2">Send Request</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="ltn__contact-feature-item">
                  <div className="ltn__contact-feature-icon">
                    <img src="img/icons/7.png" alt="Icon Image" />
                  </div>
                  <div className="ltn__contact-feature-info">
                    <span className="h6">For Confirmation</span>
                    <br />
                    <span className="h2">Get Phone Call</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* THREE STEPS AREA END */}
        {/* IMAGE SLIDER AREA START (img-slider-2) */}
        <div className="ltn__img-slider-area ltn__img-slider-2 pt-10">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area ltn__section-title-2 text-center">
                  <h6 className="section-subtitle ltn__secondary-color mt-100">
                    // Gallery
                  </h6>
                  <h1 className="section-title">
                    Team <span>Inspection</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              {GalleryImages &&
                GalleryImages.map((element) => (
                  <div className="col-lg-3">
                    <div className="ltn__img-slide-item-2">
                      <a
                        href={element.imagePath}
                        data-rel="lightcase:myCollection"
                      >
                        <img src={element.imagePath} alt="SlideImage" />
                      </a>
                    </div>
                  </div>
                ))}
            </div>
            <div className="btn-wrapper text-center">
              <Link
                to="/gallery"
                className="btn theme-btn-1 btn-effect-1 text-uppercase"
              >
                View All
              </Link>
            </div>
          </div>
        </div>
        <div>
          <br></br>
        </div>

        {/* IMAGE SLIDER AREA END */}
        {/* FOOTER AREA START (ltn__footer-2 ltn__footer-color-1) */}
        <footer className="ltn__footer-area ltn__footer-2 ltn__footer-color-1">
          <Footer />
        </footer>
        {/* FOOTER AREA END */}
      </div>
      {/* Body main wrapper end */}
      {/* preloader area start */}
      {/* <Preloader /> */}
    </div>
  );
};

export default WhyDensoServices;
