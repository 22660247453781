import "../App.css";
import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import MobileMenu from "../Components/MobileMenu";
import Footer from "../Components/Footer";
import axios from "axios";
import { SERVER_URL } from "../Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const Gallery = () => {

  const [GalleryImages, setGalleryImages] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    getGalleryImages();
  }, []);

  const getGalleryImages = () => {

    let url = SERVER_URL + `/api/portfolioImages/`;

    axios
      .get(url)
      .then((res) => {
        var reverseData = res.data.data.reverse();
        setGalleryImages(reverseData);

      })
      .catch((err) => {
        console.log(err);
        toast.error(err, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  return (
    <div className="App">
      <div className="body-wrapper">
        {/* HEADER AREA START (header-4) */}
        <header className="ltn__header-area ltn__header-4 ltn__header-6 ltn__header-transparent gradient-color-2">
          <Header />
        </header>
        {/* HEADER AREA END */}
        {/* Utilize Mobile Menu Start */}
        <div
          id="ltn__utilize-mobile-menu"
          className="ltn__utilize ltn__utilize-mobile-menu"
        >
          <MobileMenu />
        </div>
        {/* Utilize Mobile Menu End */}
        <div className="ltn__utilize-overlay" />
        {/* BREADCRUMB AREA START */}
        <div
          className="ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image"
          data-bg="img/bg/9.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                  <div className="section-title-area ltn__section-title-2">
                    <h6 className="section-subtitle ltn__secondary-color">
                      // Welcome to DENSO
                    </h6>
                    <h1 className="section-title white-color">Gallery</h1>
                  </div>
                  {/* <div className="ltn__breadcrumb-list">
                    <ul>
                      <li>
                        <a href="/home">Home</a>
                      </li>
                      <li>Gallery</li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* BREADCRUMB AREA END */}
        {/* <!-- IMAGE SLIDER AREA START (img-slider-2) --> */}
        <div className="ltn__img-slider-area ltn__img-slider-2  pt-50 pb-50">
          <div className="container-fluid">
            <div className="row">
              {GalleryImages &&
                GalleryImages.map((element) => (
                  <div className="col-lg-3">
                    <div className="ltn__img-slide-item-2">
                      <a href={element.imagePath} data-rel="lightcase:myCollection" >
                        <img src={element.imagePath} alt="SlideImage" />
                      </a>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        {/* <!-- IMAGE SLIDER AREA END --> */}
        {/* THREE STEPS AREA START */}
        <div className="contact-feature-area before-bg-bottom mb--30">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="ltn__contact-feature-item">
                  <div className="ltn__contact-feature-icon">
                    <img src="img/icons/9.png" alt="Icon Image" />
                  </div>
                  <div className="ltn__contact-feature-info">
                    <span className="h6">Find your nearest</span>
                    <br />
                    <span className="h2">Branch</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="ltn__contact-feature-item">
                  <div className="ltn__contact-feature-icon">
                    <img src="img/icons/8.png" alt="Icon Image" />
                  </div>
                  <div className="ltn__contact-feature-info">
                    <span className="h6">Make a booking by</span>
                    <br />
                    <span className="h2">Send Request</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="ltn__contact-feature-item">
                  <div className="ltn__contact-feature-icon">
                    <img src="img/icons/7.png" alt="Icon Image" />
                  </div>
                  <div className="ltn__contact-feature-info">
                    <span className="h6">For Confirmation</span>
                    <br />
                    <span className="h2">Get Phone Call</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* THREE STEPS AREA END */}
        {/* FOOTER AREA START (ltn__footer-2 ltn__footer-color-1) */}
        <footer className="ltn__footer-area ltn__footer-2 ltn__footer-color-1">
          <Footer />
        </footer>
        {/* FOOTER AREA END */}
      </div>
      {/* Body main wrapper end */}
      {/* preloader area start */}
      {/*  <Preloader /> */}
    </div>
  );
};

export default Gallery;
