import "../App.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { SERVER_URL } from "../Config";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import MobileMenu from "../Components/MobileMenu";
import { Link } from "react-router-dom";

const Locator = () => {
  const [Location, setLocation] = useState();
  useEffect(() => {
    loadData();
    window.scrollTo(0, 0);
  }, []);

  const loadData = () => {
    let url = SERVER_URL + `/api/locations/`;

    axios
      .get(url)
      .then((res) => {
        setLocation(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="App">
      <div className="body-wrapper">
        {/* HEADER AREA START (header-4) */}
        <header className="ltn__header-area ltn__header-4 ltn__header-6 ltn__header-transparent gradient-color-2">
          <Header />
        </header>
        {/* HEADER AREA END */}
        {/* Utilize Mobile Menu Start */}
        <div
          id="ltn__utilize-mobile-menu"
          className="ltn__utilize ltn__utilize-mobile-menu"
        >
          <MobileMenu />
        </div>
        {/* Utilize Mobile Menu End */}
        <div className="ltn__utilize-overlay" />
        {/* BREADCRUMB AREA START */}
        <div
          className="ltn__breadcrumb-area ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image mb-0"
          data-bg="img/bg/32.jpg"
        >
          <div className="container" id="branch-locations">
            <div className="row">
              <div className="col-lg-12">
                <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                  <div className="section-title-area ltn__section-title-2">
                    <h6 className="section-subtitle ltn__secondary-color">
                      // Our Branches
                    </h6>
                    <h1 className="section-title white-color">
                      Find your nearby location<span>.</span>
                    </h1>
                  </div>
                  {/* <div className="ltn__breadcrumb-list">
                    <ul>
                      <li>
                        <a href="/home">Home</a>
                      </li>
                      <li>Locations</li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* BREADCRUMB AREA END */}
        {/* GOOGLE MAP LOCATIONS AREA START */}
        <div className="ltn__google-map-locations-area">
          <div id="gmap" />
        </div>
        {/* GOOGLE MAP LOCATIONS AREA END */}
        {/* BRANCH LOCATION AREA START */}
        <div
          className="ltn__google-map-locations-list-area mt-0 mb-70 bg-image pt-115 pb-70"
          data-bg="img/bg/8.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ltn__state-location">
                  <h2 className="ltn__state-location-title">
                    DENSO, Kingdom of Saudi Arabia
                  </h2>
                </div>
              </div>
              {/* BRANCH START */}
              {Location &&
                Location.map((element) => (
                  <>
                    <div className="col-lg-4">
                      <div className="ltn__map-item bg-white">
                        <h3 className="ltn__location-name">
                          {element.branchName}
                        </h3>
                        <h5 className="ltn__location-single-info">
                          {/* <i className="fas fa-map-marked-alt" /> */}
                          {/* {element.branchName}, */}
                          {element.address}, {element.postCode}{" "}
                        </h5>
                        <h5 className="ltn__location-single-info">
                          <i className="fas fa-clock plr--4" /> Opening Saturday
                          till Thursday <br />
                          {element.openingSaturday +
                            "  to " +
                            element.tillThursday}
                        </h5>
                        <h4 className="ltn__location-single-info">
                          <i className="fas fa-phone-volume" />
                          <a href="tel:+966123456789">{element.phoneNumber}</a>
                        </h4>
                        <div className="btn-wrapper">
                          <a
                            href="/"
                            className="btn btn-transparent btn-border btn-effect-4"
                          >
                            <i className="fas fa-location-arrow" /> BOOKING
                          </a>
                          <a
                            href="mailto:djautoparts@dj-auto.com"
                            className="btn btn-transparent btn-border btn-effect-3"
                          >
                            <i className="fas fa-envelope" />
                            EMAIL{" "}
                          </a>
                          <a
                            href={
                              "https://www.google.com/maps/dir//@" +
                              element.latitude +
                              "," +
                              element.longitude +
                              "/" +
                              element.branchName
                            }
                            className="btn btn-transparent btn-border btn-effect-3"
                          >
                            <i className="fas fa-map-pin" /> DIRECTION
                          </a>
                        </div>
                      </div>
                    </div>
                  </>
                ))}

              {/* BRANCH END */}

              {/* BRANCH END */}
            </div>
          </div>
        </div>
        {/* BRANCH LOCATION AREA END */}
        {/* THREE STEPS AREA START */}
        <div className="contact-feature-area before-bg-bottom mb--30">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="ltn__contact-feature-item">
                  <div className="ltn__contact-feature-icon">
                    <img src="img/icons/9.png" alt="Icon Image" />
                  </div>
                  <div className="ltn__contact-feature-info">
                    <span className="h6">Find your nearest</span>
                    <br />
                    <span className="h2">Branch</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="ltn__contact-feature-item">
                  <div className="ltn__contact-feature-icon">
                    <img src="img/icons/8.png" alt="Icon Image" />
                  </div>
                  <div className="ltn__contact-feature-info">
                    <span className="h6">Make a booking by</span>
                    <br />
                    <span className="h2">Send Request</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="ltn__contact-feature-item">
                  <div className="ltn__contact-feature-icon">
                    <img src="img/icons/7.png" alt="Icon Image" />
                  </div>
                  <div className="ltn__contact-feature-info">
                    <span className="h6">For Confirmation</span>
                    <br />
                    <span className="h2">Get Phone Call</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* THREE STEPS AREA END */}
        {/* FOOTER AREA START (ltn__footer-2 ltn__footer-color-1) */}
        <footer className="ltn__footer-area ltn__footer-2 ltn__footer-color-1">
          <Footer />
        </footer>
        {/* FOOTER AREA END */}
      </div>
      {/* Body main wrapper end */}
      {/* preloader area start */}
      {/* <Preloader /> */}
    </div>
  );
};

export default Locator;
