import React, { Component } from "react";

const AboutUs = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="ltn__feature-item-box-wrap ltn__feature-item-box-wrap-2 section-bg-6 denso__feature-wrap">
              <div className="ltn__feature-item ltn__feature-item-8 ltn__border mb-10 mr-10">
                <div className="ltn__feature-icon">
                  <img src="img/icons/svg/5-madel.svg" alt="#" />
                </div>
                <div className="ltn__feature-info">
                  <h4>Brand Value Denso</h4>
                </div>
              </div>
              <div className="ltn__feature-item ltn__feature-item-8 ltn__border mb-10 mr-10">
                <div className="ltn__feature-icon">
                  <img src="img/icons/svg/13-car-owner.svg" alt="#" />
                </div>
                <div className="ltn__feature-info">
                  <h4>Car Owner Centred</h4>
                </div>
              </div>
              <div className="ltn__feature-item ltn__feature-item-8 ltn__border mb-10 mr-10">
                <div className="ltn__feature-icon">
                  <img src="img/icons/svg/14-trust.svg" alt="#" />
                </div>
                <div className="ltn__feature-info">
                  <h4>Trust</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 align-self-center">
            <div className="about-us-info-wrap">
              <div className="section-title-area ltn__section-title-2">
                <h6 className="section-subtitle ltn__secondary-color">
                  // The system upholds the following values as a guideline to
                  its operation:
                </h6>
                <h1 className="section-title">DENSO provides</h1>
                <p>
                  {" "}
                  A wide range of premium vehicle repair and maintenance
                  products in the Kingdom of Saudi Arabia.{" "}
                </p>
                <br />
                Denso Central Distributor for Denso and is building
                up a Denso Service Dealer Network to ensure affordable and
                professional repairs can be carried out to avoid poor fitment
                which could lead to product failures...
              </div>
            </div>
            <div className="btn-wrapper pb-20">
              <a href="/whydensoservices" className="btn theme-btn-3 btn-effect-1 ">
                Readmore
              </a>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="ltn__feature-item-box-wrap ltn__feature-item-box-wrap-2 section-bg-6 denso__feature-wrap">
              <div className="ltn__feature-item ltn__feature-item-8 ltn__border mb-10 mr-10">
                <div className="ltn__feature-icon">
                  <img src="img/icons/svg/15-easy-to-use.svg" alt="#" />
                </div>
                <div className="ltn__feature-info">
                  <h4>Ease of Use</h4>
                </div>
              </div>
              <div className="ltn__feature-item ltn__feature-item-8 ltn__border mb-10 mr-10">
                <div className="ltn__feature-icon">
                  <img src="img/icons/svg/16-speed.svg" alt="#" />
                </div>
                <div className="ltn__feature-info">
                  <h4>Speed</h4>
                </div>
              </div>
              <div className="ltn__feature-item ltn__feature-item-8 ltn__border mb-10 mr-10">
                <div className="ltn__feature-icon">
                  <img src="img/icons/svg/4-track.svg" alt="#" />
                </div>
                <div className="ltn__feature-info">
                  <h4>Fast Delivery</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
