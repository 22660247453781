import Header from "../Components/Header";
import MobileMenu from "../Components/MobileMenu";
import Footer from "../Components/Footer";
import React, { useState } from "react";
import axios from "axios";
import { SERVER_URL } from "../Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";

const Account = () => {
  var hashValueGetter = function(params) {
    return params.node ? params.node.rowIndex + 1 : null;
  };

  const colDefs = [
    {
      headerName: "S#",
      minWidth: 50,
      width: "15%",
      valueGetter: hashValueGetter,
      checkboxSelection: false,
      filter: false,
    },
    {
      headerName: "Vehicle",
      field: "vehicleType",
      minWidth: 150,
      width: "20%",
    },
    {
      headerName: "Model",
      field: "model",
      minWidth: 150,
      width: "20%",
    },
    {
      headerName: "Transmission",
      field: "transmission",
      minWidth: 150,
      width: "20%",
    },
    {
      headerName: "Service",
      field: "serviceDetails",
      minWidth: 200,
      width: "20%",
    },
    {
      headerName: "Branch Name",
      field: "branchName",
      minWidth: 150,
      width: "20%",
    },
    {
      headerName: "Booking Date",
      field: "bookingDate",
      minWidth: 150,
      width: "20%",
    },
    {
      headerName: "Booking Time",
      field: "bookingTime",
      minWidth: 150,
      width: "20%",
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    floatingFilter: true,
  };

  const onGridReady = (params) => {
    let url =
      SERVER_URL + `/api/bookings/list/${sessionStorage.getItem("customer")}`;

    axios
      .get(url)
      .then((res) => {
        //console.log(res.data.data);
        //setData(res.data.data);
        params.api.applyTransaction({ add: res.data.data });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const handleLogOut = () => {
    sessionStorage.setItem("customer", "");
    sessionStorage.setItem("customerName", "");
    sessionStorage.setItem("customerType", "");
  };

  const [customer, setCustomer] = useState({
    email: "",
    password: "",
    newPassword: "",
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setCustomer({ ...customer, [name]: value });
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();

    let url = SERVER_URL + `/api/customers/changepassword/`;

    axios
      .post(url, customer)
      .then((res) => {
        toast.success("New password has been update Successfully", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setCustomer({
          email: "",
          password: "",
          newPassword: "",
        });
      })
      .catch((err) => {
        console.log(err);

        toast.error(err, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const colWarrantyClaimsDefs = [
    {
      headerName: "S#",
      minWidth: 50,
      width: "15%",
      valueGetter: hashValueGetter,
      checkboxSelection: false,
      filter: false,
    },
    {
      headerName: "Vehicle Name",
      field: "vehicleName",
      minWidth: 200,
      width: "20%",
    },
    {
      headerName: "Model",
      field: "vehicleModel",
      minWidth: 150,
      width: "15%",
    },
    {
      headerName: "Customer/Shop Name",
      field: "companyName",
      minWidth: 200,
      width: "20%",
    },
    {
      headerName: "Customer No./Owner Name",
      field: "phoneNumber",
      minWidth: 250,
      width: "25%",
    },
    {
      headerName: "Failed Part",
      field: "salesOrderOrInvoiceNumber",
      minWidth: 200,
      width: "20%",
    },
    {
      headerName: "Install Date",
      field: "originalUnitInstallDate",
      minWidth: 200,
      width: "20%",
    },
    {
      headerName: "Failed Date",
      field: "failedDate",
      minWidth: 200,
      width: "20%",
    },
    {
      headerName: "Manufacturer",
      field: "manufacturer",
      minWidth: 200,
      width: "20%",
    },
    {
      headerName: "New Part Name",
      field: "newPartName",
      minWidth: 200,
      width: "20%",
    },
    {
      headerName: "Failure Reason",
      field: "failureReason",
      minWidth: 300,
      width: "30%",
    },
    {
      headerName: "Email",
      field: "email",
      minWidth: 200,
      width: "25%",
    },
  ];

  const onWarrantyGridReady = (params) => {
    let url = SERVER_URL + `/api/warrantyclaims/list/${sessionStorage.getItem("customer")}`;

    axios
      .get(url)
      .then((res) => {
        //console.log(res.data.data);
        //setData(res.data.data);
        params.api.applyTransaction({ add: res.data.data });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  return (
    <div className="App">
      <div className="body-wrapper">
        {/* HEADER AREA START (header-4) */}
        <header className="ltn__header-area ltn__header-4 ltn__header-6 ltn__header-transparent gradient-color-2">
          {/* ltn__header-top-area start */}
          <Header />
          {/* ltn__header-middle-area end */}
        </header>
        {/* HEADER AREA END */}
        {/* Utilize Mobile Menu Start */}
        <div
          id="ltn__utilize-mobile-menu"
          className="ltn__utilize ltn__utilize-mobile-menu"
        >
          <MobileMenu />
        </div>
        {/* Utilize Mobile Menu End */}
        <div className="ltn__utilize-overlay" />
        {/* BREADCRUMB AREA START */}
        <div
          className="ltn__breadcrumb-area ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image"
          data-bg="img/bg/36.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                  <div className="section-title-area ltn__section-title-2">
                    <h6 className="section-subtitle ltn__secondary-color">
                      // Welcome to our company
                    </h6>
                    <h1 className="section-title white-color">My Account</h1>
                  </div>
                  {/* <div className="ltn__breadcrumb-list">
                    <ul>
                      <li>
                        <a href="/home">Home</a>
                      </li>
                      <li>My Account</li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* BREADCRUMB AREA END */}
        {/* WISHLIST AREA START */}
        <div className="ltn__product-tab-area">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3">
                <div className="ltn__tab-menu-list mb-50">
                  <div className="nav">
                    <a
                      className="active show"
                      data-toggle="tab"
                      href="#liton_tab_1_1"
                    >
                      Booking History <i className="fas fa-file-alt" />
                    </a>
                    <a data-toggle="tab" href="#liton_tab_1_2">
                      Warranty Claim History <i className="fas fa-file-alt" />
                    </a>
                    <a data-toggle="tab" href="#liton_tab_1_3">
                      Password Change <i className="fas fa-lock" />
                    </a>
                    <a href="/Home" onClick={handleLogOut}>
                      Logout <i className="fas fa-sign-out-alt" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="tab-content">
                  {/* TAB 1 START */}
                  <div className="tab-pane fade active show" id="liton_tab_1_1">
                    {/* booking history start */}

                    <div
                      className="tab-pane fade active show"
                      id="liton_tab_1_1"
                    >
                      <div className="ltn__myaccount-tab-content-inner">
                        <p>
                          Hello{" "}
                          <strong>
                            {sessionStorage.getItem("customerName")}
                          </strong>
                        </p>
                      </div>
                      <div
                        className="ag-theme-balham-dark"
                        style={{
                          height: "475px",
                          width: "100%",
                          fontSize: 14,
                        }}
                      >
                        <AgGridReact
                          pagination={true}
                          paginationPageSize={10}
                          paginationAutoPageSize={true}
                          defaultColDef={defaultColDef}
                          rowHeight={35}
                          //rowData={data}
                          onGridReady={onGridReady}
                          columnDefs={colDefs}
                        ></AgGridReact>
                      </div>
                    </div>
                    {/* booking history end */}
                  </div>
                  {/* TAB 1 END */}

                  {/* TAB 2 START */}

                  <div className="tab-pane fade" id="liton_tab_1_2">
                    <div className="ltn__myaccount-tab-content-inner">
                      <p>
                        Hello{" "}
                        <strong>
                          {sessionStorage.getItem("customerName")}
                        </strong>
                      </p>
                    </div>
                    <div
                      className="ag-theme-balham-dark"
                      style={{
                        height: "475px",
                        width: "100%",
                        fontSize: 14,
                      }}
                    >
                      <AgGridReact
                        pagination={true}
                        paginationPageSize={10}
                        paginationAutoPageSize={true}
                        defaultColDef={defaultColDef}
                        rowHeight={35}
                        //rowData={data}
                        onGridReady={onWarrantyGridReady}
                        columnDefs={colWarrantyClaimsDefs}
                      ></AgGridReact>
                    </div>
                  </div>

                  {/* TAB 2 END */}
                  {/* TAB 3 START */}
                  {/* password change start */}
                  <div className="tab-pane fade" id="liton_tab_1_3">
                    <div className="ltn__myaccount-tab-content-inner">
                      <p>
                        Hello{" "}
                        <strong>
                          {sessionStorage.getItem("customerName")}
                        </strong>
                      </p>
                    </div>
                    <div className="ltn__myaccount-tab-content-inner">
                      {/* <p>New password has been update Successfully</p> */}
                      <div className="ltn__form-box">
                        <form action="#">
                          <fieldset>
                            <legend>Change Password</legend>
                            <div className="row">
                              <div className="col-md-12">
                                <label>Email</label>
                                <input
                                  type="text"
                                  name="email"
                                  id="email"
                                  placeholder="Type your registered email"
                                  value={customer.email}
                                  onChange={handleInput}
                                  required
                                />
                                <label>Current password</label>
                                <input
                                  type="password"
                                  name="password"
                                  id="password"
                                  placeholder="********"
                                  value={customer.password}
                                  onChange={handleInput}
                                  required
                                />
                                <label>New password:</label>
                                <input
                                  type="password"
                                  name="newPassword"
                                  id="newPassword"
                                  placeholder="********"
                                  value={customer.newPassword}
                                  onChange={handleInput}
                                  required
                                />
                              </div>
                            </div>
                          </fieldset>
                          <div className="btn-wrapper">
                            <button
                              type="submit"
                              className="btn theme-btn-1 btn-effect-1 text-uppercase"
                              onClick={handlePasswordChange}
                            >
                              Save Changes
                            </button>
                            <ToastContainer
                              position="bottom-left"
                              autoClose={5000}
                              hideProgressBar={false}
                              newestOnTop
                              closeOnClick
                              rtl={false}
                              pauseOnFocusLoss
                              draggable
                              pauseOnHover
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  {/* password change end */}
                  {/* TAB 3 END */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        {/* WISHLIST AREA START */}
        {/* THREE STEPS AREA START */}
        <div className="contact-feature-area before-bg-bottom mb--30">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="ltn__contact-feature-item">
                  <div className="ltn__contact-feature-icon">
                    <img src="img/icons/9.png" alt="Icon Image" />
                  </div>
                  <div className="ltn__contact-feature-info">
                    <span className="h6">Find your nearest</span>
                    <br />
                    <span className="h2">Branch</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="ltn__contact-feature-item">
                  <div className="ltn__contact-feature-icon">
                    <img src="img/icons/8.png" alt="Icon Image" />
                  </div>
                  <div className="ltn__contact-feature-info">
                    <span className="h6">Make a booking by</span>
                    <br />
                    <span className="h2">Send Request</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="ltn__contact-feature-item">
                  <div className="ltn__contact-feature-icon">
                    <img src="img/icons/7.png" alt="Icon Image" />
                  </div>
                  <div className="ltn__contact-feature-info">
                    <span className="h6">For Confirmation</span>
                    <br />
                    <span className="h2">Get Phone Call</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* THREE STEPS AREA END */}
        {/* FOOTER AREA START (ltn__footer-2 ltn__footer-color-1) */}
        <footer className="ltn__footer-area ltn__footer-2 ltn__footer-color-1">
          <Footer />
        </footer>
        {/* FOOTER AREA END */}
      </div>
      {/* Body main wrapper end */}
      {/* preloader area start */}
      {/* <Preloader /> */}
    </div>
  );
};

export default Account;
