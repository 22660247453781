import "../App.css";
import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import AboutUs from "../Components/AboutUs";
import Modal from "../Components/Modal";
import MobileMenu from "../Components/MobileMenu";
import { Link, useNavigate } from "react-router-dom";
import Preloader from "../Components/Preloader";
import axios from "axios";
import { SERVER_URL } from "../Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MultiSelect } from "react-multi-select-component";


const Home = () => {
  const navigate = useNavigate();
  const [Transmission, setTransmission] = useState();
  const [Branch, setBranch] = useState();
  const [Model, setModel] = useState();
  const [VehicleType, setVehicleType] = useState();
  const [TimeSlots, setTimeSlots] = useState();
  const [TempSlots, setTempSlots] = useState();
  const [PricePackage, setPricePackage] = useState();
  const [selectedOptions, setSelectedOptions] = useState();
  const [Warranty, setWarranty] = useState();
  const [GalleryImages, setGalleryImages] = useState();
  const initialArray = [];
 
  const [serviceCategoriesOptions, setServiceCategoriesOptions] = useState(
    initialArray
  );

  const [slectedBranch, setSeletedBranch] = useState({
    branchName : "Select Branch" ,
  });

  const [selected, setSelected] = useState([]);

  const [ReservedSlots, setReservedSlots] = useState({
    _id: "",
    firstName: "",
    lastName: "",
    email: "",
    branchName: "",
    phoneNumber: "",
    bookingDate: "",
    bookingTime: "",
  });

  useEffect(() => {
    const customer = sessionStorage.getItem("customer");
    const serviceName = sessionStorage.getItem("serviceName");
    const bName = sessionStorage.getItem("branchName");
    setSeletedBranch({ branchName: bName });
    const name = "branchName";
    const value = bName;
    //alert(slectedBranch.branchName);
    setServiceBooking({ ...serviceBooking, [name]: value });
    
    sessionStorage.setItem("serviceName", "");

    if (customer) {
      var booking = JSON.parse(localStorage.getItem("tempBooking"));

      if (booking != null) {
        let url = SERVER_URL + `/api/bookings/`;
        axios
          .post(url, {
            firstName: booking.firstName,
            lastName: booking.lastName,
            email: customer,
            phoneNumber: booking.phoneNumber,
            vehicleType: booking.vehicleType,
            model: booking.model,
            transmission: booking.transmission,
            waitingStatus: booking.waitingStatus,
            serviceDetails: booking.serviceDetails,
            branchName: booking.branchName,
            bookingDate: booking.bookingDate,
            bookingTime: booking.bookingTime,
            offer: booking.offer,
            isListed: booking.isListed,
          })
          .then((res) => {
            console.log(res);
            toast.success(res.data.message, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            localStorage.removeItem(customer);
            localStorage.removeItem("tempBooking");
          })
          .catch((err) => {
            console.log(err);
            toast.error(err, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
      }

      const Warranty = JSON.parse(localStorage.getItem("warranty"));

      if (Warranty != null) {
        let url = SERVER_URL + `/api/warrantyclaims`;

        axios
          .post(url, {
            companyName: Warranty.companyName,
            street: Warranty.street,
            cityStateZip: Warranty.cityStateZip,
            phoneNumber: Warranty.phoneNumber,
            homeOwnerName: Warranty.homeOwnerName,
            homeOwnerStreet: Warranty.homeOwnerStreet,
            homeOwnerCityStateZip: Warranty.homeOwnerCityStateZip,
            homeOwnerPhoneNumber: Warranty.homeOwnerPhoneNumber,
            salesOrderOrInvoiceNumber: Warranty.salesOrderOrInvoiceNumber,
            originalUnitInstallDate: Warranty.originalUnitInstallDate,
            failedDate: Warranty.failedDate,
            manufacturer: Warranty.manufacturer,
            unitModelNumber: Warranty.unitModelNumber,
            unitSerialNumber: Warranty.unitSerialNumber,
            unitPartNumber: Warranty.unitPartNumber,
            vehicleName: Warranty.vehicleName,
            vehicleModel: Warranty.vehicleModel,
            failureReason: Warranty.failureReason,
            newPartNumber: Warranty.newPartNumber,
            newPartName: Warranty.newPartName,
            newSerialNumber: Warranty.newSerialNumber,
            newPartInvoiceNumber: Warranty.newPartInvoiceNumber,
            message: Warranty.message,
            email: customer,
          })
          .then((res) => {
            toast.success(res.data.message, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          })
          .catch((err) => {
            console.log(err);

            toast.error(err, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });

        localStorage.removeItem("warranty");
      }
    }
    getTransmissions();
    getVehicleTypes();
    getModelYears();
    getBranches();
    getTimeSlots();
    getPricePackages();
    getServices();
    //getGalleryImages();
  }, []);

  const saveReservedSlots = () => {
    if (ReservedSlots != null) {
      let url = SERVER_URL + `/api/managetimeslots/`;
      axios
        .post(url, {
          firstName: ReservedSlots.firstName,
          lastName: ReservedSlots.lastName,
          email: ReservedSlots.email,
          phoneNumber: ReservedSlots.phoneNumber,
          branchName: ReservedSlots.branchName,
          bookingDate: ReservedSlots.bookingDate,
          bookingTime: ReservedSlots.bookingTime,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const getTransmissions = () => {
    let url = SERVER_URL + `/api/transmissions/`;

    axios
      .get(url)
      .then((res) => {
        var sortedData = res.data.data;
        sortedData.sort((a, b) =>
          a.transmissionName > b.transmissionName ? 1 : -1
        );
        setTransmission(sortedData);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const getVehicleTypes = () => {
    let url = SERVER_URL + `/api/vehicles/`;

    axios
      .get(url)
      .then((res) => {
        var sortedData = res.data.data;
        sortedData.sort((a, b) => (a.vehicleType > b.vehicleType ? 1 : -1));
        setVehicleType(sortedData);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const getModelYears = () => {
    let url = SERVER_URL + `/api/models/`;

    axios
      .get(url)
      .then((res) => {
        //setModel(res.data.data);
        var sortedData = res.data.data;
        sortedData.sort((a, b) => (a.modelYear > b.modelYear ? 1 : -1));
        setModel(sortedData);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const getBranches = () => {
    let url = SERVER_URL + `/api/locations/`;

    axios
      .get(url)
      .then((res) => {
        var sortedData = res.data.data;
        sortedData.sort((a, b) => (a.branchName > b.branchName ? 1 : -1));
        setBranch(sortedData);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const getServices = () => {
    let url = SERVER_URL + `/api/services/`;

    axios
      .get(url)
      .then((res) => {
        var sortedData = res.data.data;
        //setServiceCategories(data);
        // {data.map((element) => (
        //   alert(element.serviceCategory)
        // ))}

        {
          sortedData &&
            sortedData.forEach((element) => {
              setServiceCategoriesOptions((serviceCategoriesOptions) => [
                ...serviceCategoriesOptions,
                {
                  value: element.serviceCategory,
                  label: element.serviceCategory,
                },
              ]);
            });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const getTimeSlots = () => {
    let url = SERVER_URL + `/api/timeslots/`;

    axios
      .get(url)
      .then((res) => {
        setTimeSlots(res.data.data);
        setTempSlots(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
  const getPricePackages = () => {
    let url = SERVER_URL + `/api/packages/`;

    axios
      .get(url)
      .then((res) => {
        setPricePackage(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getReservedSlots = (params) => {
    var branchName = serviceBooking.branchName;
    var myStr = params;
    var newStr = myStr.replace("/", "_").replace("/", "_");
    let url = SERVER_URL + `/api/managetimeslots/` + newStr + "/" + branchName;

    axios
      .get(url)
      .then((res) => {
        setReservedSlots(res.data.data);
        var data = res.data.data;

        var previousList = TimeSlots;

        var newList = null;
        {
          data &&
            data.forEach((element) => {
              newList = previousList.filter(
                (item) => item.timeSlot !== element.bookingTime
              );
              previousList = newList;
              console.log(newList);
            });

          if (newList !== null) setTimeSlots(newList);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
  const [serviceBooking, setServiceBooking] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    vehicleType: "",
    model: "",
    transmission: "",
    waitingStatus: "",
    serviceDetails: "",
    branchName: "",
    bookingDate: "",
    bookingTime: "",
    offer: "",
    isListed: false,
  });
  // const getGalleryImages = () => {
  //   let url = SERVER_URL + `/api/portfolioImages/`;

  //   axios
  //     .get(url)
  //     .then((res) => {
  //       var reverseData = res.data.data.reverse().slice(0, 4);
  //       setGalleryImages(reverseData);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error(err, {
  //         position: "bottom-left",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //     });
  // };

  function refreshPage() {
    setTimeout(() => {
      window.location.reload(false);
    }, 500);
  }

  const handleInput = (e) => {
    const { name, value } = e.target;
     setServiceBooking({ ...serviceBooking, [name]: value });
  };

  const handleOnSelect = (e) => {
    const { name, value } = e.target;

    if (value !== "") {
      var myCurrentDate = new Date();
      let myDateString =
        ("0" + (myCurrentDate.getMonth() + 1)).slice(-2) +
        "/" +
        ("0" + myCurrentDate.getDate()).slice(-2) +
        "/" +
        myCurrentDate.getFullYear();

      const currentDate = new Date(myDateString);
      const selectedDate = new Date(e.target.value);

      if (currentDate.getTime() === selectedDate.getTime()) {
        let hours = myCurrentDate.getHours();
        let ampm = hours >= 12 ? "PM" : "AM";

        if (ampm.includes("AM")) {
          switch (hours) {
            case 1:
              hours = "01:00 AM";
              break;
            case 2:
              hours = "02:00 AM";
              break;
            case 3:
              hours = "03:00 AM";
              break;
            case 4:
              hours = "04:00 AM";
              break;
            case 5:
              hours = "05:00 AM";
              break;
            case 6:
              hours = "06:00 AM";
              break;
            case 7:
              hours = "07:00 AM";
              break;
            case 8:
              hours = "08:00 AM";
              break;
            case 9:
              hours = "09:00 AM";
              break;
            case 10:
              hours = "10:00 AM";
              break;
            case 11:
              hours = "11:00 AM";
              break;
            case 0:
              hours = "12:00 AM";
          }
          let counter = -1;
          {
            TempSlots &&
              TempSlots.forEach((element) => {
                if (element.timeSlot.includes(hours))
                  counter = element.slotIndex;
              });
          }
          if (counter !== -1) TempSlots.splice(0, counter);
          setTimeSlots(TempSlots);
        } else {
          // alert(hours);
          hours = hours - 12;
          switch (hours) {
            case 0:
              hours = "12:00 PM";
            case 1:
              hours = "01:00 PM";
              break;
            case 2:
              hours = "02:00 PM";
              break;
            case 3:
              hours = "03:00 PM";
              break;
            case 4:
              hours = "04:00 PM";
              break;
            case 5:
              hours = "05:00 PM";
              break;
            case 6:
              hours = "06:00 PM";
              break;
            case 7:
              hours = "07:00 PM";
              break;
            case 8:
              hours = "08:00 PM";
              break;
            case 9:
              hours = "09:00 PM";
            case 10:
              hours = "10:00 PM";
            case 11:
              hours = "11:00 PM";
              break;
          }

          let counter = -1;
          {
            TempSlots &&
              TempSlots.forEach((element) => {
                if (element.timeSlot.includes(hours))
                  counter = element.slotIndex;
              });
          }
          if (counter !== -1) TempSlots.splice(0, counter);
          setTimeSlots(TempSlots);
        }
        getReservedSlots(e.target.value);
      } else if (selectedDate.getTime() > currentDate.getTime()) {
        getTimeSlots();
        getReservedSlots(e.target.value);
      } else if (selectedDate.getTime() < currentDate.getTime()) {
        setTimeSlots([]);
      }
    }

    setServiceBooking({ ...serviceBooking, [name]: value });
  };

  const handleBookNow = (e) => {
   
     const name = "offer";
     const value = e.target.value;
   //alert(name +"  ==== "+ value);
   
   setServiceBooking({ offer: value });
   //setServiceBooking({ ...serviceBooking, [name]: value });
   console.log(serviceBooking);
    toast.info( value + " offer is selected ", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
   
    
    if (
      // serviceBooking.firstName === "" ||
      // serviceBooking.lastName === "" ||
      // serviceBooking.email === "" ||
      // serviceBooking.phoneNumber === "" ||
      serviceBooking.vehicleType === "" ||
      serviceBooking.vehicleType === "All Makes" ||
      serviceBooking.model === "" ||
      serviceBooking.model === "Year Model" ||
      serviceBooking.branchName === "" ||
      serviceBooking.branchName === "Select Branch" ||
      serviceBooking.transmission === "" ||
      serviceBooking.transmission === "All Models" ||
      serviceBooking.waitingStatus === "" ||
      serviceBooking.waitingStatus === "Select Preferred" ||
      serviceBooking.bookingDate === "" ||
      serviceBooking.bookingTime === ""
    ) {
      console.log(serviceBooking);
      toast.info("Please fill out all mandatory fields ", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    } else {
    handleSubmitRequest(e);
    }
  };

  const handleSubmitRequest = (e) => {
    e.preventDefault();

 debugger;

    let serviceCategories = null;

    {
      selected &&
        selected.forEach((element) => {
          if (serviceCategories == null) serviceCategories = element.label;
          else serviceCategories = serviceCategories + "," + element.label;
        });
    }

    setServiceBooking({ serviceDetails: serviceCategories });

    const customer = sessionStorage.getItem("customer");

    if (
      // serviceBooking.firstName === "" ||
      // serviceBooking.lastName === "" ||
      // serviceBooking.email === "" ||
      // serviceBooking.phoneNumber === "" ||
      serviceBooking.vehicleType === "" ||
      serviceBooking.vehicleType === "All Makes" ||
      serviceBooking.model === "" ||
      serviceBooking.model === "Year Model" ||
      serviceBooking.branchName === "" ||
      serviceBooking.branchName === "Select Branch" ||
      serviceBooking.transmission === "" ||
      serviceBooking.transmission === "All Models" ||
      serviceBooking.waitingStatus === "" ||
      serviceBooking.waitingStatus === "Select Preferred" ||
      serviceCategories === "" ||
      serviceCategories === "Select Required Service" ||
      serviceBooking.bookingDate === "" ||
      serviceBooking.bookingTime === ""
    ) {
      console.log(serviceBooking);
      toast.info("Please fill out all mandatory fields ", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      if (customer === "" || customer === null || customer === undefined) {
        localStorage.setItem("tempBooking", JSON.stringify(serviceBooking));

        navigate("/login");
      } else {
        console.log(serviceBooking);

        let url = SERVER_URL + `/api/bookings/`;
        const customer = sessionStorage.getItem("customer");

        axios
          .post(url, {
            // firstName: serviceBooking.firstName,
            // lastName: serviceBooking.lastName,
            //email: serviceBooking.email,
            email: customer,
            //phoneNumber: serviceBooking.phoneNumber,
            vehicleType: serviceBooking.vehicleType,
            model: serviceBooking.model,
            transmission: serviceBooking.transmission,
            waitingStatus: serviceBooking.waitingStatus,
            serviceDetails: serviceBooking.serviceCategories,
            branchName: serviceBooking.branchName,
            bookingDate: serviceBooking.bookingDate,
            bookingTime: serviceBooking.bookingTime,
            offer: serviceBooking.offer,
            isListed: serviceBooking.isListed,
          })
          .then((res) => {
            console.log(res);
            toast.success(res.data.message, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            // ReservedSlots.firstName = serviceBooking.firstName;
            // ReservedSlots.lastName = serviceBooking.lastName;
            ReservedSlots.email = customer;
            //ReservedSlots.phoneNumber = serviceBooking.phoneNumber;
            ReservedSlots.branchName = serviceBooking.branchName;
            ReservedSlots.bookingDate = serviceBooking.bookingDate;
            ReservedSlots.bookingTime = serviceBooking.bookingTime;
            setReservedSlots(ReservedSlots);
            saveReservedSlots();
            sessionStorage.setItem("branchName", "Select Branch");
            setServiceBooking({
              firstName: "",
              lastName: "",
              email: "",
              phoneNumber: "",
              vehicleType: "",
              model: "",
              transmission: "",
              waitingStatus: "",
              serviceDetails: "",
              branchName: "",
              bookingDate: "",
              bookingTime: "",
              offer: "",
              isListed: false,
            });
            
          })
          .catch((err) => {
            console.log(err);
            toast.error(err, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
      }
    }
  };

  return (
    <div className="App">
      <div className="body-wrapper">
        {/* HEADER AREA START (header-4) */}
        <header className="ltn__header-area ltn__header-4 ltn__header-6 ltn__header-transparent gradient-color-2">
          <Header />
        </header>
        {/* HEADER AREA END */}
        {/* Utilize Mobile Menu Start */}
        <div
          id="ltn__utilize-mobile-menu"
          className="ltn__utilize ltn__utilize-mobile-menu"
        >
          <MobileMenu />
        </div>
        {/* Utilize Mobile Menu End */}
        <div className="ltn__utilize-overlay" />
        {/* SLIDER AREA START (slider-4) */}
        <div className="ltn__slider-area ltn__slider-4 position-relative fix---">
          <div className="ltn__slide-one-active----- slick-slide-arrow-1----- slick-slide-dots-1----- arrow-white----- ltn__slide-animation-active">
            {/* HTML5 VIDEO */}
            <video autoPlay muted loop id="myVideo">
              <source src="media/workshop.mp4" type="video/mp4" />
            </video>
            {/* ltn__slide-item */}
            <div
              className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-4 bg-image bg-overlay-theme-black-80"
              data-bg
            >
              <div className="ltn__slide-item-inner text-left">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 align-self-center">
                      <div className="slide-item-car-dealer-form">
                        <div className="section-title-area ltn__section-title-2 text-center">
                          <h1 className="section-title  text-color-white d-none d-sm-block">
                            Any Car.{" "}
                            <span className="ltn__secondary-color-3">
                              Any Service
                            </span>{" "}
                          </h1>
                          <h3 className="text-color-white text-center mb-30 animated fadeIn d-none d-sm-block">
                            Make a booking today at any one of our 25+
                            locations.
                          </h3>
                          {/* <div className="ltn__car-dealer-form-item ltn__custom-icon--- ltn__icon-calendar col-lg-12 col-md-12"> */}
                          {/* <div className="btn-wrapper text-center mt-0 "> */}
                          {/* <button type="submit" className="btn theme-btn-1 btn-effect-1 text-uppercase">SUBMIT REQUEST</button> */}
                          {/* <a */}
                          {/* href="/Locator" */}
                          {/* className="show btn theme-btn-1 btn-effect-1 text-uppercase font-weight-bolder" */}
                          {/* style={{ fontSize: "24px" }} */}
                          {/* > */}
                          {/* - <i className="fas fa-map-pin" /> FIND ON */}
                          {/* LOCATOR - */}
                          {/* </a> */}
                          {/* </div> */}
                          {/* </div> */}
                        </div>
                        <div className="ltn__car-dealer-form-tab">
                          {/* <div className="ltn__tab-menu  text-uppercase text-center mb-30">
                                                <div className="nav">
                                                    <a className="active show" data-bs-toggle="tab" href="#"><i
                                                            className="fas fa-map-pin"></i>Find On Locator</a>
                                                </div>
                                            </div> */}
                          <div className="tab-content pb-10">
                            <div
                              className="tab-pane fade active show"
                              id="ltn__form_tab_1_1"
                            >
                              <div className="car-dealer-form-inner">
                                <form
                                  action="#"
                                  className="ltn__car-dealer-form-box row"
                                >
                                  {/* <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-lg-3 col-md-6">
                                    <input
                                      className="nice-select"
                                      type="text"
                                      name="firstName"
                                      id="firstName"
                                      value={serviceBooking.firstName}
                                      onChange={handleInput}
                                      placeholder="First Name"
                                      required
                                    />
                                  </div>
                                  <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-lg-3 col-md-6">
                                    <input
                                      className="nice-select"
                                      type="text"
                                      name="lastName"
                                      id="lastName"
                                      value={serviceBooking.lastName}
                                      onChange={handleInput}
                                      placeholder="Last Name"
                                      required
                                    />
                                  </div>
                                  <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-lg-3 col-md-6">
                                    <input
                                      className="nice-select"
                                      type="email"
                                      name="email"
                                      id="email"
                                      value={serviceBooking.email}
                                      onChange={handleInput}
                                      placeholder="Email Address"
                                      required
                                    />
                                  </div>
                                  <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-lg-3 col-md-6">
                                    <input
                                      className="nice-select"
                                      type="number"
                                      name="phoneNumber"
                                      id="phoneNumber"
                                      value={serviceBooking.phoneNumber}
                                      onChange={handleInput}
                                      placeholder="e.g:+966123456789"
                                      required
                                    />
                                  </div> */}
                                  <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-lg-3 col-md-6">
                                    <select
                                      className="nice-select"
                                      name="vehicleType"
                                      value={serviceBooking.vehicleType}
                                      onChange={handleInput}
                                      required
                                    >
                                      <option>All Makes</option>
                                      {VehicleType &&
                                        VehicleType.map((element) => (
                                          <option>{element.vehicleType}</option>
                                        ))}
                                    </select>
                                  </div>
                                  <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-lg-3 col-md-6">
                                    <select
                                      className="nice-select"
                                      name="transmission"
                                      value={serviceBooking.transmission}
                                      onChange={handleInput}
                                      required
                                    >
                                      <option>All Models</option>
                                      {Transmission &&
                                        Transmission.map((element) => (
                                          <option>
                                            {element.transmissionName}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                  <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-3 col-md-6">
                                    <div className="input-item">
                                      <select
                                        className="nice-select"
                                        name="model"
                                        value={serviceBooking.model}
                                        onChange={handleInput}
                                        required
                                      >
                                        <option>Year Model</option>
                                        {Model &&
                                          Model.map((element) => (
                                            <option>{element.modelYear}</option>
                                          ))}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-user col-lg-3 col-md-6">
                                    <select
                                      className="nice-select"
                                      name="branchName"
                                      id="branchName"
                                      value={serviceBooking.branchName}
                                      onChange={handleInput}
                                      required
                                    >
                                      <option>Select Branch</option>
                                      {Branch &&
                                        Branch.map((element) => (
                                          (element.branchName === slectedBranch.branchName) ? (
                                            <option selected={true}>{element.branchName}</option>
                                          ) : (
                                            <option>{element.branchName}</option>
                                          )
                                        ))}
                                    </select>
                                  </div>
                                  <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__contact-address-icon col-lg-3 col-md-6">
                                    <MultiSelect
                                      options={serviceCategoriesOptions}
                                      value={selected}
                                      onChange={setSelected}
                                      placeholder="Select Required Service"
                                    />

                                    {/* <Select
                                      placeholder="Select Required Service"
                                      options={serviceCategoriesOptions}
                                      isMulti
                                      closeMenuOnSelect={false}
                                      hideSelectedOptions={false}
                                      // components={{
                                      //   Option,
                                      // }}
                                      required
                                      onChange={handleChange}
                                      allowSelectAll={false}
                                      style={{
                                        flex: 1,
                                        display: "flex !important",
                                        margin: "-6px",
                                        flexWrap: "nowrap",
                                      }}
                                      value={selectedOptions}
                                    /> */}
                                  </div>
                                  {/* <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__contact-address-icon col-lg-3 col-md-6 ">
                                    <select
                                      name="serviceCategories"
                                      id="serviceCategories"
                                      multiple="[]"
                                    >
                                      <optgroup label="Services">
                                        {ServiceCategories &&
                                          ServiceCategories.map((element) => (
                                            <option>
                                              {element.serviceCategory}
                                            </option>
                                          ))}
                                        </optgroup>
                                    </select>
                                  </div> */}
                                  {/* <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-lg-3 col-md-6">
                                    <input
                                      className="nice-select"
                                      type="text"
                                      name="serviceDetails"
                                      value={serviceBooking.serviceDetails}
                                      onChange={handleInput}
                                      placeholder="Required Services?"
                                      required
                                    />
                                  </div> */}
                                  {/* <div
                                                                className="ltn__car-dealer-form-item ltn__custom-icon col-lg-3 col-md-6">
                                                                <input className="nice-select" type="date" name="ltn__date"
                                                                    placeholder="Date">
                                                            </div> */}
                                  <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__contact-address-icon col-lg-3 col-md-6">
                                    <select
                                      className="nice-select"
                                      name="waitingStatus"
                                      value={serviceBooking.waitingStatus}
                                      onChange={handleInput}
                                      required
                                    >
                                      <option>Select Preferred</option>
                                      <option>Dropping Off Vehicle</option>
                                      <option>Waiting</option>
                                    </select>
                                  </div>

                                  <div className="ltn__car-dealer-form-item ltn__datepicker mb-30 col-lg-3 col-md-6">
                                    <div
                                      className="input-group date"
                                      data-provide="datepicker"
                                      required
                                    >
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="bookingDate"
                                        value={serviceBooking.bookingDate}
                                        onSelect={handleOnSelect}
                                        placeholder="Select Date"
                                        autoComplete="off"
                                        required
                                      />
                                      <div className="input-group-addon">
                                        <i className="far fa-calendar-alt" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-meter col-lg-3 col-md-6">
                                    <select
                                      className="nice-select"
                                      name="bookingTime"
                                      value={serviceBooking.bookingTime}
                                      onChange={handleInput}
                                      required
                                    >
                                      <option>HH:MM</option>
                                      {TimeSlots &&
                                        TimeSlots.map((element) => (
                                          <option>{element.timeSlot}</option>
                                        ))}
                                    </select>
                                  </div>

                                  <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-12 col-md-12">
                                    <div className="btn-wrapper text-center mt-0 ">
                                      {/* <button type="submit" className="btn theme-btn-1 btn-effect-1 text-uppercase">SUBMIT REQUEST</button> */}
                                      <a
                                        href="#"
                                        className="btn theme-btn-1 btn-effect-1 text-uppercase"
                                        onClick={handleSubmitRequest}
                                      >
                                        BOOK NOW
                                      </a>
                                      <ToastContainer
                                        position="bottom-left"
                                        autoClose={5000}
                                        hideProgressBar={false}
                                        newestOnTop
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                      />
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* SLIDER AREA END */}
        {/* ABOUT US AREA START */}
        <div className="ltn__about-us-area pt-115 pb-95">
          <AboutUs />
        </div>
        {/* ABOUT US AREA END */}
        {/* CALL TO ACTION START (call-to-action-2) */}
        <div
          className="ltn__call-to-action-area call-to-action-2 pt-20 pb-20"
          data-bg="img/1.jpg--"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="call-to-action-inner call-to-action-inner-2 text-center">
                  <h2>
                    <i className="fa fa-arrow-circle-right" /> Get A Booking On
                    Call{" "}
                  </h2>
                  <div className="btn-wrapper">
                    <a
                      className="btn btn-effect-4 btn-white"
                      href="tel:+966549126544"
                    >
                      <i className="fas fa-phone-volume" /> MAKE A CALL
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* CALL TO ACTION END */}
        {/* FEATURE AREA START ( Feature - 3) */}
        <div
          className="ltn__feature-area bg-image pt-115 pb-80"
          data-bg="img/bg/8.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area ltn__section-title-2 section-title-style-3">
                  <div className="section-brief-in">
                    <p>
                      You'll be comfortable choosing Denso Service because we
                      are pioneers in Car Air conditioning system
                    </p>
                  </div>
                  <div className="section-title-in">
                    <h6 className="section-subtitle">// DENSO SERVICS</h6>
                    <h1 className="section-title">
                      Clean and quality service<span>.</span>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="row  justify-content-center">
                  <div className="col-lg-12 col-md-6 col-12">
                    <div className="ltn__feature-item ltn__feature-item-3 text-right bg-white">
                      <div className="ltn__feature-icon">
                        <span>
                          <i className="icon-maintenance-1" />
                        </span>
                      </div>
                      <div className="ltn__feature-info">
                        <h2>
                          <Link to="/service">AC Servicing</Link>
                        </h2>
                        <p>
                          Quality maintenance to extend the life of your vehicle
                          ...
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-6 col-12">
                    <div className="ltn__feature-item ltn__feature-item-3 text-right bg-white">
                      <div className="ltn__feature-icon">
                        <span>
                          <i className="icon-repair" />
                        </span>
                      </div>
                      <div className="ltn__feature-info">
                        <h2>
                          <Link to="/service">Engine service</Link>
                        </h2>
                        <p>
                          Engine repair &amp; replacement with performance check
                          ...
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-6 col-12">
                    <div className="ltn__feature-item ltn__feature-item-3 text-right bg-white">
                      <div className="ltn__feature-icon">
                        <span>
                          <i className="icon-car-parts-6" />
                        </span>
                      </div>
                      <div className="ltn__feature-info">
                        <h2>
                          <Link to="/service">Suspension &amp; Drivetrain</Link>
                        </h2>
                        <p>
                          Precision alignment for smooth &amp; stable handling
                          ...
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="feature-banner-img text-center mb-30">
                  <img src="img/others/2.png" alt="#" />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="row  justify-content-center">
                  <div className="col-lg-12 col-md-6 col-12">
                    <div className="ltn__feature-item ltn__feature-item-3 bg-white">
                      <div className="ltn__feature-icon">
                        <span>
                          <i className="icon-car-parts-4" />
                        </span>
                      </div>
                      <div className="ltn__feature-info">
                        <h2>
                          <Link to="/service">
                            Electrical &amp; Electronics
                          </Link>
                        </h2>
                        <p>
                          Complete electrical system check-up &amp; repair, From
                          alternators to spark plugs, we can correct any faults
                          or variances ...
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-6 col-12">
                    <div className="ltn__feature-item ltn__feature-item-3 bg-white">
                      <div className="ltn__feature-icon">
                        <span>
                          <i className="icon-car-parts-9" />
                        </span>
                      </div>
                      <div className="ltn__feature-info">
                        <h2>
                          <Link to="/service">
                            Air Conditioning Service, Maintenance and Repair
                          </Link>
                        </h2>
                        <p>
                          Free Health Checks, Air-conditioning Anti-bacterial
                          clean, air-conditioning re-gas, Checking Freon level,
                          ...
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* FEATURE AREA END */}
        {/* OUR HISTORY AREA START */}
        <div className="ltn__our-history-area pb-100 pt-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area ltn__section-title-2 text-left">
                  <h6 className="section-subtitle">// Denso &amp; insights</h6>
                  <h1 className="section-title">
                    Why Denso Service<span>?</span>
                  </h1>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="ltn__our-history-inner">
                  <div className="ltn__tab-menu text-uppercase">
                    <div className="nav">
                      <a
                        data-toggle="tab"
                        href="#liton_tab_2_1"
                        className="active show text-center"
                      >
                        <i className="icon-car-parts-9 fa-4x" />
                        <br />
                        <span>Wide Range</span>
                      </a>
                      <a
                        data-toggle="tab"
                        href="#liton_tab_2_2"
                        className="text-center"
                      >
                        <i className="icon-tag fa-4x" />
                        <br />
                        <span>Affordable Services</span>{" "}
                      </a>
                      <a
                        data-toggle="tab"
                        href="#liton_tab_2_3"
                        className="text-center"
                      >
                        <i className="icon-electric-car fa-4x" />
                        <br />
                        <span>All Makes &amp; Models</span>
                      </a>
                      <a
                        data-toggle="tab"
                        href="#liton_tab_2_4"
                        className="text-center"
                      >
                        <i className="icon-mechanic fa-4x" />
                        <br />
                        <span>Experienced Technicians</span>
                      </a>
                      <a
                        data-toggle="tab"
                        href="#liton_tab_2_5"
                        className="text-center"
                      >
                        <i className="icon-hand fa-4x" />
                        <br />
                        <span>Diagnostic Equipment</span>
                      </a>
                    </div>
                  </div>
                  <div className="tab-content">
                    <div
                      className="tab-pane fade active show"
                      id="liton_tab_2_1"
                    >
                      <div className="ltn__product-tab-content-inner">
                        <div className="row">
                          <div className="col-lg-6 align-self-center">
                            <div className="about-us-img-wrap about-img-left">
                              <img
                                src="img/img-slide/12.jpg"
                                alt="SlideImage"
                              />
                              <div className="ltn__history-icon">
                                <i className="icon-car-parts-9" />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 align-self-center">
                            <div className="about-us-info-wrap">
                              <div className="section-title-area ltn__section-title-2">
                                <h6 className="section-subtitle">// Denso</h6>
                                <h1 className="section-title">
                                  <Link to="/whydensoservices">
                                    Provide Wide Range<span>.</span>
                                  </Link>
                                </h1>
                                <p>
                                  Denso is your one-stop solution for all your
                                  car repair and servicing needs.
                                </p>
                              </div>
                              <p>
                                {/* Lorem ipsum dolor sit amet, consectetur adipis
                                icing elit, sed do eius mod tempor incididunt ut
                                labore et dolore magna aliqua. */}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade " id="liton_tab_2_2">
                      <div className="ltn__product-tab-content-inner">
                        <div className="row">
                          <div className="col-lg-6 align-self-center">
                            <div className="about-us-img-wrap about-img-left">
                              <img
                                src="img/img-slide/11.jpg"
                                alt="SlideImage"
                              />
                              <div className="ltn__history-icon">
                                <i className="icon-tag" />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 align-self-center">
                            <div className="about-us-info-wrap">
                              <div className="section-title-area ltn__section-title-2">
                                <h6 className="section-subtitle ltn__secondary-color">
                                  // Denso
                                </h6>
                                <h1 className="section-title">
                                  <Link to="/whydensoservices">
                                    Affordable Services<span>.</span>
                                  </Link>
                                </h1>
                                <p>
                                  We offer professional services at the right
                                  price.{" "}
                                </p>
                              </div>
                              <p>
                                Our focus is to ensure your car’s safety and
                                performance at reasonable rates because we value
                                your trust.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="liton_tab_2_3">
                      <div className="ltn__product-tab-content-inner">
                        <div className="row">
                          <div className="col-lg-6 align-self-center">
                            <div className="about-us-img-wrap about-img-left">
                              <img
                                src="img/img-slide/13.jpg"
                                alt="SlideImage"
                              />
                              <div className="ltn__history-icon">
                                <i className="icon-electric-car" />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 align-self-center">
                            <div className="about-us-info-wrap">
                              <div className="section-title-area ltn__section-title-2">
                                <h6 className="section-subtitle ltn__secondary-color">
                                  // Denso
                                </h6>
                                <h1 className="section-title">
                                  <Link to="/whydensoservices">
                                    All Makes &amp; Models<span>.</span>
                                  </Link>
                                </h1>
                                <p>
                                  Whatever the brand or model of your vehicle,{" "}
                                </p>
                              </div>
                              <p>
                                our knowledgeable technicians have the skills to
                                handle different types of requirements.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="liton_tab_2_4">
                      <div className="ltn__product-tab-content-inner">
                        <div className="row">
                          <div className="col-lg-6 align-self-center">
                            <div className="about-us-img-wrap about-img-left">
                              <img
                                src="img/img-slide/35.jpg"
                                alt="SlideImage"
                              />
                              <div className="ltn__history-icon">
                                <i className="icon-mechanic" />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 align-self-center">
                            <div className="about-us-info-wrap">
                              <div className="section-title-area ltn__section-title-2">
                                <h6 className="section-subtitle ltn__secondary-color">
                                  // Denso
                                </h6>
                                <h1 className="section-title">
                                  <Link to="/whydensoservices">
                                    Experienced Technicians<span>.</span>
                                  </Link>
                                </h1>
                                <p>
                                  Our friendly and professional technicians are
                                  qualified{" "}
                                </p>
                              </div>
                              <p>
                                and experienced on a wide range of vehicles.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="liton_tab_2_5">
                      <div className="ltn__product-tab-content-inner">
                        <div className="row">
                          <div className="col-lg-6 align-self-center">
                            <div className="about-us-img-wrap about-img-left">
                              <img
                                src="img/img-slide/36.jpg"
                                alt="SlideImage"
                              />
                              <div className="ltn__history-icon">
                                <i className="icon-hand" />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 align-self-center">
                            <div className="about-us-info-wrap">
                              <div className="section-title-area ltn__section-title-2">
                                <h6 className="section-subtitle ltn__secondary-color">
                                  // Denso
                                </h6>
                                <h1 className="section-title">
                                  <Link to="/whydensoservices">
                                    Diagnostic Equipment<span>.</span>
                                  </Link>
                                </h1>
                                <p>
                                  Equipped with advanced tools, we can
                                  accurately diagnose the condition of your
                                  vehicle.{" "}
                                </p>
                              </div>
                              <p>
                                This helps us fix it right the first time,
                                saving your time and money.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* PRICING PLAN AREA END */}
        {/* <!-- GET A Warranty CLAIM AREA START --> */}
        <div className="ltn__get-a-free-service-area get-a-free-service-margin pt-120 pb-100 section-bg-1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="get-a-free-service-inner bg-white text-center pt-115 pb-100">
                  <div className="call-to-img">
                    <img src="img/bg/10.png" alt="WarrantyImage" />
                  </div>
                  <div className="call-to-action-inner-content">
                    <div className="section-title-area ltn__section-title-2 text-center">
                      <h6 className="section-subtitle ltn__secondary-color">
                        // DENSO //
                      </h6>
                      <h1 className="section-title">
                        Get A Warranty Claim<span>.</span>
                      </h1>
                    </div>
                    <div className="btn-wrapper">
                      <Link
                        to="/WarrantyClaim"
                        className="btn theme-btn-1 btn-effect-1 text-uppercase"
                      >
                        submit claim
                      </Link>
                    </div>
                  </div>
                  <span className="call-to-circle-1"></span>
                  <span className="call-to-circle-2 fa-spin"></span>
                  <span className="call-to-bg-icon">
                    <i className="icon-automobile"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- GET A Warranty CLAIM AREA END --> */}
        {/* PRICING PLAN AREA START */}
        <div
          className="ltn__pricing-plan-area pt-115 pb-120 section-bg-1"
          id="liton_pricing"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area ltn__section-title-2 text-center ">
                  <h6 className="section-subtitle ltn__secondary-color">
                    // Denso
                  </h6>
                  <h1 className="section-title">
                    PRICING PLAN<span>.</span>
                  </h1>
                </div>
              </div>
            </div>
            <div className="row ltn__price-slider-one-active slick-arrow-1 justify-content-center--- mt-50 flex-nowrap">
              {PricePackage &&
                PricePackage.map((element) => (
                  <>
                    {element.mostPopular ? (
                      <>
                        <div className="col-lg-3 col-sm-6">
                          <div className="ltn__pricing-plan-item text-center bg-white active">
                            <span className="pricing-badge">Most Popular</span>
                            <h2 className="pricing-title">
                              {element.serviceName}
                            </h2>
                            <div className="pricing-price">
                              <h2>
                                <sup>{element.currency}</sup>
                                {element.price}
                                <sub>/{element.offer}</sub>
                              </h2>
                            </div>
                            <ul>
                              <li />
                            </ul>
                            <div className="btn-wrapper">
                              <button
                               // name={element.serviceName}
                                value={element.serviceName}
                                className="theme-btn-2 btn"
                                onClick={handleBookNow}
                              >
                                BOOK NOW
                              </button>
                              <ToastContainer
                                        position="bottom-left"
                                        autoClose={5000}
                                        hideProgressBar={false}
                                        newestOnTop
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                      />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-lg-3 col-sm-6">
                          <div className="ltn__pricing-plan-item text-center bg-white">
                            <h2 className="pricing-title">
                              {element.serviceName}
                            </h2>
                            <div className="pricing-price">
                              <h2>
                                <sup>{element.currency}</sup>
                                {element.price}
                                <sub>/{element.offer}</sub>
                              </h2>
                            </div>
                            <ul>
                              <li />
                            </ul>
                            <div className="btn-wrapper">
                              <button
                                //name={element.serviceName}
                                value={element.serviceName}
                                className="theme-btn-2 btn"
                                onClick={handleBookNow}
                              >
                                BOOK NOW
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ))}
            </div>
          </div>
        </div>
        {/* PRICING PLAN AREA END */}
        {/* IMAGE SLIDER AREA START (img-slider-2) */}
        {/* <div className="ltn__img-slider-area ltn__img-slider-2 pt-10">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area ltn__section-title-2 text-center">
                  <h6 className="section-subtitle ltn__secondary-color mt-100">
                    // Gallery
                  </h6>
                  <h1 className="section-title">
                    Team <span>Inspection</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              {GalleryImages &&
                GalleryImages.map((element) => (
                  <div className="col-lg-3">
                    <div className="ltn__img-slide-item-2">
                      <a
                        href={element.imagePath}
                        data-rel="lightcase:myCollection"
                      >
                        <img src={element.imagePath} alt="SlideImage" />
                      </a>
                    </div>
                  </div>
                ))}
            </div>
            <div className="btn-wrapper text-center">
              <Link
                to="/gallery"
                className="btn theme-btn-1 btn-effect-1 text-uppercase"
              >
                View All
              </Link>
            </div>
          </div>
        </div> */}
        {/* IMAGE SLIDER AREA END */}
        {/* GOOGLE MAP LOCATIONS AREA START */}
        <div className="container pt-50">
          <div className="ltn__google-map-locations-area  bg-image pt-50 pb-50 ">
            <div className="row text-center">
              <div className="col-lg-12 align-self-center">
                <div className="section-title-area ltn__section-title-2 ">
                  <h6 className="section-subtitle">// DENSO BRANCHES //</h6>
                  <h1 className="section-title">
                    Find your nearby location<span>.</span>
                  </h1>
                </div>
                <div className="ltn__google-map-locations-area">
                  <div id="gmap" style={{ height: "50vh" }} />
                </div>
                <div className="btn-wrapper pb-20">
                  <Link
                    to="/locator"
                    onClick={refreshPage}
                    className="btn theme-btn-1 btn-effect-1 text-uppercase"
                  >
                    VIEW ALL BRANCHES
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* GOOGLE MAP LOCATIONS AREA END */}
        {/* THREE STEPS AREA START */}
        <div className="contact-feature-area before-bg-bottom mb--30">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="ltn__contact-feature-item">
                  <div className="ltn__contact-feature-icon">
                    <img src="img/icons/9.png" alt="IconImage" />
                  </div>
                  <div className="ltn__contact-feature-info">
                    <span className="h6">Find your nearest</span>
                    <br />
                    <span className="h2">Branch</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="ltn__contact-feature-item">
                  <div className="ltn__contact-feature-icon">
                    <img src="img/icons/8.png" alt="IconImage" />
                  </div>
                  <div className="ltn__contact-feature-info">
                    <span className="h6">Make a booking by</span>
                    <br />
                    <span className="h2">Send Request</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="ltn__contact-feature-item">
                  <div className="ltn__contact-feature-icon">
                    <img src="img/icons/7.png" alt="IconImage" />
                  </div>
                  <div className="ltn__contact-feature-info">
                    <span className="h6">For Confirmation</span>
                    <br />
                    <span className="h2">Get Phone Call</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* THREE STEPS AREA END */}
        {/* FOOTER AREA START (ltn__footer-2 ltn__footer-color-1) */}
        <footer className="ltn__footer-area ltn__footer-2 ltn__footer-color-1">
          <Footer />
        </footer>
        {/* FOOTER AREA END */}
        {/* MODAL AREA START (Newsletter Modal) */}
        <div className="ltn__modal-area ltn__newsletter-popup-1 d-none----">
          {/* <Modal /> */}
        </div>
        {/* MODAL AREA END */}
      </div>
      {/* Body main wrapper end */}
      {/* preloader area start */}
      {/*  <Preloader /> */}
    </div>
  );
};

export default Home;
