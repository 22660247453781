import React, { useState, useEffect } from "react";
import axios from "axios";
import { SERVER_URL } from "../Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";

const AddNewLocation = () => {
  const [Location, setLocation] = useState({
    _id: "",
    branchName: "",
    email: "",
    phoneNumber: "",
    address: "",
    openingSaturday: "",
    tillThursday: "",
    latitude: "",
    longitude: "",
    postCode: "",
  });

  const [data, setData] = useState();

  useEffect(() => {
    onGridReady();
  }, []);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setLocation({ ...Location, [name]: value });
  };

  const saveLocation = (e) => {
    e.preventDefault();

    if (Location._id !== "" && Location._id !== undefined) {
      let url = SERVER_URL + `/api/locations/` + Location._id;
      axios
        .put(url, Location)
        .then((res) => {
          onGridReady();
          toast.success(res.data.message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLocation({
            _id: "",
            branchName: "",
            email: "",
            phoneNumber: "",
            address: "",
            openingSaturday: "",
            tillThursday: "",
            latitude: "",
            longitude: "",
            postCode: "",
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error(err, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } else if (Location._id === "" || Location._id === undefined) {
      let url = SERVER_URL + `/api/locations/`;

      axios
        .post(url, Location)
        .then((res) => {
          onGridReady();
          toast.success(res.data.message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLocation({
            _id: "",
            branchName: "",
            email: "",
            phoneNumber: "",
            address: "",
            openingSaturday: "",
            tillThursday: "",
            latitude: "",
            longitude: "",
            postCode: "",
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error(err, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    }


  };
  var hashValueGetter = function (params) {
    return params.node ? params.node.rowIndex + 1 : null;
  };

  const colDefs = [
    {
      headerName: "S#",
      minWidth: 50,
      width: "5%",
      valueGetter: hashValueGetter,
      checkboxSelection: false,
      filter: false,
    },
    {
      headerName: "id",
      field: "_id",
      minWidth: 60,
      hide: true,
    },
    {
      headerName: "Branch Name",
      field: "branchName",
      minWidth: 150,
      width: "10%",
    },
    {
      headerName: "Email",
      field: "email",
      minWidth: 200,
      width: "15%",
    },
    {
      headerName: "Phone #",
      field: "phoneNumber",
      minWidth: 120,
      width: "10%",
    },

    {
      headerName: "Opening Hours",
      field: "openingSaturday",
      minWidth: 150,
      width: "5%",
    },
    {
      headerName: "Latitude",
      field: "latitude",
      minWidth: 120,
      width: "10%",
    },
    {
      headerName: "Longitude",
      field: "longitude",
      minWidth: 120,
      width: "10%",
    },
    {
      headerName: "Postal Code",
      field: "postCode",
      minWidth: 120,
      width: "10%",
    },
    {
      headerName: "Address",
      field: "address",
      minWidth: 150,
      width: "15%",
    },
    {
      headerName: "Action",
      field: "Action",
      minWidth: 180,
      width: "20%",
      cellRenderer: ActionRenderer,
      sortable: false,
      filter: false,
    },
  ];
  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
    floatingFilter: true,
  };

  const buttonStyle = {
    fontWeight: "bold",
    width: 60,
    marginRight: 5,
    marginLeft: 5,
    background: "#e30613",
  };
  const onClickEdit = (params) => {
    const selectedRows = params.api.getSelectedRows();

    if (selectedRows.length === 1) {
      setLocation({
        _id: selectedRows[0]._id,
        branchName: selectedRows[0].branchName,
        email: selectedRows[0].email,
        phoneNumber: selectedRows[0].phoneNumber,
        address: selectedRows[0].address,
        openingSaturday: selectedRows[0].openingSaturday,
        tillThursday: selectedRows[0].tillThursday,
        latitude: selectedRows[0].latitude,
        longitude: selectedRows[0].longitude,
        postCode: selectedRows[0].postCode,
      });
    }
  };

  const onConfirmDelete = (params) => {
    try {
      let url = SERVER_URL + `/api/locations/` + params;

      axios
        .delete(url)
        .then((res) => {
          onGridReady();
          toast.success(res.data.message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error(err, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } catch (ex) { }


  };

  const onCancelDelete = (params) => { };

  const onClickDelete = (params) => {
    const selectedRows = params.api.getSelectedRows();

    if (selectedRows.length === 1) {
      window.confirm("Are you sure you wish to delete this item?")
        ? onConfirmDelete(selectedRows[0]._id)
        : onCancelDelete(selectedRows[0]._id);
    }
  };
  function ActionRenderer(params) {
    return (
      <span>
        <button style={buttonStyle} onClick={() => onClickEdit(params)}>
          Edit
        </button>

        <button style={buttonStyle} onClick={() => onClickDelete(params)}>
          Delete
        </button>
      </span>
    );
  }
  const onGridReady = (params) => {
    let url = SERVER_URL + `/api/locations/`;

    axios
      .get(url)
      .then((res) => {
        setData(res.data.data);
        //params.api.applyTransaction({ add: res.data.data });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  return (
    <div>
      {" "}
      <div className="ltn__myaccount-tab-content-inner">
        {/* <p className="bg-green">New changes has been update Successfully</p> */}
        <div className="ltn__form-box">
          <form action="#">
            <fieldset>
              <legend>Location </legend>
              <div className="row">
                <div className="col-md-6">
                  <div className="input-item input-item-map ltn__custom-icon">
                    <input
                      type="text"
                      name="branchName"
                      placeholder="Branch Name"
                      value={Location.branchName}
                      onChange={handleInput}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-item input-item-email ltn__custom-icon">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email address"
                      value={Location.email}
                      onChange={handleInput}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="input-item input-item-phone ltn__custom-icon">
                    <input
                      type="number"
                      name="phoneNumber"
                      placeholder="e.g:+966567645120"
                      value={Location.phoneNumber}
                      onChange={handleInput}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-item input-item-add ltn__custom-icon">
                    <input
                      type="number"
                      name="postCode"
                      placeholder="Postal Code"
                      value={Location.postCode}
                      onChange={handleInput}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-item input-item-add ltn__custom-icon">
                    <input
                      type="text"
                      name="address"
                      placeholder="Address"
                      value={Location.address}
                      onChange={handleInput}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="input-item input-item-time">
                    <label>Opening Saturday</label>
                    <input
                      placeholder="HH:MM-AM/PM"
                      type="text"
                      name="openingSaturday"
                      value={Location.openingSaturday}
                      onChange={handleInput}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="input-item input-item-time">
                    <label>Till Thursday</label>
                    <input
                      placeholder="HH:MM-AM/PM"
                      type="text"
                      name="tillThursday"
                      value={Location.tillThursday}
                      onChange={handleInput}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <label>Add Latitude</label>
                  <input
                    placeholder="00.0000000"
                    type="number"
                    name="latitude"
                    value={Location.latitude}
                    onChange={handleInput}
                    required
                  />
                </div>
                <div className="col-md-3">
                  <label>Add Longitude</label>
                  <input
                    placeholder="00.0000000"
                    type="number"
                    name="longitude"
                    value={Location.longitude}
                    onChange={handleInput}
                    required
                  />
                </div>
              </div>
              <div className="btn-wrapper">
                <button
                  type="submit"
                  className="btn theme-btn-1 btn-effect-1 text-uppercase"
                  onClick={saveLocation}
                >
                  Save Changes
                </button>
                <ToastContainer
                  position="bottom-left"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </div>
            </fieldset>

            <div className="ltn__myaccount-tab-content-inner denso__myaccount-tab">
              <div className="table-responsive">
                <div
                  className="ag-theme-balham-dark"
                  style={{
                    height: "475px",
                    width: "100%",
                    fontSize: 14,
                  }}
                >
                  <AgGridReact
                    pagination={true}
                    paginationPageSize={10}
                    paginationAutoPageSize={true}
                    defaultColDef={defaultColDef}
                    rowHeight={35}
                    rowData={data}
                    //onGridReady={onGridReady}
                    columnDefs={colDefs}
                    rowSelection="single"
                  ></AgGridReact>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNewLocation;
