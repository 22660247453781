import React, { Component } from "react";
import { Link } from "react-router-dom";

const MobileMenu = () => {
  function refreshPage() {
    setTimeout(() => {
      window.location.reload(false);
    }, 500);
  }

  return (
    <div className="ltn__utilize-menu-inner ltn__scrollbar">
      <div className="ltn__utilize-menu-head">
        <div className="site-logo">
          <Link to="/">
            <img src="img/logo.png" alt="Logo" />
          </Link>
        </div>
        <button className="ltn__utilize-close">×</button>
      </div>
      <div className="ltn__utilize-menu">
        <ul>
          <li>
            <Link to="/" onClick={refreshPage}></Link>
          </li>
          <li>
            <Link to="/" onClick={refreshPage}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/service">Services</Link>
          </li>
          <li>
            <Link to="/Whydensoservices">Why Denso Service?</Link>
          </li>
          <li>
            <Link to="/locator" onClick={refreshPage}>
              Locator
            </Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </div>
      <div className="ltn__utilize-buttons ltn__utilize-buttons-2">
        <ul>
          <li>
            <a href="/account" title="My Account">
              <span className="utilize-btn-icon">
                <i className="far fa-user" />
              </span>
              My Account
            </a>
          </li>
        </ul>
      </div>
      <div className="ltn__social-media-2">
        <ul>
          <li>
            <a href="#" title="Facebook">
              <i className="fab fa-facebook-f" />
            </a>
          </li>
          <li>
            <a href="#" title="Twitter">
              <i className="fab fa-twitter" />
            </a>
          </li>
          <li>
            <a href="#" title="Linkedin">
              <i className="fab fa-linkedin" />
            </a>
          </li>
          <li>
            <a href="#" title="Instagram">
              <i className="fab fa-instagram" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MobileMenu;
