import React, { useState } from "react";
import axios from "axios";
import { SERVER_URL } from "../Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function PasswordChange() {
  const [customer, setCustomer] = useState({
    email: "",
    password: "",
    newPassword: "",
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setCustomer({ ...customer, [name]: value });
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();

    let url = SERVER_URL + `/api/customers/changepassword/`;

    axios
      .post(url, customer)
      .then((res) => {
        toast.success(res.data.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setCustomer({
          email: "",
          password: "",
          newPassword: "",
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  return (
    <div>
      {" "}
      <div className="ltn__myaccount-tab-content-inner ">
        {/* <p className="bg-green"> New password has been update Successfully</p> */}
        <div className="ltn__form-box">
          <form action="#">
            <fieldset>
              <legend>Change Password</legend>
              <div className="row">
                <div className="col-md-12">
                  <label>Email</label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Type your registered email"
                    value={customer.email}
                    onChange={handleInput}
                    required
                  />
                  <label>Current Password</label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="********"
                    value={customer.password}
                    onChange={handleInput}
                    required
                  />
                  <label>New password</label>
                  <input
                    type="password"
                    name="newPassword"
                    id="newPassword"
                    placeholder="********"
                    value={customer.newPassword}
                    onChange={handleInput}
                    required
                  />
                </div>
              </div>
            </fieldset>
            <div className="btn-wrapper">
              <button
                type="submit"
                className="btn theme-btn-1 btn-effect-1 text-uppercase"
                onClick={handlePasswordChange}
              >
                Save Changes
              </button>
              <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PasswordChange;
