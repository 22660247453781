import React from 'react';
import { Link } from 'react-router-dom';


const UserMenu = () => {
  function handleLogOut() {
    sessionStorage.setItem("customer", "");
    sessionStorage.setItem("customerName", "");
    sessionStorage.setItem("customerType", "");
  }

  return (
    <ul>
      <li>
        <a href="#">
          <i className="icon-user" />
        </a>
        <ul>
          <li>
            {
              sessionStorage.getItem("customer") ? null : <Link to="/login">Login / Register</Link>
            }


          </li>
          <li>
            {
              sessionStorage.getItem("customer") ? <Link to="/account">My Account</Link> : null
            }

          </li>
          <li>
            {
              sessionStorage.getItem("customer") ? <a href="/" onClick={handleLogOut}>Logout</a> : null
            }

          </li>
        </ul>
      </li>
    </ul>

  )
}

export default UserMenu;