import "../App.css";
import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import MobileMenu from "../Components/MobileMenu";
import Footer from "../Components/Footer";
import { SERVER_URL } from "../Config";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";

const WarrantyClaim = () => {

  const navigate = useNavigate();

  const [Warranty, setWarranty] = useState({
    companyName: "",
    street: "",
    cityStateZip: "",
    phoneNumber: "",
    homeOwnerName: "",
    homeOwnerStreet: "",
    homeOwnerCityStateZip: "",
    homeOwnerPhoneNumber: "",
    salesOrderOrInvoiceNumber: "",
    originalUnitInstallDate: "",
    failedDate: "",
    manufacturer: "",
    unitModelNumber: "",
    unitSerialNumber: "",
    unitPartNumber: "",
    vehicleName: "",
    vehicleModel: "",
    failureReason: "",
    newPartNumber: "",
    newPartName: "",
    newSerialNumber: "",
    newPartInvoiceNumber: "",
    message: "",
  });

  const [VehicleType, setVehicleType] = useState();
  const [Model, setModel] = useState();

  useEffect(() => {
        window.scrollTo(0, 0);
        getVehicleTypes();
        getModelYears();
        
  },[]);

  const getVehicleTypes = () => {
    let url = SERVER_URL + `/api/vehicles/`;

    axios
      .get(url)
      .then((res) => {
        var sortedData = res.data.data;
        sortedData.sort((a, b) => (a.vehicleType > b.vehicleType ? 1 : -1));
        setVehicleType(sortedData);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const getModelYears = () => {
    let url = SERVER_URL + `/api/models/`;

    axios
      .get(url)
      .then((res) => {
        //setModel(res.data.data);
        var sortedData = res.data.data;
        sortedData.sort((a, b) => (a.modelYear > b.modelYear ? 1 : -1));
        setModel(sortedData);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setWarranty({ ...Warranty, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      Warranty.companyName === "" ||
      Warranty.street === "" ||
      Warranty.cityStateZip === "" ||
      Warranty.phoneNumber === "" ||
      // Warranty.homeOwnerStreet === "" ||
      // Warranty.homeOwnerCityStateZip === "" ||
      // Warranty.homeOwnerPhoneNumber === "" ||
      Warranty.salesOrderOrInvoiceNumber === "" ||
      Warranty.originalUnitInstallDate === "" ||
      Warranty.failedDate === "" ||
      // Warranty.manufacturer === "" ||
      // Warranty.unitModelNumber === "" ||
      // Warranty.unitSerialNumber === "" ||
      // Warranty.unitPartNumber === "" ||
      Warranty.vehicleName === "Select Vehicle Name" ||
      Warranty.vehicleName === "" ||
      Warranty.vehicleModel === "Select Vehicle Model" ||
      Warranty.vehicleModel === "" ||
      Warranty.failureReason === "" ||
      //Warranty.newPartNumber === "" ||
      //Warranty.newPartName === "" ||
      //Warranty.newSerialNumber === "" ||
      //Warranty.newPartInvoiceNumber === "" ||
      Warranty.message === ""
    ) {
      toast.info("Please fill out all mandatory fields ", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {


      const customer = sessionStorage.getItem("customer");

      if (customer === "" || customer === null || customer === undefined) {

        if (Warranty.companyName !== "")
          localStorage.setItem(
            "warranty",
            JSON.stringify(Warranty)
          );
        navigate("/login");
      }
      else{

        let url = SERVER_URL + `/api/warrantyclaims`;

        axios
          .post(url, {
            companyName: Warranty.companyName,
            street: Warranty.street,
            cityStateZip: Warranty.cityStateZip,
            phoneNumber: Warranty.phoneNumber,
            homeOwnerName: Warranty.homeOwnerName,
            homeOwnerStreet: Warranty.homeOwnerStreet,
            homeOwnerCityStateZip: Warranty.homeOwnerCityStateZip,
            homeOwnerPhoneNumber: Warranty.homeOwnerPhoneNumber,
            salesOrderOrInvoiceNumber: Warranty.salesOrderOrInvoiceNumber,
            originalUnitInstallDate: Warranty.originalUnitInstallDate,
            failedDate: Warranty.failedDate,
            manufacturer: Warranty.manufacturer,
            unitModelNumber: Warranty.unitModelNumber,
            unitSerialNumber: Warranty.unitSerialNumber,
            unitPartNumber: Warranty.unitPartNumber,
            vehicleName: Warranty.vehicleName,
            vehicleModel: Warranty.vehicleModel,
            failureReason: Warranty.failureReason,
            newPartNumber: Warranty.newPartNumber,
            newPartName: Warranty.newPartName,
            newSerialNumber: Warranty.newSerialNumber,
            newPartInvoiceNumber: Warranty.newPartInvoiceNumber,
            message: Warranty.message,
            email: customer
          })
          .then((res) => {
            toast.success(res.data.message, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setWarranty({
              companyName: "",
              street: "",
              cityStateZip: "",
              phoneNumber: "",
              homeOwnerName: "",
              homeOwnerStreet: "",
              homeOwnerCityStateZip: "",
              homeOwnerPhoneNumber: "",
              salesOrderOrInvoiceNumber: "",
              originalUnitInstallDate: "",
              failedDate: "",
              manufacturer: "",
              unitModelNumber: "",
              unitSerialNumber: "",
              unitPartNumber: "",
              vehicleName: "",
              vehicleModel: "",
              failureReason: "",
              newPartNumber: "",
              newPartName: "",
              newSerialNumber: "",
              newPartInvoiceNumber: "",
              message: "",
            });
          })
          .catch((err) => {
            console.log(err);
  
            toast.error(err, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });

      }
    }
  };

  return (
    <div className="App">
      <div className="body-wrapper">
        {/* HEADER AREA START (header-4) */}
        <header className="ltn__header-area ltn__header-4 ltn__header-6 ltn__header-transparent gradient-color-2">
          <Header />
        </header>
        {/* HEADER AREA END */}
        {/* Utilize Mobile Menu Start */}
        <div
          id="ltn__utilize-mobile-menu"
          className="ltn__utilize ltn__utilize-mobile-menu"
        >
          <MobileMenu />
        </div>
        {/* Utilize Mobile Menu End */}

        {/* BREADCRUMB AREA END */}
        {/* <!-- Warranty Claim AREAR START  --> */}
        <div>
          {" "}
          <div className="body-wrapper">
            {/* BREADCRUMB AREA START */}
            <div
              className="ltn__breadcrumb-area ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image"
              data-bg="img/bg/34.jpg"
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                      <div className="section-title-area ltn__section-title-2">
                        <h6 className="section-subtitle ltn__secondary-color">
                          // DENSO Provides Online
                        </h6>
                        <h1 className="section-title white-color">
                          Warranty Claim Form
                        </h1>
                      </div>
                      {/* <div className="ltn__breadcrumb-list">
                        <ul>
                          <li>
                            <a href="index.html">Home</a>
                          </li>
                          <li>Warranty Claim</li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* BREADCRUMB AREA END */}
            {/* APPOINTMENT AREA START */}
            <div className="ltn__appointment-area pb-120">
            
              <div className="container">
              <h6 className="text">If Fields are not available please Enter NA</h6>
                <div className="row">
                  <div className="col-lg-8 border pt-10 pb-10">
                    <div className="ltn__appointment-inner">
                    
                      <form action="#">
                        <h4 className="text-info">Personal Information</h4>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="input-item input-item-textarea ltn__custom-icon">
                              <input
                                type="text"
                                name="companyName"
                                value={Warranty.companyName}
                                onChange={handleInput}
                                placeholder="Customer Name /Shop Name"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Customer Name /Shop Name"
                              />
                            </div>
                            <div className="input-item input-item-textarea ltn__custom-icon">
                              <input
                                type="text"
                                name="street"
                                value={Warranty.street}
                                onChange={handleInput}
                                placeholder="Street 1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Street 1"
                              />
                            </div>
                            <div className="input-item input-item-textarea ltn__custom-icon">
                              <input
                                type="text"
                                name="cityStateZip"
                                value={Warranty.cityStateZip}
                                onChange={handleInput}
                                placeholder="City"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="City"
                              />
                            </div>
                            <div className="input-item input-item-textarea ltn__custom-icon">
                              <input
                                type="text"
                                name="phoneNumber"
                                value={Warranty.phoneNumber}
                                onChange={handleInput}
                                placeholder="Phone Number 1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Phone Number 1"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-item input-item-textarea ltn__custom-icon">
                              <input
                                type="text"
                                name="homeOwnerName"
                                value={Warranty.homeOwnerName}
                                onChange={handleInput}
                                placeholder="Customer No/Owner Name"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Customer No/Owner Name"
                              />
                            </div>
                            <div className="input-item input-item-textarea ltn__custom-icon">
                              <input
                                type="text"
                                name="homeOwnerStreet"
                                value={Warranty.homeOwnerStreet}
                                onChange={handleInput}
                                placeholder="Street 2"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Street 2"
                              />
                            </div>
                            <div className="input-item input-item-textarea ltn__custom-icon">
                              <input
                                type="text"
                                name="homeOwnerCityStateZip"
                                value={Warranty.homeOwnerCityStateZip}
                                onChange={handleInput}
                                placeholder="State/Zip"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="State/Zip"
                              />
                            </div>
                            <div className="input-item input-item-textarea ltn__custom-icon">
                              <input
                                type="text"
                                name="homeOwnerPhoneNumber"
                                value={Warranty.homeOwnerPhoneNumber}
                                onChange={handleInput}
                                placeholder="Phone Number 2"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Phone Number 2"
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className="text-info">Failed Part</h4>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="input-item input-item-textarea ltn__custom-icon">
                              <input
                                type="text"
                                name="salesOrderOrInvoiceNumber"
                                value={Warranty.salesOrderOrInvoiceNumber}
                                onChange={handleInput}
                                placeholder="Invoice Number"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Invoice Number"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="input-item ltn__datepicker mb-30">
                              <div
                                className="input-group date"
                                data-provide="datepicker"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  name="originalUnitInstallDate"
                                  value={Warranty.originalUnitInstallDate}
                                  onSelect={handleInput}
                                  placeholder="Original Unit Install Date"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Original Unit Install Date"
                                />
                                <div className="input-group-addon">
                                  <i className="far fa-calendar-alt" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="input-item ltn__datepicker mb-30">
                              <div
                                className="input-group date"
                                data-provide="datepicker"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  name="failedDate"
                                  value={Warranty.failedDate}
                                  onSelect={handleInput}
                                  placeholder="Failed Date"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Failed Date"
                                />
                                <div className="input-group-addon">
                                  <i className="far fa-calendar-alt" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="input-item input-item-textarea ltn__custom-icon">
                              <input
                                type="text"
                                name="manufacturer"
                                value={Warranty.manufacturer}
                                onChange={handleInput}
                                placeholder="Manufacturer"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Manufacturer"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="input-item input-item-textarea ltn__custom-icon">
                              <input
                                type="text"
                                name="unitModelNumber"
                                value={Warranty.unitModelNumber}
                                onChange={handleInput}
                                placeholder="Part Receipt Voucher: Damage/Warranty no (4747)"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Part Receipt Voucher: Damage/Warranty no (4747)"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="input-item input-item-textarea ltn__custom-icon">
                              <input
                                type="text"
                                name="unitSerialNumber"
                                value={Warranty.unitSerialNumber}
                                onChange={handleInput}
                                placeholder="Unit Serial Number"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Unit Serial Number"
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4">
                            <div className="input-item input-item-textarea ltn__custom-icon">
                              <input
                                type="text"
                                name="unitPartNumber"
                                value={Warranty.unitPartNumber}
                                onChange={handleInput}
                                placeholder="Unit Part Number"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Unit Part Number"
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4">
                            <div className="input-item input-item-textarea ltn__custom-icon">
                              {/* <div className="input-item input-item-textarea ltn__custom-icon">
                              <input
                                type="text"
                                name="vehicleName"
                                value={Warranty.vehicleName}
                                onChange={handleInput}
                                placeholder="Vehicle Name"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Vehicle Name"
                              />
                            </div> */}

                              <select
                                className="nice-select"
                                name="vehicleName"
                                value={Warranty.vehicleName}
                                onChange={handleInput}
                                required
                              >
                                <option>Select Vehicle Name</option>
                                {VehicleType &&
                                  VehicleType.map((element) => (
                                    <option>{element.vehicleType}</option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4">
                            <div className="input-item input-item-textarea ltn__custom-icon">
                              {/* <input
                                type="text"
                                name="vehicleModel"
                                value={Warranty.vehicleModel}
                                onChange={handleInput}
                                placeholder="Model (2007)"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Model (2007)"
                              /> */}
                              <select
                                className="nice-select"
                                name="vehicleModel"
                                value={Warranty.vehicleModel}
                                onChange={handleInput}
                                required
                              >
                                <option>Select Vehicle Model</option>
                                {Model &&
                                  Model.map((element) => (
                                    <option>{element.modelYear}</option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <h4 className="text-info">Specific Failure Reason</h4>
                        <div className="input-item input-item-textarea ltn__custom-icon">
                          <textarea
                            name="failureReason"
                            placeholder="Enter message"
                            value={Warranty.failureReason}
                            onChange={handleInput}
                            defaultValue={""}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Specific Failure Reason"
                          />
                        </div>
                        <h4 className="text-info">Replacement Part</h4>
                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="input-item input-item-textarea ltn__custom-icon">
                              <input
                                type="text"
                                name="newPartNumber"
                                value={Warranty.newPartNumber}
                                onChange={handleInput}
                                placeholder="New Part Number"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="New Part Number"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="input-item input-item-textarea ltn__custom-icon">
                              <input
                                type="text"
                                name="newPartName"
                                value={Warranty.newPartName}
                                onChange={handleInput}
                                placeholder="New Part Name"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="New Part Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="input-item input-item-textarea ltn__custom-icon">
                              <input
                                type="text"
                                name="newSerialNumber"
                                value={Warranty.newSerialNumber}
                                onChange={handleInput}
                                placeholder="New Serial Number"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="New Serial Number"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="input-item input-item-textarea ltn__custom-icon">
                              <input
                                type="text"
                                name="newPartInvoiceNumber"
                                value={Warranty.newPartInvoiceNumber}
                                onChange={handleInput}
                                placeholder="New Part Invoice Number"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="New Part Invoice Number"
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <h4 className="text-info">Additional Comments</h4>
                    <div className="input-item input-item-textarea ltn__custom-icon">
                      <textarea
                        name="message"
                        placeholder="Enter message"
                        value={Warranty.message}
                        onChange={handleInput}
                        defaultValue={""}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Additional Comments"
                      />
                    </div>
                    <div className="alert alert-danger te" role="alert">
                      <h5 className="text-danger pt-10">
                        Bring Original DJ Auto E-Invoice Along With Damaged
                        Parts.
                      </h5>
                    </div>
                    <div className="btn-wrapper text-center mt-0">
                      <button
                        className="btn theme-btn-1 btn-effect-1 text-uppercase"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Submit Claim
                      </button>
                      <ToastContainer
                        position="bottom-left"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 border bg-light pt-10 pb-10">
                    <h3 className="text-danger">Warranty Card Front</h3>
                    <img
                      className="mb-10"
                      src="img/warranty-card-front.jpeg"
                      alt=""
                    />
                    <h3 className="text-danger">Warranty Card Back</h3>
                    <img
                      className="mb-10"
                      src="img/warranty-card-back.jpeg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* APPOINTMENT AREA END */}
        </div>

        {/* <!-- Warranty Claim  AREA END --> */}
        {/* THREE STEPS AREA START */}
        <div className="contact-feature-area before-bg-bottom mb--30">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="ltn__contact-feature-item">
                  <div className="ltn__contact-feature-icon">
                    <img src="img/icons/9.png" alt="Icon Image" />
                  </div>
                  <div className="ltn__contact-feature-info">
                    <span className="h6">Find your nearest</span>
                    <br />
                    <span className="h2">Branch</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="ltn__contact-feature-item">
                  <div className="ltn__contact-feature-icon">
                    <img src="img/icons/8.png" alt="Icon Image" />
                  </div>
                  <div className="ltn__contact-feature-info">
                    <span className="h6">Make a booking by</span>
                    <br />
                    <span className="h2">Send Request</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="ltn__contact-feature-item">
                  <div className="ltn__contact-feature-icon">
                    <img src="img/icons/7.png" alt="Icon Image" />
                  </div>
                  <div className="ltn__contact-feature-info">
                    <span className="h6">For Confirmation</span>
                    <br />
                    <span className="h2">Get Phone Call</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* THREE STEPS AREA END */}
        {/* FOOTER AREA START (ltn__footer-2 ltn__footer-color-1) */}
        <footer className="ltn__footer-area ltn__footer-2 ltn__footer-color-1">
          <Footer />
        </footer>
        {/* FOOTER AREA END */}
      </div>
      {/* Body main wrapper end */}
      {/* preloader area start */}
      {/*  <Preloader /> */}
    </div>
  );
};

export default WarrantyClaim;
