import React, { useState, useEffect } from "react";
import axios from "axios";
import { SERVER_URL } from "../Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";

const ReservedSlots = () => {
  const [ReservedSlots, setReservedSlots] = useState({
    _id: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    bookingDate: "",
    bookingTime: "",
  });

  const [data, setData] = useState();

  const isCancelled = React.useRef(false);

  useEffect(() => {
    {
      if (!isCancelled.current) onGridReady();
      isCancelled.current = true;
      //console.log("useEffect");
    }
  },[]);
  const handleInput = (e) => {
    const { name, value } = e.target;
    setReservedSlots({ ...ReservedSlots, [name]: value });
  };

  const saveReservedSlots = (e) => {
    e.preventDefault();

    if (ReservedSlots._id !== "" && ReservedSlots._id !== undefined) {
      let url = SERVER_URL + `/api/managetimeslots/` + ReservedSlots._id;

      axios
        .put(url, ReservedSlots)
        .then((res) => {
          onGridReady();
          toast.success(res.data.message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setReservedSlots({
            _id: "",
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            bookingDate: "",
            BookingTime: "",
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error(err, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } else if (ReservedSlots._id === "" || ReservedSlots._id === undefined) {
      let url = SERVER_URL + `/api/timeslots/`;

      axios
        .post(url, ReservedSlots)
        .then((res) => {
          onGridReady();
          toast.success(res.data.message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setReservedSlots({
            _id: "",
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            bookingDate: "",
            BookingTime: "",
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error(err, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    }
    isCancelled.current = false;
  };

  var hashValueGetter = function(params) {
    return params.node ? params.node.rowIndex + 1 : null;
  };

  const colDefs = [
    {
      headerName: "S#",
      minWidth: 50,
      width: "5%",
      valueGetter: hashValueGetter,
      checkboxSelection: false,
      filter: false,
    },
    {
      headerName: "Booking Date",
      field: "bookingDate",
      minWidth: 150,
      width: "10%",
    },
    {
      headerName: "Booking TIme",
      field: "bookingTime",
      minWidth: 200,
      width: "5%",
    },
    {
      headerName: "First Name",
      field: "firstName",
      minWidth: 125,
      width: "10%",
      hide: true,
    },
    {
      headerName: "Name",
      field: "lastName",
      minWidth: 100,
      width: "10%",
      hide: true,
    },
    {
      headerName: "Phone #",
      field: "phoneNumber",
      minWidth: 150,
      width: "10%",
      hide: true,
    },
    {
      headerName: "Branch Name",
      field: "branchName",
      minWidth: 150,
      width: "10%",
    },
    {
      headerName: "Email",
      field: "email",
      minWidth: 200,
      width: "25%",
      
    },

    {
      headerName: "Action",
      field: "Action",
      minWidth: 180,
      width: "25%",
      cellRenderer: ActionRenderer,
      sortable: false,
      filter: false,
    },
  ];
  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
    floatingFilter: true,
  };
  const buttonStyle = {
    fontWeight: "bold",
    width: 60,
    marginRight: 5,
    marginLeft: 5,
    background: "#e30613",
  };
  const onClickEdit = (params) => {
    const selectedRows = params.api.getSelectedRows();

    if (selectedRows.length === 1) {
      setReservedSlots({
        _id: selectedRows[0]._id,
        firstName: selectedRows[0].firstName,
        lastName: selectedRows[0].lastName,
        email: selectedRows[0].email,
        phoneNumber: selectedRows[0].phoneNumber,
        bookingDate: selectedRows[0].bookingDate,
        bookingTime: selectedRows[0].bookingTime,
      });
    }
  };

  const onConfirmDelete = (params) => {
    try {
      let url = SERVER_URL + `/api/managetimeslots/` + params;

      axios
        .delete(url)
        .then((res) => {
          onGridReady();
          toast.success(res.data.message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error(err, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } catch (ex) {}
  };

  const onCancelDelete = (params) => {};

  const onClickDelete = (params) => {
    const selectedRows = params.api.getSelectedRows();

    if (selectedRows.length === 1) {
      window.confirm("Are you sure you wish to delete this item?")
        ? onConfirmDelete(selectedRows[0]._id)
        : onCancelDelete(selectedRows[0]._id);
    }
  };
  function ActionRenderer(params) {
    return (
      <span>
        <button style={buttonStyle} onClick={() => onClickEdit(params)}>
          Edit
        </button>

        <button style={buttonStyle} onClick={() => onClickDelete(params)}>
          Delete
        </button>
      </span>
    );
  }
  const onGridReady = (params) => {
    let url = SERVER_URL + `/api/managetimeslots/`;

    axios
      .get(url)
      .then((res) => {
        //setData(res.data.data);
        setData(res.data.data);

        // params.api.applyTransaction({ add: res.data.data });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
  return (
    <div>
      <div className="ltn__myaccount-tab-content-inner">
        {/* <p className="bg-green">New changes has been update Successfully</p> */}
        <div className="ltn__form-box">
          <form action="#">
            <fieldset>
              <legend>Reserved Slots </legend>
              <div className="row">
                <div className="col-md-6">
                  <div className="input-item input-item-map ltn__custom-icon">
                    <input
                      type="text"
                      name="bookingDate"
                      id="bookingDate"
                      value={ReservedSlots.bookingDate}
                      onChange={handleInput}
                      placeholder="Booking Date"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-item input-item-map ltn__custom-icon">
                    <input
                      type="text"
                      name="bookingTime"
                      id="bookingTime"
                      value={ReservedSlots.bookingTime}
                      onChange={handleInput}
                      placeholder="Booking Time"
                      required
                    />
                  </div>
                </div>
              </div>
            </fieldset>
            <div className="btn-wrapper">
              <button
                type="submit"
                className="btn theme-btn-1 btn-effect-1 text-uppercase"
                onClick={saveReservedSlots}
              >
                Save Changes
              </button>
              <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </div>
          </form>
          <div>
            <br />
          </div>
          <div className="ltn__myaccount-tab-content-inner denso__myaccount-tab">
            <div className="table-responsive">
              <div
                className="ag-theme-balham-dark"
                style={{
                  height: "475px",
                  width: "100%",
                  fontSize: 14,
                }}
              >
                <AgGridReact
                  pagination={true}
                  paginationPageSize={10}
                  paginationAutoPageSize={true}
                  defaultColDef={defaultColDef}
                  rowHeight={35}
                  rowData={data}
                  //onGridReady={onGridReady}
                  columnDefs={colDefs}
                  rowSelection="single"
                ></AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReservedSlots;
